import React from "react";

const Linkedin = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_9344" dataName="Rectangle 9344" width="16" height="16" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_24491" dataName="Group 24491" transform="translate(-197 -2259)">
        <rect id="Rectangle_9335" dataName="Rectangle 9335" width="24" height="24" rx="12" transform="translate(197 2259)" opacity="0" />
        <g id="Group_24527" dataName="Group 24527" transform="translate(201 2263)">
          <g id="Group_24526" dataName="Group 24526" clipPath="url(#clip-path)">
            <path id="Path_17911" dataName="Path 17911" d="M14.387,0H1.613A1.606,1.606,0,0,0,0,1.6V14.4A1.606,1.606,0,0,0,1.613,16H14.39A1.606,1.606,0,0,0,16,14.4V1.6A1.606,1.606,0,0,0,14.387,0ZM5.134,12.657H2.978V5.814H5.134ZM4.043,4.975a1.064,1.064,0,1,1,.028-2.124,1.065,1.065,0,1,1-.028,2.124m8.951,7.689H10.842v-3.8c0-.882-.312-1.483-1.091-1.483a1.179,1.179,0,0,0-1.1.8,1.515,1.515,0,0,0-.072.532v3.946H6.421V8c0-.853-.028-1.563-.056-2.183h1.87l.1.952h.041a2.488,2.488,0,0,1,2.14-1.1c1.415,0,2.478.938,2.478,2.953Z" transform="translate(0)" fill="#666" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Linkedin;
