import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    width: 512,
    padding: 30,
  },
  dialogPaper: {
    padding: "16px 24px 24px 24px",
    boxShadow: '0 1px 5px #00000052',
    borderRadius:8
  },
  chapterTitleWrapper: {
    paddingBottom: 32,
  },
  chapterTitle: {
    fontSize: 16,
    color: "#4F525B",
    fontWeight: 700
  },
  addContentButton: {
    float: "right",
  },
  chapterTitleTopContainer: {
    flex: 1,
  },
  chapterDialogTitle: {
    fontSize: 16,
    color: "#F43F5E",
  },
  chapterTitleTopWrapper: {
    paddingRight: 10,
  },
  paddingRight: {
    paddingRight: "52px !important",
  },
  header: {
    marginBottom: 20,
  },
  footer: {
    marginTop: 20,
  },
  chapterContainer: {
    marginBottom: 20,
  },
}));

export default useStyles;
