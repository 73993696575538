import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({

  issue: {
    cursor: "pointer",
  },
  imageContainer: {

    marginBottom: 12,
  },
  image: {
    width: "100%",
    display: "block",
    borderRadius: 12,
    boxShadow: "0 2px 6px #12121280",
  },
  date: {
    fontSize: 15,
    color: "#4F525B",
  },
  imageNotfound: {

    width: "100%",
    borderRadius: 12,
    display: "block",
    background: "grey",
  },
  publicationTitle: {
    fontSize: 16,
    color: "#313131",
    fontWeight:'700',
    marginBottom:'4px'
  },
}));

export default useStyles;
