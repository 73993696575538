import React, { useState, useEffect } from "react";
import useStyles from "./useStyles";
// Material Components
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography, Switch, FormControlLabel, Checkbox, FormHelperText, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// Material Icons

// Custom Hooks
import { useCustomHooks } from '../../../components/hooks';
// Screens

// Components
import Dialog from "components/dialog";

import { getPosts, getIssues, postMemberPost, getContentsGetByIssueId, getContentsGetByPublicationId } from '../../../utils/apiCalls/membersProxy';
import { HttpMethod } from "utils/constants/enums";

interface AddPostRelationProps {
    onClose: (data: any) => void;
    selectedMemberId: any;
    postIds: any;
    issueIds: any;
    getSelectedMemberPost?: any;
}

export const AddPostRelation: React.FC<AddPostRelationProps> = (props: AddPostRelationProps) => {
    const { t, proxyExecute, showWarningMessage } = useCustomHooks();
    const classes = useStyles();

    const [formData, setFormData] = useState<any>({
        postId: '',
        issueId: undefined,
        memberTypeId: '1',
        hasReadAccessToAllContents: false,
        contentIds: [],
        memberId: props.selectedMemberId
    });
    const [contentList, setContentList] = useState<any>([]);
    const [postList, setPostList] = useState<any>([]);
    const [issueList, setIssueList] = useState<any>([]);
    const [errors, setErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAllIsuesSelected, setIsAllIsuesSelected] = useState<boolean>(false);

    const handlePostId = (value: any) => {
        setContentList([]);
        if (value.startsWith('e-')) {
            var editionId = value.substring(2);
            proxyExecute(`Issues/List?editionId=${editionId}`, HttpMethod.Get, {
                setIsLoading: undefined,
                callback: (data: any) => {
                    data = data.filter((x: any) => props.issueIds.indexOf(x.id) < 0);
                    if (data.length === 0)
                        showWarningMessage('All issues of this post has been related');
                    setIssueList(data);
                }
            });
            formData.editionId = editionId;
            formData.publicationId = undefined;
            formData.issueId = undefined;
        }
        else {
            setIssueList([]);
            formData.publicationId = value.substring(2);
            formData.editionId = undefined;
            formData.issueId = undefined;
            var publicationId = formData.publicationId;
            proxyExecute(`Contents/GetByPublicationId/${publicationId}`, HttpMethod.Get, {
                setIsLoading: undefined,
                callback: (data: any) => { setContentList(data) }
            });
        }
    }

    const dataHandler = (field: any, value: any,) => {
        if (field === 'postId') {
            handlePostId(value);
        }

        if (field === 'issueId') {
            var issueId = value;
            proxyExecute(`Contents/GetByIssueId/${issueId}`, HttpMethod.Get, {
                setIsLoading: undefined,
                callback: (data: any) => { setContentList(data) }
            });
        }

        if (field === 'memberTypeId') {
            if (value !== "3") {
                setIsAllIsuesSelected(false);
            }
        }
        setFormData({ ...formData, [field]: value });
    };

    useEffect(() => {
        if (postList.length === 0) {
            proxyExecute(`Posts`, HttpMethod.Get, {
                setIsLoading: undefined,
                callback: (data: any) => {
                    data = data.filter((x: any) => props.postIds.indexOf(x.publicationId) < 0 && props.postIds.indexOf(x.editionId) < 0);
                    setPostList(data);
                }
            })
        }
        var a = props.getSelectedMemberPost && props.getSelectedMemberPost();
        if (a && formData.postId === '') {
            var memberPost = {
                postId: '',
                issueId: undefined,
                memberTypeId: '1',
                hasReadAccessToAllContents: false,
                contentIds: [],
                memberId: props.selectedMemberId
            };
            memberPost.postId = a.editionId === '00000000-0000-0000-0000-000000000000' ? 'p-' + a.publicationId : 'e-' + a.editionId;
            if (a.issueId === '00000000-0000-0000-0000-000000000000')
                setIsAllIsuesSelected(true);
            handlePostId(memberPost.postId);
            memberPost.issueId = a.issueId;
            if (memberPost.postId.startsWith('e-')) {
                var issueId = memberPost.issueId;
                proxyExecute(`Contents/GetByIssueId/${issueId}`, HttpMethod.Get, {
                    setIsLoading: undefined,
                    callback: (data: any) => { setContentList(data) }
                });
            }
            else {
                var publicationId = a.publicationId;
                proxyExecute(`Contents/GetByPublicationId/${publicationId}`, HttpMethod.Get, {
                    setIsLoading: undefined,
                    callback: (data: any) => { setContentList(data) }
                });
            }
            memberPost.memberTypeId = a.memberTypeId.toString();
            memberPost.hasReadAccessToAllContents = a.hasReadAccessToAllContents;
            memberPost.contentIds = !a.contentIds ? [] : a.contentIds.split(",").map((item: any) => item.trim());
            setFormData(memberPost);
        }

    }, [formData.postId, handlePostId, postList, props, proxyExecute]);

    const validate = () => {
        var errorMessages: any = {};
        if (!formData.postId || formData.postId === '') {
            errorMessages.postId = t('This field is required');
        }
        if (formData.memberTypeId === "2" && (!formData.contentIds && formData.contentIds === '')) {
            errorMessages.contentIds = t('This field is required');
            showWarningMessage('Please select at least one content')
        }
        if (formData.postId && formData.postId.startsWith('e-')) {
            if (formData.memberTypeId === "1" || formData.memberTypeId === "2") {
                if (!formData.issueId || formData.issueId == '') {
                    errorMessages.issueId = t('This field is required');
                }
            }
        }
        setErrors(errorMessages);
        return Object.keys(errorMessages).length === 0;
    }

    return (
        <Dialog
            open={true}
            closable={true}
            showAlwaysTopDivider={true}
            onClose={() => {
                props.onClose && props.onClose(formData);
            }}
            title={t('Add Post Relation')}
        >
            <Grid container style={{
                width: 512,
                padding: 30
            }}>
                <Grid item xs={12}>
                    <Paper className={classes.dialogPaper}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid style={{ marginBottom: 8 }} item>
                                    <FormControl fullWidth variant="standard" disabled={props.getSelectedMemberPost()}>
                                        <InputLabel id="post">{t('Post')}</InputLabel>
                                        <Select
                                            name="port"
                                            labelId="port"
                                            value={formData.postId}
                                            onChange={(value) => {
                                                dataHandler('postId', value?.target?.value);
                                            }}
                                            error={Boolean(errors.postId)}
                                        >
                                            {postList && postList.length > 0 &&
                                                postList.map((item: any, index: any) => {
                                                    var value = '';
                                                    if (item.publicationId)
                                                        value = 'p-' + item.publicationId;
                                                    if (item.editionId)
                                                        value = 'e-' + item.editionId;
                                                    return <MenuItem key={index} value={value}>{item.name}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                        {errors.postId && <FormHelperText error>{errors.postId}</FormHelperText>}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <ToggleButtonGroup
                                        value={formData.memberTypeId}
                                        exclusive
                                        onChange={(event: React.MouseEvent<HTMLElement>, value: string | null,) => {
                                            var newFormData = { ...formData };
                                            if (value != null)
                                                newFormData.memberTypeId = value;
                                            newFormData.contentIds = [];
                                            newFormData.hasReadAccessToAllContents = false;
                                            setFormData(newFormData);
                                        }}
                                    >
                                        <ToggleButton value="1">
                                            <Typography>{t('Member')}</Typography>
                                        </ToggleButton>
                                        <ToggleButton value="2">
                                            <Typography>{t('Writer')}</Typography>
                                        </ToggleButton>
                                        <ToggleButton value="3">
                                            <Typography>{t('Editor')}</Typography>
                                        </ToggleButton>
                                        <ToggleButton value="4">
                                            <Typography>{t('Post Manager')}</Typography>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {formData.memberTypeId === "1" &&
                                    <Typography align="center" style={{ fontSize: 11, color: "#D1D4D8" }}>{t('Can read all post')}</Typography>}
                                {formData.memberTypeId === "2" && <Grid>
                                    <Grid
                                        container
                                        flexDirection={'row'}>
                                        <Grid item flexDirection={'row'}>
                                            <Switch checked={formData.hasReadAccessToAllContents} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                dataHandler('hasReadAccessToAllContents', event.target.checked);
                                            }}></Switch>
                                        </Grid>
                                        <Grid item flexDirection={'row'}>
                                            <Grid display={'flex'} justifyContent={"flex-start"}>
                                                {t('Can read all post as a member')}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                                {formData.memberTypeId == "3" && <Typography align="center" style={{ fontSize: 11, color: "#D1D4D8" }}>{t('Can edit all contents of post, can add/delete content')}</Typography>}
                                {formData.memberTypeId == "4" && <Typography align="center" style={{ fontSize: 11, color: "#D1D4D8" }}>{t('Can edit all contents of post, can add/delete content, can edit post details, can add new issue')}</Typography>}

                            </Grid>
                            <Grid item xs={12}>
                                {formData.memberTypeId !== "4" && issueList && issueList.length > 0 && <Grid container alignItems="center">
                                    {formData.memberTypeId === "3" && <>
                                        <Grid item xs={3}>
                                            <Typography>{t('All issues')}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Switch
                                                checked={isAllIsuesSelected}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    setIsAllIsuesSelected(event.target.checked);
                                                    if (event.target.checked !== false)
                                                        dataHandler('issueId', undefined);
                                                }}
                                            />
                                        </Grid>
                                    </>}
                                    <Grid item xs={formData.memberTypeId === "3" ? 7 : 12}>
                                        <FormControl fullWidth variant="standard">
                                            <InputLabel id="Issue">{t('Issue')}</InputLabel>
                                            {!isAllIsuesSelected && <Select
                                                name="issue"
                                                labelId="issue"
                                                value={formData.issueId}
                                                onChange={(value) => {
                                                    dataHandler('issueId', value?.target?.value);
                                                }}
                                                error={Boolean(errors.issueId)}
                                                disabled={props.getSelectedMemberPost()}                                            >
                                                {issueList?.map((item: any, index: any) => {
                                                    return <MenuItem key={index} value={item.id}>{item.issueNumber}</MenuItem>;
                                                })}
                                            </Select>}

                                            {isAllIsuesSelected && <Select
                                                disabled={true}
                                                name="issue"
                                                labelId="issue"
                                            >
                                            </Select>}

                                            {errors.issueId && <FormHelperText error>{errors.issueId}</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                </Grid>}


                                {formData.memberTypeId === "2" && <Grid>
                                    <Grid container direction="column">
                                        {
                                            contentList.map((item: any) => {
                                                return <FormControlLabel
                                                    labelPlacement="end"
                                                    control={<Checkbox checked={formData.contentIds.indexOf(item.id) > -1 ? true : false}
                                                        onChange={(event, value) => {
                                                            if (value === false)
                                                                dataHandler('contentIds', formData.contentIds.filter((contentId: any) => contentId !== item.id));
                                                            else
                                                                dataHandler('contentIds', [...formData.contentIds, item.id]);
                                                        }}
                                                    />}
                                                    label={
                                                        <Typography className={classes.contentTitle}>
                                                            {item.title}
                                                        </Typography>
                                                    }
                                                />
                                            })
                                        }
                                    </Grid>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton fullWidth type="submit" variant="contained" loading={isLoading}
                        onClick={() => {
                            if (formData.contentIds && formData.contentIds.length > 0) {
                                formData.contentIds = formData.contentIds.join(';');
                            }
                            else {
                                formData.contentIds = '';
                            }

                            if (formData.postId.startsWith('e-')) {
                                formData.editionId = formData.postId.substring(2);
                            }
                            else {
                                formData.publicationId = formData.postId.substring(2);
                            }

                            if (!validate()) return;

                            proxyExecute(`MemberPosts`, HttpMethod.Post, {
                                body:formData,
                                setIsLoading,
                                callback: (data: any) => {
                                    props.onClose && props.onClose(formData);
                                }
                            })
                        }}
                        style={{
                            marginTop: 20,
                            marginBottom: 10,
                          }}
                    >
                        {t('Next')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </Dialog>
    );
};