import React, { useEffect, useState } from "react";
import useStyles from "./useStyles";
// Material Components
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// Material Icons

// Custom Hooks
import { useCustomHooks } from '../../../../../components/hooks';
// Screens

// Components
import Dialog from "components/dialog";
//Constants&Enums
import { HttpMethod } from "utils/constants/enums";
import Constants from "utils/constants";
import { generatingYears, Year } from "utils";

interface Props {
  onClose?: (dialogResponse: any) => void;
  publicationId: string;
}

const PublicationDefinition: React.FC<Props> = (props: Props) => {
  const { t, proxyExecute, isXs, showInfoMessage } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [file, setFile] = useState<any | null>(null);
  const [years, setYears] = useState<Year[]>([]);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | ArrayBuffer | null>("");
  const [publication, setPublication] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dataHandler = (field: any, value: any,) => {
    setPublication({ ...publication, [field]: value });
  };

  useEffect(() => setYears(generatingYears()), []);

  useEffect(() => {
    if (!publication) {
      var id = props.publicationId;
      proxyExecute(`Publications/Detail?Id=${id}`, HttpMethod.Get, {
        setIsLoading: undefined,
        callback: (data: any) => { setPublication(data) }
      });
    }
    else {
      if (publication.imagePath && publication.imagePath.length > 0)
        setImagePreviewUrl(`${Constants.API_URL}${publication.imagePath}`);
    }
  }, [props.publicationId, proxyExecute, publication]);

  // const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   event.preventDefault();

  //   const reader = new FileReader();
  //   const file = event.target.files && event.target.files[0];

  //   reader.onloadend = () => {
  //     file &&
  //       setFile({
  //         name: file.name,
  //         size: file.size,
  //         type: file.type,
  //         value: reader.result,
  //         lastModified: file.lastModified,
  //       });
  //     reader.result && setImagePreviewUrl(reader.result);
  //   };

  //   file && reader.readAsDataURL(file);
  // };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const reader = new FileReader();
    const file = event.target.files && event.target.files[0];

    reader.onload = async () => {
      const image = new Image();
      image.onload = async () => {
        const maxWidth = 1800;
        const maxHeight = 4000;
        let width = image.width;
        let height = image.height;

        if (width > maxWidth || height > maxHeight) {
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx?.drawImage(image, 0, 0, width, height);

        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7);
        console.log(compressedBase64);

        // Further processing with compressedBase64

        file &&
          setFile({
            name: file.name,
            size: file.size,
            type: file.type,
            value: compressedBase64,
            lastModified: file.lastModified,
          });
        reader.result && setImagePreviewUrl(reader.result);
      };

      image.src = reader.result as string;
    };

    // reader.onloadend = () => {
    //   file &&
    //     setFile({
    //       name: file.name,
    //       size: file.size,
    //       type: file.type,
    //       value: reader.result,
    //       lastModified: file.lastModified,
    //     });
    //   reader.result && setImagePreviewUrl(reader.result);
    // };

    file && reader.readAsDataURL(file);
  };

  const getDialogLeftContent = () => {
    return (
      <Grid item xs={6} className={classes.dialogLeftContent}>
        <Grid container spacing={3} alignItems="flex-end" justifyContent="space-between">
          <Grid item xs={8}>
            <input type="file" id="image-file" accept="image/*" onChange={handleImageChange} className={classes.uploadInput} />
            <label htmlFor="image-file">
              <Grid
                container
                direction="row"
                alignItems="flex-end"
                alignContent="flex-end"
                className={imagePreviewUrl ? classes.imagePreviewAfterLoad : classes.imagePreview}
              >
                <Grid item xs={12}>
                  {imagePreviewUrl && <img className={classes.image} alt={t('Post Cover')} src={imagePreviewUrl as string} />}
                  <Button disabled variant="outlined" className={classes.uploadImage}>
                    {t('Upload Cover')}
                  </Button>
                </Grid>
              </Grid>
            </label>
          </Grid>
          <Grid item >
            <Typography className={classes.imageInfo}>
              {t('Post cover image width must be minimum 600px')}
              <br />
              {t('The file size must be no more than 1 MB')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getDialogRightContent = () => {
    if (!publication) return;
    return (
      <Grid item xs={6} className={classes.dialogRightContent}>
        <Grid container direction="column" spacing={1}>
          <Grid className={classes.row} item>
            <Typography style={{ marginBottom: '24px' }}>{publication.name}</Typography>
            <Grid className={classes.row} item>
              <FormControl fullWidth variant="standard">
                <InputLabel id="language">{t('Language')}</InputLabel>
                <Select
                  name="language"
                  labelId="language"
                  value={publication.language}
                  onChange={(value) => {
                    dataHandler('language', value?.target?.value);
                  }}
                >
                  <MenuItem value="TUR">{t('Turkish')}</MenuItem>
                  <MenuItem value="ENG">{t('English')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid className={classes.row} item>
              <FormControl fullWidth variant="standard">
                <InputLabel id="year">{t('Year')}</InputLabel>
                <Select
                  name="year"
                  labelId="year"
                  onChange={(value) => {
                    dataHandler('year', value?.target?.value);
                  }}
                  value={publication.year}
                  error={Boolean(errors.year)}
                >
                  {years.map((year) => {
                    return (
                      <MenuItem key={year.key} value={year.key}>
                        {year.value}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errors.year && <FormHelperText error>{errors.year}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const validate = () => {
    var errorMessages: any = {};
    if (!publication.year || publication.year.length === 0) {
      errorMessages.year = t('This field is required');
    }
    if (!imagePreviewUrl) {
      showInfoMessage('Upload cover photo');
      errorMessages.image = t('Upload cover photo');
    }
    setErrors(errorMessages);
    return Object.keys(errorMessages).length === 0;
  }

  return (
    <Dialog open closable={true} fullScreen={isXs} title={t('PublicationDetail')} onClose={() => {
      props.onClose && props.onClose({ result: false, id: props.publicationId });
    }} showAlwaysTopDivider={true}>
      <Grid container direction="column" className={classes.dialogContent}>
        <Paper className={classes.dialogPaper}>
          <Grid container spacing={3} >
            {getDialogLeftContent()}
            {getDialogRightContent()}
          </Grid>
        </Paper>
        <Grid item>
          <LoadingButton loading={isLoading} fullWidth size="large" variant="contained" className={classes.nextButton}
            onClick={() => {
              if (!validate()) return;
              proxyExecute(`Publications/CreateDetail`, HttpMethod.Post, {
                body: {
                  id: publication.id,
                  file: file,
                  year: publication.year,
                  language: publication.language
                },
                setIsLoading,
                callback: () => { props.onClose && props.onClose({ result: true }) }
              });
            }}>
            {t('Ok')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default PublicationDefinition;
