import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: "100vh",
    background: theme.palette.baseWhite
  },
  paddingTop: {
    paddingTop: 56,
  },
  bottomContainer: {
    display: "flex",
    minHeight: "calc(100vh - 56px)",
  },
  maxWidthMd: {
    userSelect: "none",
    padding: "50px 24px",
  },
}));

export default useStyles;
