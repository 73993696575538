import Loading from "components/loading";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Constants from "utils/constants";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSnackbar } from "components/snackbar";
import { Button, ButtonGroup, ClickAwayListener, Grid, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";

// Custom Hooks
import { useCustomHooks } from '../../../components/hooks';

import useStyles from "./useStyles";
import { deletePublication, getPublicationById } from "utils/apiCalls/publications";
import PublicationDefinition from "pages/post/posted/postDefinition/publicationDefinition";
import PostDefinition from "pages/post/posted/postDefinition";
import DeleteConfirmation from "components/deleteConfirmation";

interface Props {
  onClick: (publication: any) => void;
  publicationId: any;
  onPublicationDeleted: () => void;
  onPublicationModified: () => void;
  tenantId?: any;
  publication:any;
}

const Publication: React.FC<Props> = (props: Props) => {
  const { publicationId } = props;
  const classes = useStyles();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const { i18n } = useTranslation();
  const [publication, setPublication] = React.useState<any>();
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [openPublicationDefinitionAsDialog, setOpenPublicationDefinitionAsDialog] = useState<boolean>(false);
  const [openPostDefinitionAsDialog, setOpenPostDefinitionAsDialog] = useState<boolean>(false);
  const [deletePublicationConfirmation, setDeletePublicationConfirmation] = useState(false);
  const [isHover, setHover] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { isMd } = useCustomHooks();

  useEffect(() => {
    if(props.publication)
      setPublication(props.publication);

    // getPublicationById({ publicationId, tenantId: props.tenantId }, (data: any) => {
    //   setPublication(data);
    // });
  }, [publicationId]);

  const getLanguageFlag = (languageCode: any) => {
    switch (languageCode) {
      case 'TUR':
        return <div>&#x1F1F9;&#x1F1F7;</div>
      case 'ENG':
        return <div>&#x1F1EC;&#x1F1E7;</div>;
      default:
        return <div></div>;
    }
  }

  if (!publication) {
    return (
      <Grid container className={classes.issue} onClick={() => publication && props.onClick(publication)}>
        <Loading></Loading>
      </Grid>
    );
  }


  const handleToggle = (value: any) => {
    if (value === undefined)
      setOpenContextMenu((prevOpenContextMenu: any) => !prevOpenContextMenu);
    else
      setOpenContextMenu(value);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenContextMenu(false);
  };

  const getContectMenu = () => {
    return <Popper
      style={{ zIndex: 2 }}
      transition
      open={openContextMenu}
      disablePortal role={undefined} anchorEl={anchorRef.current}
      placement={'bottom-end'}>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu">
                {(!props.tenantId || publication.hasAccessToEditPost) && <MenuItem onClick={() => {
                  setOpenPostDefinitionAsDialog(true);
                  setOpenContextMenu(false);
                }}>
                  <Typography>Yayın bilgileri düzenle</Typography>
                </MenuItem>}
                {(!props.tenantId || publication.hasAccessToEditPostDetail) && <MenuItem onClick={() => {
                  setOpenPublicationDefinitionAsDialog(true);
                  setOpenContextMenu(false);
                }}>
                  <Typography>Yayın detay bilgileri düzenle</Typography>
                </MenuItem>}
                {(!props.tenantId || publication.hasAccessToDeletePost) && <MenuItem onClick={() => {
                  setDeletePublicationConfirmation(true);
                  setOpenContextMenu(false);
                }}>
                  <Typography>Yayın sil</Typography>
                </MenuItem>}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>;
  }

  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => {setHover(false); setOpenContextMenu(false)}}>
      <Grid>

        <Grid container className={classes.issue} onClick={() => publication && props.onClick(publication)}>
          <Grid item xs={12}>
            <Grid container alignContent="flex-end" className={classes.imageContainer}>
              <Grid item xs={12}>
                {publication?.imagePath ? (
                  <img alt={publication.name} className={classes.image} src={`${Constants.API_URL}${publication?.imagePath}`} />
                ) : (
                  <div className={classes.imageNotfound} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>

          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.publicationTitle}>{publication.name}</Typography>
            {publication && publication.writers && (
              <Typography>
                {publication.writers.map((writer: any, index: number) => {
                  return writer.name + (index == publication.writers.length - 1 ? '' : ', ')
                })}
              </Typography>
            )}
            <Typography style={{ fontSize: '12px' }}>{publication.year}</Typography>
          </Grid>
        </Grid>
        <Grid container xs={12} direction="row" justifyContent="space-between" alignItems="center" style={{height:45}}>
          <Grid container flex={1} direction={"row"} alignItems="center">
            {getLanguageFlag(publication.language)}
            <Typography style={{ fontSize: '12px', marginLeft: 5 }}>
              {publication.language}
            </Typography>
          </Grid>
          {isHover && (!props.tenantId || publication.hasAccessToDeletePost || publication.hasAccessToEditPost || publication.hasAccessToEditPostDetail) && !isMd
          && <Grid>
            <ButtonGroup ref={anchorRef} >
              <IconButton size="small" onClick={handleToggle}><MoreVertIcon style={{height:25}}/></IconButton>
            </ButtonGroup>
          </Grid>}
          {getContectMenu()}
        </Grid>
        {openPublicationDefinitionAsDialog && <PublicationDefinition publicationId={props.publicationId}
          onClose={(dialogResponse: any) => {
            setOpenPublicationDefinitionAsDialog(false);
            props.onPublicationModified && props.onPublicationModified();
          }}></PublicationDefinition>}
        {openPostDefinitionAsDialog && <PostDefinition postType={'publication'} post={publication} onClose={(dialogResponse: any) => {
          setOpenPostDefinitionAsDialog(false);
          props.onPublicationModified && props.onPublicationModified();
        }}></PostDefinition>}
        {deletePublicationConfirmation && <DeleteConfirmation onDeleteClicked={() => {
          setDeletePublicationConfirmation(false);
          deletePublication(props.publicationId, () => {
            enqueueSnackbar("İşlem tamamlandı.", {
              variant: "success",
            });
            props.onPublicationDeleted && props.onPublicationDeleted();
          });
        }}
          onCancelClicked={() => {
            setDeletePublicationConfirmation(false);
          }}></DeleteConfirmation>}
      </Grid>
    </div>
  );
};

export default Publication;
