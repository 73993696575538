import React from "react";

const Logo = () => {
  return (
    <svg id="LogoFinal" xmlns="http://www.w3.org/2000/svg" width="152" height="42" viewBox="0 0 152 42" style={{ verticalAlign: 'bottom' }}>
      <rect id="Rectangle_9248" dataName="Rectangle 9248" width="152" height="42" fill="none" />
      <g id="Group_24452" dataName="Group 24452" transform="translate(-360 -360)">
        <g id="Group_24451" dataName="Group 24451" transform="translate(407 372.75)">
          <path
            id="Path_17844"
            dataName="Path 17844"
            d="M287.345,73.2a2.911,2.911,0,0,1-1.106-.388,5.291,5.291,0,0,1-1.58-1.364q-.632-.625-1.317-1.352t-1.448-1.5q-.764-.776-1.54-1.577l-1.541-1.59q-.764-.789-1.527-1.565t-1.448-1.5q-.685-.726-1.316-1.352a.581.581,0,0,0-.171-.188.371.371,0,0,0-.171-.088c-.071,0-.119.059-.145.175a1.4,1.4,0,0,0-.039.3q-.027,1.152,0,2.7t.066,3.142q.04,1.59.039,2.891a1.6,1.6,0,0,0,.527,1.264,3.639,3.639,0,0,0,1.554.714,1.846,1.846,0,0,1,.514.163.362.362,0,0,1,.224.338.306.306,0,0,1-.211.275,1.034,1.034,0,0,1-.448.1q-.711,0-1.159-.038t-.829-.05q-.382-.012-.935-.013-.58,0-1.093.025t-.869.05c-.237.016-.4.025-.487.025a1.134,1.134,0,0,1-.408-.088q-.224-.087-.224-.263a.337.337,0,0,1,.211-.325q.211-.1.474-.2a1.812,1.812,0,0,0,.961-.751,2.776,2.776,0,0,0,.382-1.3q.105-.926.224-2.453t.224-3.442q.105-1.915.105-3.968a2.3,2.3,0,0,0-.7-1.877,5.5,5.5,0,0,0-1.7-.926,2.065,2.065,0,0,1-.474-.188.41.41,0,0,1-.263-.363.314.314,0,0,1,.211-.313,1.225,1.225,0,0,1,.474-.088q.474,0,1.067.025t1.251.037q.658.013,1.369.037t1.422.025a.613.613,0,0,1,.329.075.986.986,0,0,1,.224.2l1.251,1.352q.671.727,1.409,1.515t1.5,1.6q.763.814,1.514,1.64t1.475,1.6q.724.776,1.409,1.5t1.264,1.352a2.071,2.071,0,0,0,.2.213.339.339,0,0,0,.224.112q.21,0,.211-.375,0-.776.013-1.8t.013-2.178q0-1.151-.013-2.278t-.066-2.078a1.193,1.193,0,0,0-.54-1.014,4.66,4.66,0,0,0-1.409-.563,1.447,1.447,0,0,1-.5-.175.388.388,0,0,1-.211-.351.284.284,0,0,1,.211-.263,1.154,1.154,0,0,1,.448-.088q.606,0,1.027.013t.764.013H287q.527,0,.9-.013t.8-.038q.435-.024,1.119-.025a.976.976,0,0,1,.487.1.307.307,0,0,1,.171.275q0,.376-.685.5a3,3,0,0,0-1.317.5,1.353,1.353,0,0,0-.421,1.051q0,.9-.013,1.928t-.066,2.1q-.053,1.077-.092,2.14t-.066,2.04q-.026.976-.026,1.8a11.123,11.123,0,0,0,.079,1.264q.079.689.145,1.252t.066.789a1.457,1.457,0,0,1-.2.876.652.652,0,0,1-.54.25"
            transform="translate(-269.701 -56.256)"
          />
          <path
            id="Path_17845"
            dataName="Path 17845"
            d="M398.751,73.724a.514.514,0,0,1-.29-.088.271.271,0,0,1-.132-.237.427.427,0,0,1,.132-.325.734.734,0,0,1,.342-.175,2.961,2.961,0,0,0,.961-.337.659.659,0,0,0,.277-.587v-5.4a2.039,2.039,0,0,0-.277-1.212,1.218,1.218,0,0,0-.856-.437.324.324,0,0,1-.237-.15.547.547,0,0,1-.079-.3q0-.375.29-.425a11.637,11.637,0,0,0,2.436-.7q1.067-.45,1.646-.7a1.91,1.91,0,0,1,.527-.2q.342,0,.342.3-.026.425-.118,1.062t-.158,1.312q-.066.675-.066,1.3V71.95a.641.641,0,0,0,.276.55,3.368,3.368,0,0,0,.988.4,1.283,1.283,0,0,1,.342.175.353.353,0,0,1,.132.3q0,.325-.4.325-.5,0-1.053-.025l-1.066-.05q-.514-.024-.988-.025-.448,0-.935.025t-1,.062q-.513.038-1.04.038m2.9-12.394a2.043,2.043,0,0,1-1.435-.575,1.81,1.81,0,0,1-.619-1.374,1.865,1.865,0,0,1,.619-1.424,2.044,2.044,0,0,1,1.435-.575,2.132,2.132,0,0,1,1.527.575,1.885,1.885,0,0,1,.606,1.424,1.829,1.829,0,0,1-.606,1.374,2.131,2.131,0,0,1-1.527.575"
            transform="translate(-378.363 -57.198)"
          />
          <path
            id="Path_17846"
            dataName="Path 17846"
            d="M446.348,102.075a.743.743,0,0,1-.356-.092.3.3,0,0,1-.171-.277.372.372,0,0,1,.184-.329,1.861,1.861,0,0,1,.421-.2,3.946,3.946,0,0,0,.7-.3.732.732,0,0,0,.3-.7v-6.03a2.454,2.454,0,0,0-.171-.974.856.856,0,0,0-.645-.5.39.39,0,0,1-.2-.132.514.514,0,0,1-.092-.342q0-.369.263-.448.9-.211,1.962-.566a12.918,12.918,0,0,0,1.83-.75c.07-.035.149-.079.237-.132a.473.473,0,0,1,.237-.079q.158,0,.2.092a.569.569,0,0,1,.04.224,5.4,5.4,0,0,1-.066.672,5.933,5.933,0,0,0-.066.829,2.287,2.287,0,0,0,.013.237.316.316,0,0,0,.039.158,7.679,7.679,0,0,1,1.817-1.343,4.6,4.6,0,0,1,2.133-.474,3.012,3.012,0,0,1,2.3,1.014,3.9,3.9,0,0,1,.935,2.752v5.794a.731.731,0,0,0,.3.7,3.931,3.931,0,0,0,.7.3,1.468,1.468,0,0,1,.369.2.388.388,0,0,1,.158.329.307.307,0,0,1-.145.277.558.558,0,0,1-.3.092q-.527,0-.987-.04t-.9-.066q-.435-.026-.909-.026-.448,0-.9.026t-.9.066q-.448.04-.974.04a.557.557,0,0,1-.3-.092.306.306,0,0,1-.145-.277.389.389,0,0,1,.158-.329,1.465,1.465,0,0,1,.369-.2,2.873,2.873,0,0,0,.658-.3.778.778,0,0,0,.263-.7V94.939a2.417,2.417,0,0,0-.461-1.567,1.575,1.575,0,0,0-1.224-.593,2.687,2.687,0,0,0-1.027.184,5.338,5.338,0,0,0-.921.5.712.712,0,0,0-.211.5v6.215a.744.744,0,0,0,.29.7,3.776,3.776,0,0,0,.658.3q.527.158.527.527a.323.323,0,0,1-.132.263.492.492,0,0,1-.316.106q-.527,0-.974-.04t-.843-.066q-.395-.026-.869-.026-.448,0-.909.026t-.948.066q-.488.04-1.014.04"
            transform="translate(-418.601 -85.549)"
          />
          <path
            id="Path_17847"
            dataName="Path 17847"
            d="M540.031,104.249a5.7,5.7,0,0,1-2.844-.711,5.386,5.386,0,0,1-2.765-4.793,6.144,6.144,0,0,1,.474-2.4,6.394,6.394,0,0,1,1.317-2,6.227,6.227,0,0,1,1.975-1.369,5.929,5.929,0,0,1,2.423-.5,5.774,5.774,0,0,1,2.949.764,5.916,5.916,0,0,1,2.093,2.015,5.071,5.071,0,0,1,.777,2.726,6.83,6.83,0,0,1-.724,3.121,5.582,5.582,0,0,1-2.133,2.291,6.725,6.725,0,0,1-3.542.856m.527-1.159a1.968,1.968,0,0,0,.961-.224,1.757,1.757,0,0,0,.671-.672,3.745,3.745,0,0,0,.435-1.435,16.841,16.841,0,0,0,.118-2.146,9.363,9.363,0,0,0-.316-2.541,4.62,4.62,0,0,0-.882-1.778,1.709,1.709,0,0,0-1.33-.658,1.944,1.944,0,0,0-.764.145,1.9,1.9,0,0,0-.606.408,3.117,3.117,0,0,0-.619,1.5,14.123,14.123,0,0,0-.171,2.4,8.666,8.666,0,0,0,.329,2.41,5.445,5.445,0,0,0,.9,1.87,1.615,1.615,0,0,0,1.277.724"
            transform="translate(-492.962 -87.434)"
          />
          <path
            id="Path_17848"
            dataName="Path 17848"
            d="M614.114,105.51q-.158,0-.329-.342t-.382-.816q-.211-.474-.448-1.067t-.448-1.067q-.659-1.553-1.369-3.028t-1.4-3a4.211,4.211,0,0,0-.553-.988,1.636,1.636,0,0,0-.79-.566.981.981,0,0,1-.369-.224.477.477,0,0,1-.105-.329.253.253,0,0,1,.224-.264,1.806,1.806,0,0,1,.408-.053,9.483,9.483,0,0,1,.988.04l.7.079a8.432,8.432,0,0,0,.922.04q.553,0,.987-.04l.9-.079q.461-.04,1.067-.04a1.236,1.236,0,0,1,.408.079c.149.053.224.141.224.263a.31.31,0,0,1-.184.316q-.185.079-.421.184a.892.892,0,0,0-.435.4,1.43,1.43,0,0,0-.171.737v.435a.91.91,0,0,0,.105.408l1.765,4.345h.079l1.554-4.161a1.612,1.612,0,0,0,.132-.448,3.256,3.256,0,0,0,.026-.4,1.536,1.536,0,0,0-.2-.8,1.17,1.17,0,0,0-.645-.487,2.6,2.6,0,0,1-.408-.171.34.34,0,0,1-.171-.329.274.274,0,0,1,.211-.263,1.124,1.124,0,0,1,.4-.079q.711.027,1.067.053t.75.026q.395,0,.764-.026t1.053-.053a1.805,1.805,0,0,1,.408.053.253.253,0,0,1,.224.263q0,.369-.5.553a2.148,2.148,0,0,0-.882.593,3.584,3.584,0,0,0-.671,1.04l-2.054,5.162a3.463,3.463,0,0,1-.171.448q-.119.264-.237.54t-.224.513q-.105.237-.211.421-.079.185-.263.606l-.369.843a6.2,6.2,0,0,1-.342.685Z"
            transform="translate(-554.62 -88.51)"
          />
          <path
            id="Path_17849"
            dataName="Path 17849"
            d="M688.678,73.724a.514.514,0,0,1-.29-.088.271.271,0,0,1-.132-.237.427.427,0,0,1,.132-.325.734.734,0,0,1,.342-.175,2.961,2.961,0,0,0,.961-.337.659.659,0,0,0,.277-.587v-5.4a2.039,2.039,0,0,0-.277-1.212,1.218,1.218,0,0,0-.856-.437.325.325,0,0,1-.237-.15.548.548,0,0,1-.079-.3q0-.375.29-.425a11.637,11.637,0,0,0,2.436-.7q1.066-.45,1.646-.7a1.909,1.909,0,0,1,.527-.2q.342,0,.342.3-.026.425-.118,1.062t-.158,1.312q-.066.675-.066,1.3V71.95a.641.641,0,0,0,.276.55,3.367,3.367,0,0,0,.988.4,1.282,1.282,0,0,1,.342.175.353.353,0,0,1,.132.3q0,.325-.4.325-.5,0-1.053-.025l-1.066-.05q-.514-.024-.988-.025-.448,0-.935.025t-1,.062q-.513.038-1.04.038m2.9-12.394a2.043,2.043,0,0,1-1.435-.575,1.81,1.81,0,0,1-.619-1.374,1.865,1.865,0,0,1,.619-1.424,2.044,2.044,0,0,1,1.435-.575,2.132,2.132,0,0,1,1.527.575,1.885,1.885,0,0,1,.606,1.424,1.829,1.829,0,0,1-.606,1.374,2.13,2.13,0,0,1-1.527.575"
            transform="translate(-622.295 -57.198)"
          />
          <path
            id="Path_17850"
            dataName="Path 17850"
            d="M739.022,104.385a4.458,4.458,0,0,1-2.541-.75,5.176,5.176,0,0,1-1.765-2.054,6.587,6.587,0,0,1-.645-2.963,6.036,6.036,0,0,1,.764-3.015,5.846,5.846,0,0,1,2.081-2.159,5.454,5.454,0,0,1,2.9-.8,4.176,4.176,0,0,1,2.028.487,3.964,3.964,0,0,1,1.435,1.3,3.27,3.27,0,0,1,.54,1.843q0,1-.816,1h-5.267a.654.654,0,0,0-.54.211,1.141,1.141,0,0,0-.171.711,3.787,3.787,0,0,0,.448,1.83,3.4,3.4,0,0,0,1.211,1.3,3.276,3.276,0,0,0,1.764.474,3.4,3.4,0,0,0,1.409-.29,6.077,6.077,0,0,0,1.277-.79q.105-.079.2-.145a.3.3,0,0,1,.171-.066c.175,0,.263.115.263.342a2.2,2.2,0,0,1-.211.79,4.129,4.129,0,0,1-.882,1.3,4.623,4.623,0,0,1-1.541,1.04,5.3,5.3,0,0,1-2.107.4m-1.29-8.216h1.58q.737,0,1.027-.026a2.321,2.321,0,0,0,.527-.105.417.417,0,0,0,.211-.263,1.341,1.341,0,0,0,.079-.474,1.5,1.5,0,0,0-.487-1.159,1.706,1.706,0,0,0-1.2-.448,1.938,1.938,0,0,0-1.04.3,2.421,2.421,0,0,0-.79.79,1.722,1.722,0,0,0-.277,1.014.326.326,0,0,0,.369.369"
            transform="translate(-660.943 -87.569)"
          />
          <path
            id="Path_17851"
            dataName="Path 17851"
            d="M801.407,102.075a.743.743,0,0,1-.356-.092.3.3,0,0,1-.171-.277.371.371,0,0,1,.184-.329,1.861,1.861,0,0,1,.421-.2,3.944,3.944,0,0,0,.7-.3.732.732,0,0,0,.3-.7v-6.03a2.454,2.454,0,0,0-.171-.974.856.856,0,0,0-.645-.5.39.39,0,0,1-.2-.132.514.514,0,0,1-.092-.342q0-.369.263-.448.9-.211,1.962-.566a12.922,12.922,0,0,0,1.83-.75c.07-.035.149-.079.237-.132a.473.473,0,0,1,.237-.079q.158,0,.2.092a.568.568,0,0,1,.04.224,5.414,5.414,0,0,1-.066.672,5.935,5.935,0,0,0-.066.829,2.258,2.258,0,0,0,.013.237.315.315,0,0,0,.039.158,7.679,7.679,0,0,1,1.817-1.343,4.6,4.6,0,0,1,2.133-.474,3.012,3.012,0,0,1,2.3,1.014,3.9,3.9,0,0,1,.935,2.752v5.794a.731.731,0,0,0,.3.7,3.932,3.932,0,0,0,.7.3,1.468,1.468,0,0,1,.369.2.389.389,0,0,1,.158.329.307.307,0,0,1-.145.277.558.558,0,0,1-.3.092q-.527,0-.987-.04t-.9-.066q-.434-.026-.909-.026-.448,0-.9.026t-.9.066q-.448.04-.974.04a.557.557,0,0,1-.3-.092.306.306,0,0,1-.145-.277.389.389,0,0,1,.158-.329,1.465,1.465,0,0,1,.369-.2,2.874,2.874,0,0,0,.658-.3.778.778,0,0,0,.263-.7V94.939a2.417,2.417,0,0,0-.461-1.567,1.575,1.575,0,0,0-1.224-.593,2.686,2.686,0,0,0-1.027.184,5.333,5.333,0,0,0-.921.5.711.711,0,0,0-.211.5v6.215a.744.744,0,0,0,.29.7,3.782,3.782,0,0,0,.658.3q.527.158.527.527a.324.324,0,0,1-.132.263.492.492,0,0,1-.316.106q-.527,0-.975-.04t-.843-.066q-.395-.026-.869-.026-.448,0-.909.026t-.948.066q-.488.04-1.014.04"
            transform="translate(-716.794 -85.549)"
          />
        </g>
        <path
          id="Path_17852"
          dataName="Path 17852"
          d="M40,15.088s0,0,0,0c-.05-.591-4.3-1.284-6.674-1.092-.361.029-.663.549-.878,1.076l-1.231.157a18.984,18.984,0,0,0-.482-2,14.174,14.174,0,0,0-9.182-9.579c-1.656-.495-4.175-.921-5.9-1.272A1.3,1.3,0,0,0,15,.031l.008,0s-1.87-.321-2.382,1.255a1.911,1.911,0,0,0,.054,1.259,64.385,64.385,0,0,0-6.608,8.624A34.978,34.978,0,0,0,2.394,18.04c-.4.037-.8.087-1.182.152a2.563,2.563,0,0,0-1.054.653.474.474,0,0,0,0,.705l.108.1a.47.47,0,0,1,.155.334.471.471,0,0,1-.07.362l-.082.121a.474.474,0,0,0,.173.684,2.557,2.557,0,0,0,1.18.377c.389-.031.784-.079,1.183-.141a34.814,34.814,0,0,0,5.217,5.776A64.078,64.078,0,0,0,16.5,33.92a1.908,1.908,0,0,0,.25,1.235c.875,1.4,2.612.637,2.612.637l-.009,0a1.3,1.3,0,0,0,.065-2.432c1.595-.762,3.936-1.789,5.424-2.672a14.24,14.24,0,0,0,6.609-11.532,19.01,19.01,0,0,0-.013-2.059l1.232-.148c.336.459.754.89,1.111.83,2.346-.391,6.308-2.1,6.214-2.685v0m-32.78-3.17A64.561,64.561,0,0,1,13.5,3.675a2.371,2.371,0,0,0,.559.337,10.287,10.287,0,0,0,1.845.48A24.493,24.493,0,0,1,20.74,6c3.544,1.547,5.565,4.5,6.5,8.207.137.494.25.985.344,1.474L7.952,18.142a33.1,33.1,0,0,0-4.065-.184,39.66,39.66,0,0,1,3.33-6.04M27.834,19.05c-.02,3.827-1.271,7.183-4.339,9.548a24.583,24.583,0,0,1-4.328,2.642,10.32,10.32,0,0,0-1.675.915,2.381,2.381,0,0,0-.461.463,64.2,64.2,0,0,1-8.079-6.467A39.457,39.457,0,0,1,4.271,21.1a33.161,33.161,0,0,0,3.9-1.169l19.644-2.4c.026.5.035,1,.02,1.514"
          transform="translate(363 363)"
          fill="#f43f5e"
        />
      </g>
    </svg>
  );
};

export default Logo;
