import Constants from "../constants";
import axios from 'axios'

export const publishPublication = async (publicationId: any, callback: any) => {
  var request = {
    method: 'put',
    url: Constants.API_URL + 'Publications/Publish/' + publicationId,
    headers: {
      authorization: ''
    }
  };
  let token;
  if (typeof window !== "undefined") {
    token = window.localStorage.getItem("token");
  }

  if (token) {
    request.headers.authorization = `Bearer ${JSON.parse(token)}`;
  }

  var data = await axios(request);
  if (data.data.success) {
    callback(true);
  }
  else {
    callback(false);
  }
}

export const unpublishPublication = async (publicationId: any, callback: any) => {
  var request = {
    method: 'put',
    url: Constants.API_URL + 'Publications/Unpublish/' + publicationId,
    headers: {
      authorization: ''
    }
  };
  let token;
  if (typeof window !== "undefined") {
    token = window.localStorage.getItem("token");
  }

  if (token) {
    request.headers.authorization = `Bearer ${JSON.parse(token)}`;
  }

  var data = await axios(request);
  if (data.data.success) {
    callback(true);
  }
  else {
    callback(false);
  }
}

export const getPublications = async (isApproved: boolean, isPublished: boolean, tenantId: any, callback: any) => {
  var request = {
    method: 'get',
    url: Constants.API_URL + 'Publications/List',
    headers: {
      authorization: '',
      'N-AuthorizedTenantId': tenantId
    }
  };

  request.url += `?IsApproved=${isApproved}`;
  request.url += `&IsPublished=${isPublished}`;


  let token;
  if (typeof window !== "undefined") {
    token = window.localStorage.getItem("token");
  }

  if (token) {
    request.headers.authorization = `Bearer ${JSON.parse(token)}`;
  }

  axios(request).then(({ data }: any) => {
    if (data && data.success) {
      console.log(data);
      callback(data.data);
    }
    else {
      alert('hata aldı. posted 2');
    }
  });
}

export const getPublicationById = async (parameters: any, callback: any) => {
  var request = {
    method: 'get',
    url: Constants.API_URL + 'Publications/Detail',
    headers: {
      authorization: '',
      'N-AuthorizedTenantId': parameters.tenantId
    }
  };

  request.url += `?Id=${parameters.publicationId}`;

  let token;
  if (typeof window !== "undefined") {
    token = window.localStorage.getItem("token");
  }

  if (token) {
    request.headers.authorization = `Bearer ${JSON.parse(token)}`;
  }

  axios(request).then(({ data }: any) => {
    if (data && data.success) {
      console.log(data);
      callback(data.data);
    }
    else {
      alert('hata aldı. posted 2');
    }
  });
}

export const savePublication = async (body: any, callback: any) => {
  var request = {
    method: 'post',
    url: Constants.API_URL + 'Publications/Create',
    headers: {
      authorization: ''
    },
    data: body
  };
  let token;
  if (typeof window !== "undefined") {
    token = window.localStorage.getItem("token");
  }

  if (token) {
    request.headers.authorization = `Bearer ${JSON.parse(token)}`;
  }

  axios(request).then(({ data }: any) => {
    if (data && data.success) {
      callback(data.data);
    }
    else {
      alert('hata aldı. createeditiondialog');
    }
  });
}

export const updatePublication = async (body: any, callback: any) => {
  var request = {
    method: 'put',
    url: Constants.API_URL + 'Publications',
    headers: {
      authorization: ''
    },
    data: body
  };
  let token;
  if (typeof window !== "undefined") {
    token = window.localStorage.getItem("token");
  }

  if (token) {
    request.headers.authorization = `Bearer ${JSON.parse(token)}`;
  }

  axios(request).then(({ data }: any) => {
    if (data && data.success) {
      callback(data.data);
    }
    else {
      alert('hata aldı. createeditiondialog');
    }
  });
}

export const savePublicationDetail = async (body: any, callback: any) => {
  var request = {
    method: 'post',
    url: Constants.API_URL + 'Publications/CreateDetail',
    headers: {
      authorization: ''
    },
    data: body
  };
  let token;
  if (typeof window !== "undefined") {
    token = window.localStorage.getItem("token");
  }

  if (token) {
    request.headers.authorization = `Bearer ${JSON.parse(token)}`;
  }

  axios(request).then(({ data }: any) => {

    if (data && data.success) {
      callback();
    }
    else {
      alert('hata aldı.publicationDialog2');
    }
  });

}

export const deletePublication = (publicationId: any, callback: any) => {
  var request = {
    method: 'delete',
    url: Constants.API_URL + 'Publications/' + publicationId,
    headers: {
      authorization: ''
    }
  };
  let token;
  if (typeof window !== "undefined") {
    token = window.localStorage.getItem("token");
  }

  if (token) {
    request.headers.authorization = `Bearer ${JSON.parse(token)}`;
  }

  axios(request).then(({ data }: any) => {

    if (data && data.success) {
      callback();
    }
    else {
      alert('hata aldı.EditIssueDialog3');
    }
  });
}

export const getPublicationImprint = async (callback: any, parameters: any) => {
  var request = {
    method: 'get',
    url: Constants.API_URL + `Publications/${parameters.id}/Imprint`,
    headers: {
      authorization: ''
    }
  };

  let token;
  if (typeof window !== "undefined") {
    token = window.localStorage.getItem("token");
  }

  if (token) {
    request.headers.authorization = `Bearer ${JSON.parse(token)}`;
  }

  axios(request).then(({ data }: any) => {
    if (data && data.success) {
      console.log(data);
      callback(data.data);
    }
    else {
      alert('hata aldı. posted 2');
    }
  });
}

export const updatePublicationImprint = async (callback: any, parameters: any) => {
  var request = {
    method: 'put',
    url: Constants.API_URL + `Publications/${parameters.id}/Imprint`,
    headers: {
      authorization: ''
    },
    data: parameters.imprint
  };

  let token;
  if (typeof window !== "undefined") {
    token = window.localStorage.getItem("token");
  }

  if (token) {
    request.headers.authorization = `Bearer ${JSON.parse(token)}`;
  }

  axios(request).then(({ data }: any) => {
    if (data && data.success) {
      console.log(data);
      callback(data.data);
    }
    else {
      alert('hata aldı. posted 2');
    }
  });
}