import React from "react";

const ContactUs: React.FC = () => {
  return (
    <div
      style={{
        flex: 1,
        fontSize: 20,
        display: "flex",
        fontWeight: "bold",
        alignSelf: "center",
        alignItems: "center",
      }}
    >
      Bize Yazın
    </div>
  );
};

export default ContactUs;
