import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    position: "relative",
    color: theme.palette.baseLight500,
  },
  dialogTitle: {
    fontSize: 18,
    fontWeight: 500,
    color: "#4F525B",
  },
  maxWidthMd: {
    maxWidth: 1320,
    userSelect: "none"
  },
  groupTitle: {
    fontSize: 24,
    color: "#2B2B2B",
    marginRight: 20,
    fontWeight: '700'
  },
  banner: {
    backgroundColor: theme.palette.grey['200'],
    height:84,
    width:'100%'
  },
  menu: {
    height: 36,
    fontSize: 14,
    marginRight: 12,
    padding: "0 24px",
    borderRadius: 150,
    lineHeight: "36px",
    fontWeight: "bold",
    textDecoration: "none",
    display: "inline-block",
    color: theme.palette.primary.main,
  },
  activeMenu: {
    color: theme.palette.baseWhite,
    backgroundColor: theme.palette.primary.main,
  },
}));

export default useStyles;
