import { OptionsObject, SnackbarOrigin, SnackbarProvider, SnackbarProviderProps, useSnackbar, WithSnackbarProps } from "notistack";
import React from "react";
import Constants from "utils/constants";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import useStyles from "./useStyles";

const defaultAnchorOrigin: SnackbarOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const styledAnchorOrigin: SnackbarOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

let useSnackbarRef: WithSnackbarProps;

export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export default {
  success(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: "success" });
  },
  warning(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: "warning" });
  },
  info(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: "info" });
  },
  error(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: "error" });
  },
  toast(msg: string, options: OptionsObject = {}) {
    useSnackbarRef.enqueueSnackbar(msg, options);
  },
};

const Snackbar: React.FC<SnackbarProviderProps> = (props: SnackbarProviderProps) => {
  const theme = useTheme();
  const innerClasses = useStyles();
  const { classes, ...others } = props;
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <SnackbarProvider
      {...others}
      maxSnack={3}
      hideIconVariant
      preventDuplicate
      className={innerClasses.snackbar}
      classes={{
        ...classes,
        variantInfo: innerClasses.info,
        variantError: innerClasses.error,
        variantSuccess: innerClasses.success,
        variantWarning: innerClasses.warning,
      }}
      autoHideDuration={Constants.SNACKBAR_AUTO_HIDE_DURATION}
      anchorOrigin={isXs ? defaultAnchorOrigin : styledAnchorOrigin}
    >
      <SnackbarUtilsConfigurator />
      {props.children}
    </SnackbarProvider>
  );
};

export { Snackbar, useSnackbar };
