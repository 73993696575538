import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    position: "relative",
    color: theme.palette.baseLight500,
  },
  maxWidthMd: {
    maxWidth: 888,
    userSelect: "none",
    padding: "50px 24px",
  },
}));

export default useStyles;
