import React, { useEffect, useRef, useState } from "react";
import useStyles from "./useStyles";
// Material Components
import { Button, ButtonGroup, ClickAwayListener, Grid, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
// Material Icons
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from '../../../components/hooks';
// Screens
import ContentManagement from "pages/post/posted/contentManagement";
import IssueDefinition from "pages/post/posted/postDefinition/issueDefinition";
import IssueList from "../issueList";
import PostDefinition from "pages/post/posted/postDefinition";
// Components
import Loading from "components/loading";
import DeleteConfirmation from "components/deleteConfirmation";
import Issue from "../issue";
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";


interface Props {
  onClick: (publication: any) => void;
  onDeleteClick: (id: any) => void;
  editionId: any;
  tenantId: any;
  isPublished: any;
  edition: any;
}

const Edition: React.FC<Props> = (props: Props) => {
  const { t, proxyExecute, isXs, isSm, isMd } = useCustomHooks();
  const classes = useStyles();

  const { editionId } = props;
  const [edition, setEdition] = React.useState<any>();
  const [selectedIssueId, setSelectedIssueId] = React.useState<any>();
  const [openContentManagementAsDialog, setOpenContentManagementAsDialog] = React.useState<any>(false);
  const [openCreateIssueAsDialog, setOpenCreateIssueAsDialog] = React.useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [deleteEditionConfirmation, setDeleteEditionConfirmation] = useState(false);
  const [openPostDefinitionAsDialog, setOpenPostDefinitionAsDialog] = useState<boolean>(false);
  const [openIssueList, setOpenIssueList] = useState<boolean>(false);
  const [isHover, setHover] = useState<boolean>(false);

  useEffect(() => {
    if (edition) return;

    if(props.edition)
      setEdition(props.edition.editionDetail);

    // proxyExecute(`Editions/Detail?Id=${editionId}&IsPublished=${props.isPublished}`, HttpMethod.Get, {
    //   setIsLoading: undefined,
    //   callback: (data: any) => { setEdition(data) },
    //   authorizedTenantId: props.tenantId
    // });
  });

  if (!edition) {
    return (
      <Grid container className={classes.issue} onClick={() => edition && props.onClick(edition)}>
        <Loading></Loading>
      </Grid>
    );
  }

  if (edition.issueIdList.length === 0 && props.tenantId)
    return <></>


  const handleToggle = (value: any) => {
    if (value === undefined)
      setOpenContextMenu((prevOpenContextMenu: any) => !prevOpenContextMenu);
    else
      setOpenContextMenu(value);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenContextMenu(false);
  };

  const getContectMenu = () => {
    return <Popper
      style={{ zIndex: 2 }}
      transition
      open={openContextMenu}
      disablePortal role={undefined} anchorEl={anchorRef.current}
      placement={'bottom-end'}>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu">
                {(!props.tenantId || edition.hasAccessToEditPost) && <MenuItem onClick={() => {
                  setOpenPostDefinitionAsDialog(true);
                  setOpenContextMenu(false);
                }}>
                  <Typography>{t('Edit post detail')}</Typography>
                </MenuItem>}
                {(!props.tenantId || edition.hasAccessToDeletePost) && <MenuItem onClick={() => {
                  setOpenContextMenu(false);
                  setDeleteEditionConfirmation(true);
                }}>
                  <Typography>{t('Delete post')}</Typography>
                </MenuItem>}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>;
  }

  return (
    <Grid container >
      <Grid item xs={12} className={classes.issueContainer}>
        <Grid container>
          <div onMouseEnter={() => setHover(true)} onMouseLeave={() => { setHover(false); setOpenContextMenu(false) }}>
            <Grid item xs={12} >

              <Grid container justifyContent="space-between" className={classes.editionTitleWrapper} alignItems={"center"}>
                <Grid item>
                  <Grid container justifyContent="space-between" alignItems="center" justifyItems={"center"}>
                    <Grid>
                      <Typography className={classes.editionName}>{edition.name}</Typography>
                    </Grid>
                    {(!props.tenantId || edition.hasAccessToAddNewIssue) && !isMd && <Grid style={{ marginLeft: 24 }}>
                      <Button variant="outlined" size="small" onClick={() => { setOpenCreateIssueAsDialog(true); }}>
                        {t('Add New Issue')}
                      </Button>
                    </Grid>}
                    {isHover && (!props.tenantId || edition.hasAccessToDeletePost || edition.hasAccessToEditPost) && !isMd && <Grid style={{ marginLeft: 12 }}>
                      <ButtonGroup ref={anchorRef} >
                        <IconButton onClick={handleToggle}><MoreVertIcon  /></IconButton>
                      </ButtonGroup>
                    </Grid>}
                    {getContectMenu()}
                  </Grid>
                </Grid>
                {edition.issueIdList.length > (isXs ? 2 : (isSm ? 3 : 6)) && (
                  <Grid item>
                    <Button variant="outlined" size="small" onClick={() => { setOpenIssueList(true); }}>
                      {t('Tümü')}
                    </Button>
                  </Grid>
                )}
              </Grid>

            </Grid>
            <Grid container spacing={3} style={{ alignItems: 'baseline' }}>
              {edition.issueIdList.slice(0, isXs ? 2 : (isSm ? 3 : 6)).map((issueId: any) => {
                return (
                  <Grid key={issueId} item xs={isXs ? 6 : (isSm ? 4 : 2)}>
                    <Issue issue={edition.issueList.find((x:any)=>x.id == issueId)} tenantId={props.tenantId} issueId={issueId} onClick={(issueId) => {
                      setSelectedIssueId(issueId);
                      setOpenContentManagementAsDialog(true);
                    }}
                      onIssueDeleted={() => { setEdition(undefined) }} />
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Grid>
      </Grid>

      {openContentManagementAsDialog === true &&
        <ContentManagement tenantId={props.tenantId} postType="edition" id={selectedIssueId} onClose={() => {
          setOpenContentManagementAsDialog(false);
          setEdition(undefined);
        }}></ContentManagement>}

      {openCreateIssueAsDialog &&
        <IssueDefinition editionId={editionId}
          onClose={(dialogResponse: any) => {
            setOpenCreateIssueAsDialog(false);
            setEdition(undefined);
          }}></IssueDefinition>}

      {openPostDefinitionAsDialog &&
        <PostDefinition postType={'edition'} post={edition} onClose={(dialogResponse: any) => {
          setOpenPostDefinitionAsDialog(false);
          props.onDeleteClick && props.onDeleteClick(editionId);
        }}></PostDefinition>}

      {deleteEditionConfirmation &&
        <DeleteConfirmation onDeleteClicked={() => {
          setDeleteEditionConfirmation(false);

          var id = editionId;
          proxyExecute(`Editions/${id}`, HttpMethod.Delete, {
            setIsLoading: undefined,
            callback: () => { props.onDeleteClick && props.onDeleteClick(editionId) }
          })
        }}
          onCancelClicked={() => {
            setDeleteEditionConfirmation(false);
          }}></DeleteConfirmation>}

      {openIssueList &&
        <IssueList tenantId={props.tenantId} edition={edition} onClose={() => { setOpenIssueList(false); }}
          onIssueClicked={(issueId) => {
            setSelectedIssueId(issueId);
            setOpenContentManagementAsDialog(true);
          }}
          onNewIssueClicked={() => { setOpenCreateIssueAsDialog(true); }}
          onIssueDeleted={() => { setEdition(undefined) }}
        ></IssueList>}
    </Grid>
  );
};

export default Edition;
