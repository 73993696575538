import { alpha, Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    width: 852,
    padding: 30,
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      padding: "20px 16px",
    },
  },
  dialogPaper: {
    padding: 24,
    boxShadow: '0 1px 5px #00000052',
    borderRadius:8
  },
  row: {
    marginBottom: 8,
  },
  nextButton: {
    marginTop: 20,
    marginBottom: 10,
  },
  title: {
    marginBottom: 8,
  },
  uploadInput: {
    display: "none",
  },
  imagePreview: {
    height: 340,
    width: "100%",
    borderRadius: 12,
    cursor: "pointer",
    overflow: "hidden",
    textAlign: "center",
    position: "relative",
    background: theme.palette.baseLight100
  },
  imagePreviewAfterLoad: {
    width: "100%",
    borderRadius: 12,
    cursor: "pointer",
    overflow: "hidden",
    textAlign: "center",
    position: "relative",
  },
  greyBackground: {
    background: theme.palette.baseLight100,
  },
  uploadImage: {
    left: 16,
    bottom: 16,
    position: "absolute",
    "&.Mui-disabled": {
      color: theme.palette.primary,
      border: `1px solid ${theme.palette.divider}`,
      background: alpha(theme.palette.baseWhite, 0.5),
    },
  },
  image: {
    width: "100%",
    display: "block",
  },
  imageInfo: {
    fontSize: 12,
    color: theme.palette.baseLight500,
  },
  dialogLeftContent: {},
  dialogRightContent: {},
}));

export default useStyles;
