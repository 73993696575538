import React, { useEffect, useState } from "react";
// Material Components
import { Button, Tabs } from "@mui/material";
// Material Icons
import AddIcon from "@mui/icons-material/Add";
// Custom Hooks
import { useCustomHooks } from '../../components/hooks';
// Screens
import PostDefinition from "./posted/postDefinition";
import IssueDefinition from "./posted/postDefinition/issueDefinition";
import PublicationDefinition from "./posted/postDefinition/publicationDefinition";
import Authorized from './authorized';
import Posted from "./posted";
// Components
import TabBar from "components/tabBar";
// Constants&Enums
import { HttpMethod, PostTabs } from "utils/constants/enums";

const Post: React.FC = () => {
  const { t, proxyExecute, isXs, isMd } = useCustomHooks();

  // Variables
  const [refreshData, setRefreshData] = useState(false);
  const [selectedTab, setSelectedTab] = useState<PostTabs>(PostTabs.Authorized);
  const [openCreatePostAsDialog, setOpenCreatePostAsDialog] = useState<boolean>(false);
  const [openCreateIssueAsDialog, setOpenCreateIssueAsDialog] = useState<boolean>(false);
  const [openCreatePublicationAsDialog, setOpenCreatePublicationAsDialog] = useState<boolean>(false);
  const [publicationId, setPublicationId] = useState<any>();
  const [editionId, setEditionId] = useState<any>();

  const handleTabChange = (event: any, value: PostTabs) => {
    setSelectedTab(value);
  };

  useEffect(() => {
    if (refreshData) {
      const timeout = setTimeout(() => {
        setRefreshData(false);
      }, 500);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [refreshData]);

  return <>
    <TabBar hasDivider tabListFixed
      value={selectedTab}
      variant="scrollable"
      items={[
        {
          value: PostTabs.Authorized,
          content: <div style={{
            maxHeight: 'calc(100vh - 105px)',
            overflowY: 'auto',
          }} ><Authorized></Authorized></div>,
          label: <div>{t('Authorized Post')}</div>,
        },
        {
          value: PostTabs.Published,
          content: <div style={{
            maxHeight: 'calc(100vh - 105px)',
            overflowY: 'auto',
          }} ><Posted isApproved={true} isPublished={true} refreshData={refreshData} /></div>,
          label: <div>{t('Published')}</div>,
        },
        {
          value: PostTabs.Draft,
          content: <div style={{
            maxHeight: 'calc(100vh - 105px)',
            overflowY: 'auto',
          }} ><Posted isApproved={true} isPublished={false} refreshData={refreshData} /></div>,
          label: t('Draft'),
        }
      ]}
      onChange={handleTabChange} />

    {!isMd && selectedTab !== PostTabs.Authorized &&<Button size="large" variant="contained" style={{
      top: 82,
      right: 84,
      zIndex: 0,
      borderRadius: 150,
      position: "fixed",
      padding: "11px 22px",
    }} onClick={() => {
      setOpenCreatePostAsDialog(true);
    }}>
      <AddIcon style={{
        marginRight: 10
      }} />
      {t('Add New Post')}
    </Button>}

    {openCreatePostAsDialog &&
      <PostDefinition onClose={(dialogResponse: any) => {
        setOpenCreatePostAsDialog(false);
        if (dialogResponse.result) {
          if (dialogResponse.postType === 'publication') {
            setPublicationId(dialogResponse.id);
            setOpenCreatePublicationAsDialog(true);
          }
          else if (dialogResponse.postType === 'edition') {
            setEditionId(dialogResponse.id);
            setOpenCreateIssueAsDialog(true);
          }
        }
      }}></PostDefinition>}

    {openCreateIssueAsDialog &&
      <IssueDefinition editionId={editionId}
        onClose={(dialogResponse: any) => {
          setOpenCreateIssueAsDialog(false);
          if (!dialogResponse.result) {
            var id = editionId;
            proxyExecute(`Editions/${id}`, HttpMethod.Delete, {
              setIsLoading: undefined,
              callback: () => { }
            });
          }
          else {
            setRefreshData(true);
            setSelectedTab(PostTabs.Draft);
          }
        }}></IssueDefinition>}

    {openCreatePublicationAsDialog &&
      <PublicationDefinition publicationId={publicationId}
        onClose={(dialogResponse: any) => {
          setOpenCreatePublicationAsDialog(false);
          if (!dialogResponse.result) {
            var id = publicationId;
            proxyExecute(`Publications/${id}`, HttpMethod.Delete, {
              setIsLoading: undefined,
              callback: () => { }
            });
          }
          else {
            setRefreshData(true);
            setSelectedTab(PostTabs.Draft);
          }
        }}></PublicationDefinition>}
  </>;
};

export default Post;
