import React, { useState } from "react";
import useStyles from "./useStyles";
// Material Components
import {  Container, Grid } from "@mui/material";
// Material Icons

// Custom Hooks

// Screens

// Components
import TenantContainer from "pages/post/authorized/tenantContainer";
// Constants&Enum
import { PostListType } from "utils/constants/enums";

interface Props {
  postListType?: PostListType;
}

const Authorized: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.pageWrapper}>
      <Grid item xs={12}>
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.container}
          classes={{
            maxWidthMd: classes.maxWidthMd,
          }}>
          <TenantContainer></TenantContainer>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Authorized;