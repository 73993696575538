import React, { useState } from "react";
import useStyles from "./useStyles";
// Material Components
import { Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// Material Icons

// Custom Hooks
import { useCustomHooks } from 'components/hooks';
// Screens

// Components
import Dialog from "components/dialog";
// Constants&Enums
import Constants from "utils/constants";

import { generateUUID } from "utils";
import { addImageToTextboxContent } from "utils/apiCalls/contents";

interface Props {
  onClose?: (dialogResponse: any) => void;
  contentId: string;
  textboxContent?: any;
}

const TextBoxContentDefinition: React.FC<Props> = (props: Props) => {
  const { t, isXs} = useCustomHooks();
  const classes = useStyles();

  const [file, setFile] = useState<any | null>(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | ArrayBuffer | null>(`${props.textboxContent?.imagePath ? Constants.API_URL + props.textboxContent?.imagePath : ""}`);
  const [formData, setFormData] = useState<any>({
    id: generateUUID(),
    ...props.textboxContent
  });

  const dataHandler = (field: any, value: any,) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleImageChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const reader = new FileReader();
    const file = event.target.files && event.target.files[0];

    reader.onloadend = () => {
      if (file) {
        var fileData = {
          name: file.name,
          size: file.size,
          type: file.type,
          value: reader.result,
          lastModified: file.lastModified,
        };
        setFile(fileData);
        addImageToTextboxContent(props.contentId, fileData, (imagePath: any) => {
          dataHandler('imagePath', imagePath)
        });
      }
      reader.result && setImagePreviewUrl(reader.result);
    };

    file && reader.readAsDataURL(file);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const reader = new FileReader();
    const file = event.target.files && event.target.files[0];

    reader.onload = async () => {
      const image = new Image();
      image.onload = async () => {
        const maxWidth = 1800;
        const maxHeight = 4000;
        let width = image.width;
        let height = image.height;

        if (width > maxWidth || height > maxHeight) {
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx?.drawImage(image, 0, 0, width, height);

        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7);
        console.log(compressedBase64);

        // Further processing with compressedBase64

          if(file){
            var fileData = {
              name: file.name,
              size: file.size,
              type: file.type,
              value: compressedBase64,
              lastModified: file.lastModified,
            };
            setFile(fileData);
            addImageToTextboxContent(props.contentId, fileData, (imagePath: any) => {
              dataHandler('imagePath', imagePath)
            });
          }
        reader.result && setImagePreviewUrl(reader.result);
      };

      image.src = reader.result as string;
    };

    // reader.onloadend = () => {
    //   // file &&
    //   //   setFile({
    //   //     name: file.name,
    //   //     size: file.size,
    //   //     type: file.type,
    //   //     value: reader.result,
    //   //     lastModified: file.lastModified,
    //   //   });
    //   // reader.result && setImagePreviewUrl(reader.result);

    //   if(file){
    //     var fileData = {
    //       name: file.name,
    //       size: file.size,
    //       type: file.type,
    //       value: reader.result,
    //       lastModified: file.lastModified,
    //     };
    //     setFile(fileData);
    //     addImageToTextboxContent(props.contentId, fileData, (imagePath: any) => {
    //       dataHandler('imagePath', imagePath)
    //     });
    //   }
    // reader.result && setImagePreviewUrl(reader.result);
    // };

    file && reader.readAsDataURL(file);
  };

  const getDialogLeftContent = () => {
    return (
      <Grid item xs={6} className={classes.dialogLeftContent}>
        <Grid container spacing={3} alignItems="flex-end" justifyContent="space-between">
          <Grid item xs={12}>
            <input type="file" id="image-file" accept="image/*" onChange={handleImageChange} className={classes.uploadInput} />
            <label htmlFor="image-file">
              <Grid
                container
                direction="row"
                alignItems="flex-end"
                alignContent="flex-end"
                className={imagePreviewUrl ? classes.imagePreviewAfterLoad : classes.imagePreview}
              >
                <Grid item xs={12}>
                  {imagePreviewUrl && <img className={classes.image} alt={t('Post Cover')} src={imagePreviewUrl as string} />}
                  <Button disabled variant="outlined" className={classes.uploadImage}>
                    {t('Upload Cover')}
                  </Button>
                </Grid>
              </Grid>
            </label>
          </Grid>
          <Grid item >
            <Typography className={classes.imageInfo}>
              {t('Post cover image width must be minimum 600px')}
              <br />
              {t('The file size must be no more than 1 MB')}
            </Typography>
          </Grid>

        </Grid>
        <Grid className={classes.row} item>
          <TextField
            fullWidth
            type="text"
            label={t('Text under image (Optional)')}
            name="underImageText"
            variant="standard"
            onChange={(value) => { dataHandler('underImageText', value.currentTarget.value) }}
            value={formData.underImageText}
          />
        </Grid>
      </Grid>
    );
  };

  const getDialogRightContent = () => {

    return (
      <Grid item xs={6} className={classes.dialogRightContent}>
        <Grid container direction="column" spacing={1}>
          <Grid className={classes.row} item>
            <Grid className={classes.row} item>
              <TextField
                fullWidth
                type="text"
                label={t('Title')}
                name="header"
                variant="standard"
                onChange={(value) => { dataHandler('header', value.currentTarget.value) }}
                value={formData.header}
              />
            </Grid>
            <Grid className={classes.row} item>
              <TextField
                fullWidth
                multiline
                type="text"
                label={t('Text')}
                name="content"
                variant="standard"
                onChange={(value) => { dataHandler('content', value.currentTarget.value) }}
                value={formData.content}
              />
            </Grid>
            <Grid className={classes.row} item>
              <TextField
                fullWidth
                type="text"
                label={t('Link display name (Optional)')}
                name="linkDisplayName"
                variant="standard"
                onChange={(value) => { dataHandler('linkDisplayName', value.currentTarget.value) }}
                value={formData.linkDisplayName}
              />
            </Grid>
            <Grid className={classes.row} item>
              <TextField
                fullWidth
                type="text"
                label={t('Link (Optional)')}
                name="link"
                variant="standard"
                onChange={(value) => { dataHandler('link', value.currentTarget.value) }}
                value={formData.link}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog open closable={true} fullScreen={isXs} title={t('Add Text Box')} onClose={() => {
      props.onClose && props.onClose(undefined);
    }} showAlwaysTopDivider={true}>
      <Grid container direction="column" className={classes.dialogContent}>
        <Paper className={classes.dialogPaper}>
          <Grid container spacing={3} >
            {getDialogLeftContent()}
            {getDialogRightContent()}
          </Grid>
        </Paper>
        <Grid item>
          <LoadingButton fullWidth size="large" variant="contained" className={classes.nextButton}
            onClick={() => {
              props.onClose && props.onClose(formData);
            }}>
            {t('Ok')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default TextBoxContentDefinition;
