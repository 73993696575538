import "i18n";

import { Snackbar } from "components/snackbar";
import theme from "components/themes";
import { useLocalStorage } from "hooks";
import Post from "pages/post";
import CheckActivationCode from "pages/account/checkActivationCode";
import CheckResetPasswordCode from "pages/account/checkResetPasswordCode";
import ContactUs from "pages/account/contactUs";
import CreateAccount from "pages/account/createAccount";
import ForgotPassword from "pages/account/forgotPassword";
import Home from "pages/home";
import Login from "pages/login";
import Plan from "pages/plan";
import ResetPassword from "pages/account/resetPassword";
import SendEmail from "pages/account/sendEmail";
import Settings from "pages/settings";
import Authorization from "pages/authorization";
import Welcome from "pages/home/welcome";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import PageContainer from "routers/container";
import { useAppDispatch } from "store";
import { loggedIn } from "store/authentication/reducer";

import { Container, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import PrivateRoute from "./privateRouter";
import SelectTenant from "pages/login/selectTenant";
import { useTranslation } from "react-i18next";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const [localStoragetoken] = useLocalStorage("token", "");
  const { i18n } = useTranslation();
  const [languagePageState, setLanguagePageState] = React.useState(0);

  useEffect(() => {

    var lang = localStorage.getItem('language');
    if(!lang){
      var language = {
        languageCode: 'en-EN',
        languageId: '713d1ee2-dbd4-47b9-98f6-4abc65c67d04'
      };
      localStorage.setItem('language', JSON.stringify(language));
      i18n.changeLanguage(language.languageCode);
    }

    if (localStoragetoken) {
      dispatch(loggedIn());
    }
  }, []);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth={false} disableGutters={true}>
          <Snackbar>
            <Switch>
              <Route exact path="/">
                <PageContainer languagePageState={languagePageState}>
                  <Home onLanguagePageChange={(state)=>{setLanguagePageState(state)}}/>
                </PageContainer>
              </Route>
              <Route path="/giris-yap">
                <PageContainer onlyShowLogo>
                  <Login />
                </PageContainer>
              </Route>
              <Route path="/select-tenant">
                <PageContainer onlyShowLogo>
                  <SelectTenant />
                </PageContainer>
              </Route>
              <Route path="/login-aktivasyon">
                <CheckActivationCode />
              </Route>
              <Route path="/sifre-sifirlama-kontrol">
                <CheckResetPasswordCode />
              </Route>
              <Route path="/sifremi-unuttum">
                <PageContainer onlyShowLogo>
                  <ForgotPassword />
                </PageContainer>
              </Route>
              <Route path="/sifre-sifirla">
                <PageContainer onlyShowLogo>
                  <ResetPassword />
                </PageContainer>
              </Route>
              <Route path="/hesap-olustur">
                <PageContainer onlyShowLogo>
                  <CreateAccount />
                </PageContainer>
              </Route>
              <PrivateRoute path="/hos-geldin" isAuthenticated={Boolean(localStoragetoken)}>
                <PageContainer>
                  <Welcome />
                </PageContainer>
              </PrivateRoute>
              <PrivateRoute path="/ekip" isAuthenticated={Boolean(localStoragetoken)}>
                <PageContainer>
                  <Authorization />
                </PageContainer>
              </PrivateRoute>
              <PrivateRoute path="/yayin" isAuthenticated={Boolean(localStoragetoken)}>
                <PageContainer>
                  <Post />
                </PageContainer>
              </PrivateRoute>
              <Route path="/email-aktivasyon">
                <PageContainer>
                  <SendEmail />
                </PageContainer>
              </Route>
              <PrivateRoute path="/plan" isAuthenticated={Boolean(localStoragetoken)}>
                <PageContainer>
                  <Plan />
                </PageContainer>
              </PrivateRoute>
              <PrivateRoute path="/ayarlar" isAuthenticated={Boolean(localStoragetoken)}>
                <PageContainer>
                  <Settings />
                </PageContainer>
              </PrivateRoute>
              <PrivateRoute path="/bize-yazin" isAuthenticated={Boolean(localStoragetoken)}>
                <PageContainer>
                  <ContactUs />
                </PageContainer>
              </PrivateRoute>
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </Snackbar>
        </Container>
      </ThemeProvider>
    </Router>
  );
};

export default App;
