import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  forgotPasswordContainer: {
    padding: 40,
  },
  paper: {
    width: 408,
    padding: "24px 35px",
    boxShadow: '0 1px 5px #00000052',
    borderRadius:8
  },
  logo: {
    fontSize: 16,
    textAlign: "center",
    color: theme.palette.baseBlack,
  },
  title: {
    fontSize: 24,
    marginBottom: 8,
    textAlign: "center",
    color: theme.palette.baseLight700,
  },
  info: {
    fontSize: 12,
    marginTop: 13,
    color: theme.palette.baseLight500,
  },
  sendButton: {
    marginBottom: 60,
  },
}));

export default useStyles;
