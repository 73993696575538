import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  centerContent: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  primary: {
    color: theme.palette.primary.main,
  },
  white: {
    color: theme.palette.baseWhite,
  },
}));

export default useStyles;
