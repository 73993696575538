import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en";
import tr from "./tr";
import { Language } from "./types";
import {getLanguage} from '../utils'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  [Language.EN]: en,
  [Language.TR]: tr,
};

const savedLanguage = getLanguage();

i18n.use(initReactI18next).init({
  resources,
  lng: savedLanguage?.languageCode || Language.EN,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
