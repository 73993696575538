import React from "react";

const MiniLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="40" viewBox="0 0 44 40">
      <g id="Group_24452" dataName="Group 24452" transform="translate(-363 -363)">
        <path id="Path_17852" dataName="Path 17852" d="M44,16.765s0,0,0,0c-.055-.657-4.734-1.426-7.342-1.213-.4.032-.729.61-.966,1.2l-1.354.174A21.27,21.27,0,0,0,33.8,14.7,15.679,15.679,0,0,0,23.7,4.052c-1.821-.55-4.592-1.023-6.5-1.413a1.455,1.455,0,0,0,.535-1.555A1.423,1.423,0,0,0,16.5.035l.009,0s-2.057-.357-2.62,1.394a2.144,2.144,0,0,0,.06,1.4,71.461,71.461,0,0,0-7.269,9.582,39,39,0,0,0-4.042,7.64c-.442.041-.877.1-1.3.168a2.814,2.814,0,0,0-1.159.726.53.53,0,0,0,0,.784l.119.108a.525.525,0,0,1,.17.371.528.528,0,0,1-.077.4l-.09.135a.529.529,0,0,0,.19.76,2.793,2.793,0,0,0,1.3.419c.427-.034.863-.088,1.3-.157A38.559,38.559,0,0,0,8.821,30.18a70.605,70.605,0,0,0,9.332,7.509,2.138,2.138,0,0,0,.275,1.372c.962,1.56,2.874.708,2.874.708l-.009,0a1.449,1.449,0,0,0,.072-2.7c1.754-.847,4.33-1.988,5.967-2.969A15.853,15.853,0,0,0,34.6,21.281a21.336,21.336,0,0,0-.014-2.288l1.355-.164c.369.51.829.989,1.222.923,2.581-.435,6.938-2.333,6.835-2.984v0M7.939,13.243a71.662,71.662,0,0,1,6.914-9.159,2.6,2.6,0,0,0,.615.374,11.224,11.224,0,0,0,2.029.533,26.747,26.747,0,0,1,5.317,1.676c3.9,1.719,6.121,5,7.154,9.119.151.549.275,1.095.379,1.637l-21.6,2.735a36.05,36.05,0,0,0-4.471-.2,44.206,44.206,0,0,1,3.663-6.711m22.679,7.924c-.022,4.252-1.4,7.981-4.773,10.609a26.993,26.993,0,0,1-4.761,2.935,11.318,11.318,0,0,0-1.842,1.016,2.633,2.633,0,0,0-.507.514,70.75,70.75,0,0,1-8.887-7.185,43.68,43.68,0,0,1-5.15-5.61,36.2,36.2,0,0,0,4.289-1.3L30.6,19.485c.029.552.038,1.112.022,1.682" transform="translate(363 363)" fill="#f43f5e" />
      </g>
    </svg>
  );
};

export default MiniLogo;
