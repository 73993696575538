import { createTheme } from "@mui/material/styles";

import LightTheme from "./common/light";
import overrides from "./overrides";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    primaryVariant: string;
    secondaryVariant: string;
    paper: string;
    surface: string;
    baseWhite: string;
    baseLight100: string;
    baseLight200: string;
    baseLight300: string;
    baseLight400: string;
    baseLight500: string;
    baseLight600: string;
    baseLight700: string;
    baseBlack: string;
    baseDark100: string;
    baseDark200: string;
    baseDark300: string;
    baseDark400: string;
    baseDark500: string;
    baseDark600: string;
    baseDark700: string;
    red: string;
    blue: string;
    green: string;
    orange: string;
    yellow: string;
  }

  interface Palette {
    primaryVariant: string;
    secondaryVariant: string;
    paper: string;
    surface: string;
    baseWhite: string;
    baseLight100: string;
    baseLight200: string;
    baseLight300: string;
    baseLight400: string;
    baseLight500: string;
    baseLight600: string;
    baseLight700: string;
    baseBlack: string;
    baseDark100: string;
    baseDark200: string;
    baseDark300: string;
    baseDark400: string;
    baseDark500: string;
    baseDark600: string;
    baseDark700: string;
    red: string;
    blue: string;
    green: string;
    orange: string;
    yellow: string;
  }
}

const { palette } = createTheme();

const theme = createTheme({
  spacing: 8,
  palette: LightTheme,
  components: overrides(palette),
  breakpoints: {
    values: {
      xs: 0,
      sm: 600, // mobil
      md: 1024, //840, // tablet
      lg: 1320,
      xl: 1600
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Lexend",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export default theme;
