import React, { useEffect, useState } from "react";
import useStyles from "./useStyles";
// Material Components
import { Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// Material Icons

// Custom Hooks
import { useCustomHooks } from '../../../../../components/hooks';
// Screens

// Components
import DatePicker from "components/datePicker";
import Dialog from "components/dialog";
import Loading from "components/loading";

import { generatingYears, Year } from "utils";
import Constants from "utils/constants";

import { getIssueById, saveIssue, updateIssue } from "utils/apiCalls/issues";
import { getEditionById } from "utils/apiCalls/editions";
import { getPeriodItems } from "utils/apiCalls/periods";
import { HttpMethod } from "utils/constants/enums";

interface Props {
  onClose?: (dialogResponse: any) => void;
  editionId: string;
  issueId?: any;
}

const IssueDefinition: React.FC<Props> = (props: Props) => {
  const { t, proxyExecute, showErrorMessage, showInfoMessage, isXs } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [years, setYears] = useState<Year[]>([]);
  const [edition, setEdition] = useState<any>();
  const [periodItems, setPeriodItems] = useState<any>();
  const [formData, setFormData] = useState<any>({
    editionId: props.editionId,
    date: new Date().toISOString(),
    language: 'ENG'
  });
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dataHandler = (field: any, value: any,) => {
    setFormData({ ...formData, [field]: value });
  };

  useEffect(() => {
    setYears(generatingYears());
    if (!edition) {
      proxyExecute(`Editions/Detail?Id=${props.editionId}`, HttpMethod.Get, {
        setIsLoading: undefined,
        callback: (data: any) => {
          setEdition(data);
          var periodId = data.period.id;
          proxyExecute(`Periods/List/${periodId}`, HttpMethod.Get, {
            setIsLoading: undefined,
            callback: (periodItems: any) => { setPeriodItems(periodItems) }
          })
        }
      });
    }

    if (props.issueId) {
      proxyExecute(`Issues/Detail?Id=${props.issueId}`, HttpMethod.Get, {
        setIsLoading: undefined,
        callback: (data: any) => {
          data.imagePath = `${Constants.API_URL}${data.imagePath}`;
          setFormData(data);
        }
      });
    }
  }, []);

  // const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   event.preventDefault();

  //   const reader = new FileReader();
  //   const file = event.target.files && event.target.files[0];

  //   reader.onloadend = () => {
  //     if (!file || !reader.result) {
  //       showErrorMessage('Image could not load')
  //       return;
  //     }

  //     setFormData({
  //       ...formData,
  //       file: {
  //         name: file?.name,
  //         size: file?.size,
  //         type: file?.type,
  //         value: reader.result,
  //         lastModified: file?.lastModified,
  //       },
  //       imagePath: reader.result
  //     })
  //   };
  //   file && reader.readAsDataURL(file);
  // };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const reader = new FileReader();
    const file = event.target.files && event.target.files[0];

    reader.onload = async () => {
      const image = new Image();
      image.onload = async () => {
        const maxWidth = 1800;
        const maxHeight = 4000;
        let width = image.width;
        let height = image.height;

        if (width > maxWidth || height > maxHeight) {
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx?.drawImage(image, 0, 0, width, height);

        const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7);
        console.log(compressedBase64);

        // Further processing with compressedBase64

        // file &&
        //   setFile({
        //     name: file.name,
        //     size: file.size,
        //     type: file.type,
        //     value: compressedBase64,
        //     lastModified: file.lastModified,
        //   });
        // reader.result && setImagePreviewUrl(reader.result);
        setFormData({
          ...formData,
          file: {
            name: file?.name,
            size: file?.size,
            type: file?.type,
            value: compressedBase64,
            lastModified: file?.lastModified,
          },
          imagePath: reader.result
        })
      };

      image.src = reader.result as string;
    };

    // reader.onloadend = () => {
    //   file &&
    //     setFile({
    //       name: file.name,
    //       size: file.size,
    //       type: file.type,
    //       value: reader.result,
    //       lastModified: file.lastModified,
    //     });
    //   reader.result && setImagePreviewUrl(reader.result);
    // };

    file && reader.readAsDataURL(file);
  };

  const getDialogLeftContent = () => {
    return (
      <Grid item xs={6} className={classes.dialogLeftContent}>
        <Grid container spacing={3} alignItems="flex-end" justifyContent="space-between">
          <Grid item xs={8}>
            <input type="file" id="image-file" accept="image/*" onChange={handleImageChange} className={classes.uploadInput} />
            <label htmlFor="image-file">
              <Grid
                container
                direction="row"
                alignItems="flex-end"
                alignContent="flex-end"
                className={formData.imagePath ? classes.imagePreviewAfterLoad : classes.imagePreview}
              >
                <Grid item xs={12}>
                  {formData.imagePath && <img className={classes.image} alt={t('Post Cover')} src={formData.imagePath as string} />}
                  <Button disabled variant="outlined" className={classes.uploadImage}>
                    {t('Upload Cover')}
                  </Button>
                </Grid>
              </Grid>
            </label>
          </Grid>
          <Grid item >
            <Typography className={classes.imageInfo}>
              {t('Post cover image width must be minimum 600px')}
              <br />
              {t('The file size must be no more than 1 MB')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getPeriodItemTitle = (periodCode: any) => {
    const periodTitles: any = {
      'Daily': t('Daily'),
      'Weekly': t('Weekly'),
      'Monthly': t('Monthly'),
      'TwoMonthly': t('TwoMonthly'),
      'Quarterly': t('Quarterly'),
      'Seasonal': t('Seasonal'),
      'SemiAnnual': t('SemiAnnual'),
      'Annual': t('Annual'),
    };
    return periodTitles[periodCode] || t('Not found');
  }

  const dateTimePicker = () => {
    if (!edition) return false;

    if (edition.period.code !== 'Daily' && edition.period.code !== 'Weekly') {
      return;
    }

    return <Grid className={classes.row} item>
      <DatePicker
        value={formData.date}
        label={t('Date')}
        onChange={(date: Date | null) => dataHandler('date', date)}
        error={Boolean(errors.date)}
        helperText={errors.date} />
    </Grid>;
  }

  const periodItemSelect = () => {
    if (!edition || !periodItems) return;

    if (edition.period.code === 'Daily' || edition.period.code === 'Weekly' || edition.period.code === 'Annual') {
      return;
    }

    return <Grid className={classes.row} item>
      <FormControl fullWidth variant="standard">
        <InputLabel id="periodItem">{getPeriodItemTitle(edition.period.code)}</InputLabel>
        <Select
          name="periodItem"
          labelId="periodItem"
          value={formData.periodItemId}
          onChange={(value) => {
            dataHandler('periodItemId', value?.target?.value);
          }}
          error={Boolean(errors.periodItemId)}
        >
          {periodItems.map((periodItem: any) => {
            return (
              <MenuItem key={periodItem.id} value={periodItem.id}>
                {periodItem.name}
              </MenuItem>
            );
          })}
        </Select>
        {errors.periodItemId && <FormHelperText error>{errors.periodItemId}</FormHelperText>}
      </FormControl>
    </Grid>;
  }

  const yearSelect = () => {
    if (!edition || !years) return false;

    if (edition.period.code === 'Daily' || edition.period.code === 'Weekly') {
      return;
    }

    return <Grid className={classes.row} item>
      <FormControl fullWidth variant="standard">
        <InputLabel id="year">{t('Year')}</InputLabel>
        <Select
          name="year"
          labelId="year"
          onChange={(value) => {
            dataHandler('year', value?.target?.value);
          }}
          value={formData.year}
          error={Boolean(errors.year)}
        >
          {years.map((year) => {
            return (
              <MenuItem key={year.key} value={year.key}>
                {year.value}
              </MenuItem>
            );
          })}
        </Select>
        {errors.year && <FormHelperText error>{errors.year}</FormHelperText>}
      </FormControl>
    </Grid>;
  }

  const getDialogRightContent = () => {
    if (!edition) return <Loading />;

    return (
      <Grid item xs={6} className={classes.dialogRightContent}>
        <Grid container direction="column" spacing={1}>
          <Grid className={classes.row} item>
            <Typography style={{ marginBottom: '24px' }}>{edition?.name}</Typography>
          </Grid>
          <Grid className={classes.row} item>
            <FormControl fullWidth variant="standard">
              <InputLabel id="language">{t('Language')}</InputLabel>
              <Select
                name="language"
                labelId="language"
                value={formData.language}
                onChange={(value) => {
                  dataHandler('language', value?.target?.value);
                }}
              >
                <MenuItem value="TUR">{t('Turkish')}</MenuItem>
                <MenuItem value="ENG">{t('English')}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid className={classes.row} item>
            <TextField
              fullWidth
              type="number"
              label={t('Issue Number')}
              name="issueNumber"
              variant="standard"
              onChange={(value) => { dataHandler('issueNumber', value.currentTarget.value) }}
              value={formData.issueNumber}
              error={Boolean(errors.issueNumber)}
              helperText={errors.issueNumber}
            />
          </Grid>
          {periodItemSelect()}
          {dateTimePicker()}
          {yearSelect()}
        </Grid>
      </Grid>
    );
  };

  const validate = () => {
    var errorMessages: any = {};
    if (!formData.imagePath) {
      showInfoMessage('Upload cover photo');
      errorMessages.image = t('Upload cover photo');
    }
    if (!formData.issueNumber || formData.issueNumber === '') {
      errorMessages.issueNumber = t('This field is required');
    }
    if (edition.period.code !== 'Daily' && edition.period.code !== 'Weekly') {
      if (!formData.year || formData.year.length === 0) {
        errorMessages.year = t('This field is required');
      }
      if (!formData.periodItemId || formData.periodItemId.length === 0) {
        errorMessages.periodItemId = t('This field is required');
      }
    }
    else {
      if (!formData.date) {
        errorMessages.date = t('This field is required');
      }
    }
    setErrors(errorMessages);
    return Object.keys(errorMessages).length === 0;
  }

  return (
    <Dialog open closable={true} fullScreen={isXs} title={t('Issue detail')} onClose={() => {
      props.onClose && props.onClose({ result: false });
    }} showAlwaysTopDivider={true}>
      <Grid container direction="column" className={classes.dialogContent}>
        <Paper className={classes.dialogPaper}>
          <Grid container spacing={3}>
            {getDialogLeftContent()}
            {getDialogRightContent()}
          </Grid>
        </Paper>
        <Grid item>
          <LoadingButton loading={isLoading} fullWidth size="large" type="submit" variant="contained" className={classes.nextButton}
            onClick={() => {
              if (!validate()) return;

              if (!formData.periodItemId)
                formData.periodItemId = edition.period.id;

              if (props.issueId) {
                proxyExecute(`Issues`, HttpMethod.Put, {
                  body: formData,
                  setIsLoading,
                  callback: () => { props.onClose && props.onClose({ result: true }) }
                })
              }
              else {
                proxyExecute(`Issues/Create`, HttpMethod.Post, {
                  body: formData,
                  setIsLoading,
                  callback: () => { props.onClose && props.onClose({ result: true }) }
                });
              }
            }}>
            {t('Ok')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default IssueDefinition;