import clsx from "clsx";
import React from "react";

import { CircularProgress } from "@mui/material";

import useStyles from "./useStyles";

interface Props {
  size?: number;
  color?: "white";
}

const Loading: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.centerContent}>
      <CircularProgress
        size={props.size || 30}
        className={clsx({
          [classes.white]: props.color === "white",
          [classes.primary]: props.color === undefined,
        })}
      />
    </div>
  );
};

export default Loading;
