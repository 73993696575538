import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    width: 512,
    padding: 30,
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      padding: "20px 16px",
    },
  },
  dialogPaper: {
    padding: 24,
    boxShadow: '0 1px 5px #00000052',
    borderRadius:8
  },
  infoIcon: {
    color: "#666666",
  },
  plusIcon: {
    color: "#F43F5E",
  },
  row: {
    marginBottom: 8
  },
  nextButton: {
    marginTop: 20,
    marginBottom: 10,
  },
  title: {
    marginBottom: 8,
  },
  checkIcon: {
    height: 36,
    color: "white",
  },
  color: {
    margin: 6,
    width: 36,
    height: 36,
    float: "left",
    cursor: "pointer",
    borderRadius: 150,
    textAlign: "center",
  },
  grey: {
    background: "#6E717A",
  },
  blue: {
    background: "#03A9F4",
  },
  aqua: {
    background: "#00BCD4",
  },
  green: {
    background: "#4CAF50",
  },
  yellow: {
    background: "#FDD835",
  },
  orange: {
    background: "#FF9800",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  dialogTitle: {
    fontSize: 16,
    color: "#F43F5E",
  },
  tooltip: {},
  tooltipIcon: {
    color: "#6E717A",
    display: "block",
  },
}));

export default useStyles;
