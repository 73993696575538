import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitleWrapper: {
    padding: "6px 20px 6px 16px"
  },
  dialogTitle: {
    flex: 1,
    fontSize: 16,
    fontWeight: 500,
    overflow: "hidden",
    textAlign: "center",
    alignContent:"center",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: theme.palette.baseBlack,
    marginRight:"-44px",
    [theme.breakpoints.only("xs")]: {
      marginLeft: 28,
      textAlign: "left",
      marginRight: "auto",
    },

  },
  titleTextAlignLeft: {
    marginLeft: 32,
    textAlign: "left",
    marginRight: "auto",
    paddingRight: 24
  },
  closeIcon: {
    position: "relative",
    color: theme.palette.baseLight500,
  },
  fullHeight: {
    height: "100%",
  },
  fullWidth: {
    width: "100%",
    maxWidth: "calc(100% - 64px)",
    [theme.breakpoints.only("xs")]: {
      maxWidth: "100%",
    },
  },
  leftActions: {
    
  },
  rightActions: {

    
  },
}));

export default useStyles;
