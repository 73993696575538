import { useSnackbar } from "components/snackbar";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useLazyGetCheckActivationQuery } from "store/authentication/api";

const CheckActivationCode: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["success", "error"]);
  const queryObject = queryString.parse(location.search);
  const [checkActivation] = useLazyGetCheckActivationQuery();

  const check = () => {
    checkActivation(queryObject.code)
      .unwrap()
      .then(({ success }) => {
        if (success) {
          history.replace({ pathname: "giris-yap" });
          enqueueSnackbar(t("success:accountActivated"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("error:alreadyActivated"));
          history.replace({ pathname: "giris-yap" });
        }
      });
  };

  useEffect(() => check(), []);

  return null;
};

export default CheckActivationCode;
