import React, { useState, useEffect } from "react";
import useStyles from "./useStyles";
// Material Components
import { Button, Container, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from "@mui/material";
// Material Icons
import { Edit, Delete as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from '../../../components/hooks';
// Screens
import { AddMember } from './addMember'
import { MemberDetail } from './memberDetail'
// Components
import DeleteConfirmation from "components/deleteConfirmation";
import EnhancedTable from '../../../components/dataGrid/dataGrid'
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";

const Members: React.FC = () => {
  const { t, proxyExecute } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [selectedPost, setSelectedPost] = useState('-');
  const [showAddMemberForm, setShowAddMemberForm] = useState<boolean>(false);
  const [showMemberDetailForm, setShowMemberDetailForm] = useState<boolean>(false);
  const [members, setMembers] = useState<any>();
  const [posts, setPosts] = useState<any>();
  const [selectedMembers, setSelectedMembers] = useState<any>([]);
  const [deleteEditionConfirmation, setDeleteEditionConfirmation] = useState(false);

  useEffect(() => {
    if (posts) return;

    proxyExecute(`Posts`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => { setPosts([{ id: '-', name: t('All') }, ...data]); }
    });

  }, [posts, proxyExecute, t]);

  useEffect(() => {
    if (members) return;
    var postId = undefined;
    if (selectedPost !== '-') {
      postId = selectedPost;
    }
    if (postId === undefined) {
      proxyExecute(`Members`, HttpMethod.Get, {
        setIsLoading: undefined,
        callback: (data: any) => {
          arrangeMembers(data);
        }
      });
    }
    else {
      proxyExecute(`MemberPosts/ByPostId/${postId}`, HttpMethod.Get, {
        setIsLoading: undefined,
        callback: (data: any) => {
          arrangeMembers(data);
        }
      });
    }
  }, [members, proxyExecute, selectedPost]);

  const arrangeMembers = (data: any) => {
    if (!data) {
      setMembers([]);
    }
    else {
      setMembers(data.map((item: any) => {
        return {
          ...item,
          fullname: item.firstname + ' ' + item.lastname
        };
      }));
    }
  }

  useEffect(() => {
    setSelectedMembers(selectedMembers);
  }, [selectedMembers]);

  const headCells = [
    {
      id: 'fullname',
      numeric: false,
      disablePadding: true,
      label: t('Name')
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('Email')
    },
    {
      id: 'authorizedPosts',
      numeric: false,
      disablePadding: false,
      label: t('Authorized Posts')
    }
  ];

  return (
    <Grid container direction="column" className={classes.pageWrapper}>
      <Grid item >
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.container}
          classes={{
            maxWidthMd: classes.maxWidthMd,
          }}
        >
          <Grid container xs={12}>
            {posts && <Grid style={{ marginBottom: 8 }} xs={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="post">{t('Post')}</InputLabel>
                <Select
                  name="port"
                  labelId="port"
                  value={selectedPost}
                  onChange={(value) => {
                    setSelectedPost(value?.target?.value || '');
                    setMembers(undefined);
                  }}
                >
                  {
                    posts.map((post: any) => {
                      var id = post.id;
                      var name = post.name;
                      if (post.editionId) {
                        id = 'e-' + post.editionId;
                        name = <Grid><Typography>{post.name}</Typography><Typography style={{
                          fontSize: 10,
                          color: "#24272A"
                        }}>{t('(Edition)')}</Typography></Grid>
                      }
                      else if (post.publicationId) {
                        id = 'p-' + post.publicationId;
                        name = post.name;
                      }

                      return <MenuItem key={id} value={id}>{name}</MenuItem>;
                    })
                  }
                </Select>
              </FormControl>
            </Grid>}
            <Grid xs={12}>
              {members && <EnhancedTable
                rows={members}
                columns={headCells}
                headerTitle={t('{{count}} records listed', { count: members.length })}
                headerRightContent={
                  <Grid container direction={'row'} justifyContent="flex-end">
                    <Button key={123} size="small" variant="contained" onClick={(data: any) => {
                      setShowAddMemberForm(true);
                    }}>
                      <AddIcon className={classes.addIcon} />
                      {t('Add Member')}
                    </Button>
                  </Grid>
                }
                headerRightContent2={
                  <Grid container direction={'row'} justifyContent="flex-end">
                    <IconButton key={123} onClick={() => {
                      setDeleteEditionConfirmation(true);
                    }}>
                      <DeleteIcon className={classes.addIcon} />
                    </IconButton>
                    <IconButton key={123} onClick={() => {
                      setShowMemberDetailForm(true);
                    }}>
                      <Edit className={classes.addIcon} />
                    </IconButton>
                  </Grid>
                }
                onSelected={(selected) => {
                  setSelectedMembers(selected);
                }}
              ></EnhancedTable>}
            </Grid>
          </Grid>
        </Container>
      </Grid>

      {showAddMemberForm &&
        <AddMember onClose={() => {
          setShowAddMemberForm(false);
          setMembers(undefined);
        }}>
        </AddMember>}

      {showMemberDetailForm &&
        <MemberDetail selectedMember={members.find((x: any) => x.id === selectedMembers[0])} onClose={() => {
          setShowMemberDetailForm(false);
          setMembers(undefined);
        }}>
        </MemberDetail>}

      {deleteEditionConfirmation &&
        <DeleteConfirmation onDeleteClicked={() => {
          setDeleteEditionConfirmation(false);
          selectedMembers.map((id: any) => {
            proxyExecute(`Members/${id}`, HttpMethod.Delete, {
              setIsLoading: undefined,
              callback: () => { setMembers(undefined) }
            })
          })
        }}
          onCancelClicked={() => {
            setDeleteEditionConfirmation(false);
          }}>
        </DeleteConfirmation>}
    </Grid>
  );
};

export default Members;