import { CSSProperties } from "react";
import { DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";

export const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined): CSSProperties => {
  return {

    userSelect: "none",
    background: "white",
    ...draggableStyle,
  };
};

export const getQuestionListStyle = (isDraggingOver: boolean): CSSProperties => ({

  background: "white",
});

export const getAnswerListStyle = (isDraggingOver: boolean): CSSProperties => ({

  background: "white",
});
