import clsx from "clsx";
import React, { useEffect } from "react";
import {useCustomHooks} from '../hooks'

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";

import { TabBarDefaultProps, TabBarItem, TabBarProps } from "./types";
import useStyles from "./useStyles";

const TabBar = (props: TabBarProps): JSX.Element => {
  const [value, setValue] = React.useState<string>(props.value);
  const classes = useStyles({ disabled: props.disabled });
  const { isMd } = useCustomHooks();

  const onChange = (event: React.ChangeEvent<any>, newValue: string) => {
    setValue(newValue);
    props.onChange && props.onChange(event, newValue);
  };

  useEffect(() => setValue(props.value), [props.value]);

  return (
    <TabContext data-testid="tabContext" value={value}>
      <div
        className={clsx({
          [classes.hasDivider]: props.hasDivider,
          [classes.rightBorder]: props.isVertical,
          [classes.tabListFixed]: props.tabListFixed,
          [classes.rightButtonContent]: props.rightButton,
        })}
      >
        {props.rightButton && <div className={classes.rightButtonWrapper}>{props.rightButton}</div>}
        <TabList
          onChange={onChange}
          textColor="primary"
          data-testid="tabList"
          variant={props.variant}
          centered={props.centered}
          indicatorColor="primary"
          orientation={props.isVertical ? "vertical" : "horizontal"}
          className={props.isVertical ? classes.verticalTabs : (isMd ? classes.horizontalTabsMobile : classes.horizontalTabs)}
        >
          {props.items.map((item: TabBarItem) => {
            return (
              <Tab
                key={item.value}
                value={item.value}
                label={item.label}
                data-testid={item.label}
                disabled={props.disabled || item.disabled}
                classes={{
                  root: props.isVertical ? classes.verticalTabRoot : classes.tabRoot,
                  textColorPrimary: props.isVertical ? classes.verticalText : classes.textColorPrimary,
                }}
              />
            );
          })}
        </TabList>
      </div>
      {props.items.map((item: TabBarItem) => {
        return (
          <TabPanel
            key={item.value}
            value={item.value}
            className={clsx(classes.tabPanelRoot, {
              [classes.activeTabPanelRoot]: item.value === value,
              [classes.verticalActiveTabPanelRoot]: item.value === value && props.isVertical,
            })}
          >
            {item.content}
          </TabPanel>
        );
      })}
    </TabContext>
  );
};

TabBar.defaultProps = TabBarDefaultProps;

export default TabBar;
