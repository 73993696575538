import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    padding: 30,
    textAlign: "center",
  },
  image: {
    width: 200,
    height: 176,
    marginBottom: 50,
    display: "inline-block",
    background: theme.palette.baseDark500,
  },
  nameText: {
    fontSize: 30,
    marginBottom: 5,
    fontWeight: "bold",
    color: theme.palette.baseDark300,
  },
  info1: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 40,
    color: theme.palette.baseDark600,
  },
  info2: {
    fontSize: 16,
    marginBottom: 50,
    color: theme.palette.baseDark300,
  },
}));

export default useStyles;
