import React, { } from "react";
import { Masonry } from "@mui/lab";
import TextBoxContent from "./textBoxContent";
// Custom Hooks
import { useCustomHooks } from '../../components/hooks';

interface Props {
  isReadOnly?: boolean;
  contentDetail: any;
  onDeleteClick: (id: any) => void;
  onEditClick: (id: any) => void;
}

const TextBoxContentContainer: React.FC<Props> = (props) => {
  const { isXs } = useCustomHooks();

  if (!props.contentDetail || props.contentDetail.length === 0)
    return <></>;

  return (

    <Masonry columns={isXs ? 1 : 2} spacing={3} style={{ marginTop: 15 }}>
      {props.contentDetail.map((item: any) => (
        <TextBoxContent isReadOnly={props.isReadOnly} content={item} onClick={() => { }} onDeleteClick={(id: any) => {
          props.onDeleteClick && props.onDeleteClick(id);
        }}
          onEditClick={(id: any) => {
            props.onEditClick && props.onEditClick(id);
          }}>

        </TextBoxContent>
      ))}
    </Masonry>
  );
};

export default TextBoxContentContainer;
