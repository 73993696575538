import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  loginContainer: {
    padding: 40,
  },
  paper: {
    width: 408,
    padding: "24px 35px",   
    boxShadow: '0 1px 5px #00000052',
    borderRadius:8
  },
  logo: {
    fontSize: 16,
    textAlign: "center",
    color: theme.palette.baseBlack,
  },
  title: {
    fontSize: 24,
    marginBottom: 8,
    textAlign: "center",
    color: theme.palette.baseLight700,
  },
  passwordInfo: {
    fontSize: 12,
    color: theme.palette.baseLight500,
  },
  rememberMeInfo: {
    fontSize: 14,
    margin: "10px 0",
    color: theme.palette.baseLight600,
  },
  loginButton: {
    marginBottom: 60,
  },
  signInInfo: {
    fontSize: 16,
    marginBottom: 16,
    textAlign: "center",
    color: theme.palette.baseLight600,
  },
  forgotPasswordWrapper: {
    textAlign: "center",
  },
  forgotPassword: {
    fontSize: 14,
    fontWeight: 500,
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  passwordInfoButton: {
    padding: 0,
  },
  adornment: {
    color: theme.palette.baseLight600,
    fontSize: 20,
  },
  progress: {
    color: theme.palette.baseWhite,
  },
}));

export default useStyles;
