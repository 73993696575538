import { Icons } from "components/icons";
import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "store";
import { selectLogin } from "store/authentication/reducer";

import { AppBar as MuiAppBar, Button, Grid, Link, Toolbar, IconButton, Drawer, List, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, Typography, ButtonGroup } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import User from "./user";
import useStyles from "./useStyles";

// Custom Hooks
import { useCustomHooks } from 'components/hooks';
// Material Icons
import { Menu as MenuIcon } from "@mui/icons-material";
import MenuComponent from "components/menuComponent";

interface MenuItemType {
  title: string;
  routerLink: string;
}

interface Props {
  onlyShowLogo?: boolean;
  languagePageState: any;
}

const AppBar: React.FC<Props> = (props: Props) => {
  const { t, isSm, isMd, getCache } = useCustomHooks();
  const theme = useTheme();
  const classes = useStyles();
  const isLogin = useAppSelector(selectLogin);
  // const customMediaQuery = `(max-width: 1015px)`; // Define your custom media query here
  // const isShowAppbar = useMediaQuery(customMediaQuery);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [openContextMenu, setOpenContextMenu] = useState(false);

  const appBarMenu: MenuItemType[] = [
    { title: t('Main Page'), routerLink: "" },
    { title: t('Post'), routerLink: "yayin" },
    { title: t('Team'), routerLink: "ekip" },
    // { title: t('Plan'), routerLink: "plan" },
    // { title: t('Contact Us'), routerLink: "bize-yazin" },
    // { title: t('Settings'), routerLink: "ayarlar" },
  ];

  const [menu] = useState<Array<MenuItemType>>(appBarMenu);

  const renderMenu = () => {
    if (props.onlyShowLogo) return null;
    //if (!isLogin) return null;
    if (isMd) return null;


    return menu.map((item: MenuItemType) => {
      return (
        <NavLink exact key={item.title} to={item.routerLink} className={classes.menu} activeClassName={classes.activeMenu}>
          {item.title}
        </NavLink>
      );
    });
  };

  const renderAccount = () => {
    if (props.onlyShowLogo) return null;
    if (!isLogin) return null;

    return <User />;
  };

  const renderLoginAndCreateAccountButtons = () => {
    if (props.onlyShowLogo) return null;
    if (isLogin) return null;

    return (
      <>
        <NavLink exact to="hesap-olustur">
          <Button className={classes.signInButton}>{t('Create Account')}</Button>
        </NavLink>
        <NavLink exact to="giris-yap">
          <Button variant="contained" className={classes.loginButton}>
            {t('Log In')}
          </Button>
        </NavLink>
      </>
    );
  };

  const handleToggle = (value: any) => {
    if (value === undefined)
      setOpenContextMenu((prevOpenContextMenu: any) => !prevOpenContextMenu);
    else
      setOpenContextMenu(value);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenContextMenu(false);
  };

  console.log(window.location.href)
  console.log(window.location.href.indexOf('/'))
  console.log(window.location.href.length)
  console.log('pathname:' +window.location.pathname)

  const getContectMenu = () => {
    return <Popper
      style={{ zIndex: 50000 }}
      transition
      open={openContextMenu}
      disablePortal role={undefined} anchorEl={anchorRef.current}
      placement={'bottom-end'}>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu">
                <MenuItem >
                  <Typography>{t('Edit post detail')}</Typography>
                </MenuItem>
                <MenuItem >
                  <Typography>{t('Delete post')}</Typography>
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>;
  }

  return (
    <MuiAppBar elevation={0} style={{
      top: 0,
      zIndex: window.location.pathname == '/' ? 1 : 0 ,
      position: "fixed",
      backgroundColor: theme.palette.baseWhite,
      borderBottom: `1px solid ${theme.palette.divider}`,
      justifyContent: "center",
      height: 56,
      paddingRight:  (props.languagePageState == 1 ? '15px' : '0px')
    }} position="relative">
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item style={{
                marginRight: 50,
                color: theme.palette.baseBlack
              }}>
                <Link style={{
                  textDecoration: "none",
                  verticalAlign:"bottom"
                }} href={'https://ninovien.net'}>
                  <Icons.Logo />
                </Link>
                <span className={classes.betaText}>BETA</span>
              </Grid>
              {!isMd && <Grid item>{renderMenu()}</Grid>}
            </Grid>
          </Grid>
          <Grid item>
            {renderAccount()}
            {renderLoginAndCreateAccountButtons()}
          </Grid>

        </Grid>
        {isMd && <Grid style={{ marginLeft: 12, height: 32 }}>
          <ButtonGroup ref={anchorRef} >
            <IconButton onClick={handleToggle} style={{ height: 36, width: 36 }} aria-label="language"
              aria-controls="language-menu"
              aria-haspopup="true"
              color="primary">
              <MenuIcon />
            </IconButton>
          </ButtonGroup>
        </Grid>}

        {openContextMenu && <MenuComponent onClose={() => {
          setOpenContextMenu(false);
        }}></MenuComponent>}
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
