import React, { useState } from "react";

// Material Components

// Material Icons

// Custom Hooks
import { useCustomHooks } from '../../components/hooks';
// Screens
import Members from './members'
import ManagersAndOWners from "./managersAndOwners";
// Components
import TabBar from "components/tabBar";
import { TabBarItem } from "components/tabBar/types";
import { AuthorizationTabs } from "utils/constants/enums";
// Constants&Enums

const Authorization: React.FC = () => {
  const { t } = useCustomHooks();

  // Variables
  const [selectedTab, setSelectedTab] = useState<AuthorizationTabs>(AuthorizationTabs.Members);

  const handleTabChange = (event: any, value: AuthorizationTabs) => {
    setSelectedTab(value);
  };

  const getSearchType = (): Array<TabBarItem> => {
    return [
      {
        value: AuthorizationTabs.Members,
        content: <Members />,
        label: t('Members'),
      },
      {
        value: AuthorizationTabs.MemberGroups,
        content: (
          <div
            style={{
              flex: 1,
              fontSize: 20,
              display: "flex",
              fontWeight: "bold",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            {t('Member Groups')}
          </div>
        ),
        label: <div>{t('Member Groups')}</div>,
      },
      {
        value: AuthorizationTabs.ManagersAndOwners,
        content: <ManagersAndOWners />,
        label: t('Managers And Owners'),
      },
    ];
  };

  return <TabBar hasDivider tabListFixed centered={false} value={selectedTab} items={getSearchType()} onChange={handleTabChange} />;
};

export default Authorization;
