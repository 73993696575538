import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
  },
  maxWidthMd: {
    maxWidth: 888,
    userSelect: "none",
    padding: "50px 24px",
  },
  pageWrapper: {
    flex: 1,
  },
  row: {
    marginBottom: 8,
  },
  addNewPostButton: {
    top: 90,
    right: 84,
    zIndex: 0,
    borderRadius: 150,
    position: "fixed",
    padding: "11px 22px",
  },
  issueContainer: {
    marginBottom: 30,
  },
  editionTitleWrapper: {
    marginBottom: 12,
  },
  editionName: {
    fontSize: 24,
    color: "#2B2B2B",
  },
  addIcon: {
    marginRight: 10,
  },
  outerGrid: {
    flex: 1,
  },
  innerGrid: {
    flex: 1,
    display: "flex",
    alignSelf: "center",
    alignItems: "center",
  },
  dialogTitle: {
    fontSize: 16,
    color: "#F43F5E",
  },
  dialogContent: {
    width: 888,
    padding: 30,
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      padding: "20px 16px",
    },
  },
  dialogPaper: {
    padding: 24,
    boxShadow: '0 1px 5px #00000052',
    borderRadius:8
  },
  contentTitle: {
    fontSize: 18,
    color: "#24272A",
    lineHeight: "23px",
  },
  writer: {
    fontSize: 14,
    color: "#24272A",
  },
}));

export default useStyles;
