import React, { useState, useEffect } from "react";
import useStyles from "./useStyles";
// Material Components
import { Button, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
// Material Icons
import { Edit, Delete as DeleteIcon, Add as AddIcon, ContentCopy } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from '../../../components/hooks';
// Screens
import { AddMember } from './addMember'
import { MemberDetail } from './memberDetail'
import { DeniedMembers } from './deniedMembers'
// Components
import DeleteConfirmation from "components/deleteConfirmation";
import EnhancedTable from '../../../components/dataGrid/dataGrid';
import Dialog from "components/dialog";
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";
import { LoadingButton } from "@mui/lab";

interface AuthorizedMembersProps {
  postName?: any;
  issueNumber?: any;
  tenantName?: any;
  publicationId?: any;
  editionId?: any;
  issueId?: any;
  onClose: () => void;
}

const AuthorizedMembers: React.FC<AuthorizedMembersProps> = (props: AuthorizedMembersProps) => {
  const { t, proxyExecute } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [showAddMemberForm, setShowAddMemberForm] = useState<boolean>(false);
  const [showMemberDetailForm, setShowMemberDetailForm] = useState<boolean>(false);
  const [members, setMembers] = useState<any>();
  const [posts, setPosts] = useState<any>();
  const [selectedMembers, setSelectedMembers] = useState<any>([]);
  const [deleteEditionConfirmation, setDeleteEditionConfirmation] = useState(false);
  const [formData, setFormData] = useState<any>({
    accessType:'1'
  });
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dataHandler = (field: any, value: any,) => {
    setFormData({ ...formData, [field]: value });
  };

  const validate = () => {
    var errorMessages: any = {};
    if (!formData.firstName || formData.firstName === '') {
      errorMessages.firstName = t('This field is required');
    }
    if (!formData.lastName || formData.lastName === '') {
      errorMessages.lastName = t('This field is required');
    }
    if (!formData.email || formData.email === '') {
      errorMessages.email = t('This field is required');
    }
    setErrors(errorMessages);
    return Object.keys(errorMessages).length === 0;
  }

  useEffect(() => {
    if (posts) return;

    proxyExecute(`Posts`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => { setPosts([{ id: '-', name: t('All') }, ...data]); }
    });

  }, [posts, proxyExecute, t]);

  useEffect(() => {
    if (members) return;
    var postId = `p-${props.publicationId}`;
    if (props.issueId) {
      postId = `e-${props.editionId}`;
    }

    proxyExecute(`MemberPosts/ByPostId/${postId}`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => {
        arrangeMembers(data);
      }
    });

  }, [members, proxyExecute]);

  const arrangeMembers = (data: any) => {
    if (!data) {
      setMembers([]);
    }
    else {
      setMembers(data.map((item: any) => {
        return {
          ...item,
          fullname: item.firstname + ' ' + item.lastname
        };
      }));
    }
  }

  useEffect(() => {
    setSelectedMembers(selectedMembers);
  }, [selectedMembers]);

  const headCells = [
    {
      id: 'fullname',
      numeric: false,
      disablePadding: true,
      label: t('Name')
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('Email')
    }
  ];

  return (
    <Grid container direction="column" className={classes.pageWrapper}>
      <Grid item >
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.container}
          classes={{
            maxWidthMd: classes.maxWidthMd,
          }}
        >
          <Grid container xs={12}>
            <Paper className={classes.dialogPaper}>
              <Grid  >
                <Grid item xs={6}>
                  <Typography>{props.postName}</Typography>
                </Grid>
                <Grid item xs={6}>
                  {props.issueNumber && <Typography>Sayı [{props.issueNumber}]</Typography>}

                </Grid>
                <Grid item xs={6}>
                  <Typography>{props.tenantName}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel>{t('Access')}</InputLabel>
                    <Select
                      name="access"
                      onChange={(value) => { dataHandler('accessType', value?.target?.value) }}
                      value={formData.accessType}
                      error={Boolean(errors.link)}
                    >
                      {
                        [{ id: '1', name: 'Herkese açık' }, { id: '2', name: 'Sınırlı' }].map((item: any) => {
                          return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>;
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="link"
                    label={t('Link')}
                    variant="standard"
                    onChange={(value) => { dataHandler('link', value.currentTarget.value) }}
                    value={formData.link}
                    error={Boolean(errors.link)}
                    helperText={errors.link}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment style={{
                          fontSize: 20,
                        }} position="end">
                          <IconButton
                            color="inherit"
                            onClick={() => {
                              dataHandler('passwordInputType', formData.passwordInputType === 'password' ? 'text' : 'password');
                            }}
                          >
                            <ContentCopy />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Grid xs={12}>
              {members && formData.accessType === '2' && <EnhancedTable
                rows={members}
                columns={headCells}
                headerTitle={t('Members with access')}
                headerRightContent={
                  <Grid container direction={'row'} justifyContent="flex-end">
                    <Button key={123} size="small" variant="outlined" onClick={(data: any) => {
                      setShowAddMemberForm(true);
                    }}>
                      {t('Add')}
                    </Button>
                  </Grid>
                }
                headerRightContent2={
                  <Grid container direction={'row'} justifyContent="flex-end">
                    <IconButton key={123} onClick={() => {
                      setDeleteEditionConfirmation(true);
                    }}>
                      <DeleteIcon className={classes.addIcon} />
                    </IconButton>
                  </Grid>
                }
                onSelected={(selected) => {
                  setSelectedMembers(selected);
                }}
              ></EnhancedTable>}
            </Grid>
            <Grid item xs={12}>
              <LoadingButton fullWidth type="submit" variant="contained" loading={isLoading}
                onClick={() => {
                  var id = 0;
                  if (!props.issueId) {
                    id = props.publicationId;
                    proxyExecute(`Publications/Publish/${id}`, HttpMethod.Put, {
                      setIsLoading,
                      callback: () => {
                        props.onClose && props.onClose();
                      }
                    });
                  }
                  else {
                    id = props.issueId;
                    proxyExecute(`Issues/Publish/${id}`, HttpMethod.Put, {
                      setIsLoading,
                      callback: () => {
                        props.onClose && props.onClose();
                      }
                    });
                  }
                }}
              >
                {t('Publish')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Container>
      </Grid >

      <Dialog open={showAddMemberForm}
        onClose={() => { setShowAddMemberForm(false) }}
        closable={true}
        showAlwaysTopDivider={true}
        title={t('Select member')}>
        <DeniedMembers postName={props.postName}
          issueNumber={props.issueNumber}
          publicationId={props.publicationId}
          issueId={props.issueId}
          tenantName={props.tenantName}
          editionId={props.editionId}
          onClose={()=>{
            setMembers(undefined);
            setShowAddMemberForm(false);
          }}
        ></DeniedMembers>
      </Dialog>

      {
        showMemberDetailForm &&
        <MemberDetail selectedMember={members.find((x: any) => x.id === selectedMembers[0])} onClose={() => {
          setShowMemberDetailForm(false);
          setMembers(undefined);
        }}>
        </MemberDetail>
      }

      {
        deleteEditionConfirmation &&
        <DeleteConfirmation onDeleteClicked={() => {
          setDeleteEditionConfirmation(false);
          selectedMembers.map((id: any) => {
            proxyExecute(`Members/${id}`, HttpMethod.Delete, {
              setIsLoading: undefined,
              callback: () => { setMembers(undefined) }
            })
          })
        }}
          onCancelClicked={() => {
            setDeleteEditionConfirmation(false);
          }}>
        </DeleteConfirmation>
      }
    </Grid >
  );
};

export default AuthorizedMembers;