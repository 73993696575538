import React, { useState, useEffect } from "react";
import useStyles from "./useStyles";
// Material Components
import { Grid, Typography, Paper, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// Material Icons

// Custom Hooks
import { useCustomHooks } from 'components/hooks';
// Screens

// Components
import Dialog from "components/dialog";
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";

interface Props {
  onClose?: () => void;
  chapterId?: any;
  postType?: any;
  id?: any;
}

const ChapterDefinition: React.FC<Props> = (props: Props) => {
  const { t, proxyExecute } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [chapter, setChapter] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!props.chapterId || chapter?.id) return;

    var id = props.chapterId;
    proxyExecute(`ContentChapters/${id}`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => { setChapter(data) }
    });
  }, []);

  const validate = () => {
    var errorMessages: any = {};
    if (!chapter || !chapter.title) {
      errorMessages.title = 'This field is required';
    }
    setErrors(errorMessages);
    return Object.keys(errorMessages).length === 0;
  }

  return (
    <Dialog
      closable={true}
      open={true}
      showAlwaysTopDivider={true}
      onClose={() => { props.onClose && props.onClose(); }}
      title={<Typography className={classes.chapterDialogTitle}>{t('Add Chapter Title')}</Typography>}
    >
      <Grid container className={classes.dialogContent}>
        <Grid item xs={12}>
          <Paper className={classes.dialogPaper}>
            <TextField
              fullWidth
              name="title"
              variant="standard"
              label={t('Chapter Title')}
              value={chapter?.title}
              onChange={(value) => { setChapter({ ...chapter, title: value.currentTarget.value }); }}
              inputProps={{ style: { textTransform: "uppercase" } }}
              error={Boolean(errors.title)}
              helperText={errors.title}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton loading={isLoading} fullWidth variant="contained" onClick={() => {
            if (!validate()) return;

            if (!chapter?.id) {
              proxyExecute(`ContentChapters`, HttpMethod.Post, {
                body: { ...chapter, postType: props.postType, postId: props.id },
                setIsLoading,
                callback: () => { props.onClose && props.onClose() }
              });
            }
            else {
              var id = props.chapterId;
              proxyExecute(`ContentChapters/${id}`, HttpMethod.Put, {
                body: { title: chapter.title },
                setIsLoading,
                callback: () => { props.onClose && props.onClose() }
              });
            }
          }} style={{
            marginTop: 20,
            marginBottom: 10,
          }}>
            {t('Next')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ChapterDefinition;
