import React from "react";
import useStyles from "./useStyles";
// Material Components
import { Button, Divider, Grid, Typography } from "@mui/material";
// Custom Hooks
import { useCustomHooks } from 'components/hooks';
// Constants&Enums
import Constants from "utils/constants";

interface Props {
  isReadOnly?: boolean;
  onClick: (publication: any) => void;
  content: any;
  onDeleteClick: (id: any) => void;
  onEditClick: (id: any) => void;
}

const TextBoxContent: React.FC<Props> = (props: Props) => {
  const { t, proxyExecute } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const { content } = props;

  if (!content)
    return <></>;

  return (
    <Grid container className={classes.issue}>
      <Grid item xs={12}>
        <Grid container alignContent="flex-end" className={classes.imageContainer}>
          <Grid item xs={12} style={{
            width: "100%",
            display: "block",
            borderRadius: 12,
            boxShadow: "0 2px 6px #12121280",
            paddingBottom: 10
          }}>
            {content?.imagePath ? (
              <img alt={content.name} className={classes.image} src={`${Constants.API_URL}${content?.imagePath}`} />
            ) : (
              <div className={classes.imageNotfound} />
            )}
            <Grid style={{ marginLeft: 24, marginRight: 24, marginTop: 10, marginBottom: 10 }}>
              <Typography style={{ fontSize: '12px' }}>{content.underImageText}</Typography>
              <Typography style={{
                fontSize: 20,
                color: "#313131",
                fontWeight: '700',
                marginTop: '20px',
                lineHeight: 1.3
              }}>{content.header}</Typography>
              <Typography style={{ marginTop: 10 }}>
                {content.content}
              </Typography>
            </Grid>
            {content.linkDisplayName &&
              <>
                <Divider style={{ marginTop: 25 }}></Divider>
                <Grid container>
                  <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end", marginTop:6 }}>
                    <Button variant="text"
                      style={{ marginRight: 20,  color: '#FA9C2C' }} onClick={() => {
                        window.open(
                          content.link, "_blank");
                      }}>
                      {content.linkDisplayName}
                    </Button>
                  </Grid>
                </Grid>
              </>}
          </Grid>

          {!props.isReadOnly && <Grid container>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "center", marginTop: 8 }}>
              <Button color="primary" onClick={() => {
                props.onDeleteClick && props.onDeleteClick(content.id);
              }}>
                {t('Delete')}
              </Button>
              <Button color="primary" onClick={() => {
                props.onEditClick && props.onEditClick(content.id);
              }}>
                {t('Edit')}
              </Button>
            </Grid>
          </Grid>}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TextBoxContent;
