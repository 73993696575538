import React, { useState, useEffect } from "react";
import useStyles from "./useStyles";
// Material components
import { Button, Grid, Paper, Typography, IconButton, Avatar } from "@mui/material";
// Material icons
import { Delete as DeleteIcon, Add as AddIcon, MoreVert as MoreVertIcon, Edit } from '@mui/icons-material';
// Custom Hooks
import { useCustomHooks } from '../../../components/hooks';
// Components
import EnhancedTable from '../../../components/dataGrid/dataGrid'
import DeleteConfirmation from "components/deleteConfirmation";
import Dialog from "components/dialog";
// Screens
import { AddPostRelation } from './addPostRelation'
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";

interface MemberDetailProps {
    onClose: () => void;
    selectedMember: any;
}

export const MemberDetail: React.FC<MemberDetailProps> = (props: MemberDetailProps) => {
    const { t, proxyExecute } = useCustomHooks();
    const classes = useStyles();

    // Variables
    const [selectedMember, setSelectedMember] = useState<any>({ ...props.selectedMember, fullname: props.selectedMember.firstname + ' ' + props.selectedMember.lastname });
    const [showAddPostRelationForm, setShowAddPostRelationForm] = useState<boolean>(false);
    const [memberPosts, setMemberPosts] = useState<any>();
    const [selectedMemberPostIds, setSelectedMemberPostIds] = useState<any>();
    const [deleteEditionConfirmation, setDeleteEditionConfirmation] = useState(false);
    const [postIds, setPostIds] = useState([]);
    const [issueIds, setIssueIds] = useState([]);

    useEffect(() => {
        if (memberPosts) return;
        var id = selectedMember.id;
        proxyExecute(`MemberPosts/ByMemberId/${id}`, HttpMethod.Get, {
            setIsLoading: undefined,
            callback: (data: any) => {
                setMemberPosts([...data]);
            }
        })
    }, [memberPosts, proxyExecute, selectedMember.id]);

    return (
        <Dialog
            open={true}
            closable={true}
            showAlwaysTopDivider={true}
            onClose={() => {
                props.onClose && props.onClose();
            }}
            title={t('Member Detail')}
        >
            <Grid container className={classes.dialogContent}>
                <Grid item xs={12}>
                    <Paper style={{ padding: 24, marginBottom: 15  ,   boxShadow: '0 1px 5px #00000052',
    borderRadius:8 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={2}>
                                        <IconButton sx={{ p: 0 }}>
                                            <Avatar alt={selectedMember.fullname} sx={{ bgcolor: "primary.main" }}>
                                                {selectedMember.fullname.slice(0, 1)}
                                            </Avatar>
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid item xs={8}>
                                            <Typography className={classes.contentTitle}>{selectedMember.fullname}</Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: '2px' }}>
                                            <Typography className={classes.writer}>
                                                {selectedMember.email}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>

                    {memberPosts && <Grid container spacing={1} >
                        <Grid item xs={12}>
                            <EnhancedTable
                                rows={memberPosts}
                                columns={[
                                    {
                                        id: 'postName',
                                        numeric: false,
                                        disablePadding: true,
                                        label: t('Post Name'),
                                    },
                                    {
                                        id: 'memberTypeName',
                                        numeric: false,
                                        disablePadding: false,
                                        label: t('Role'),
                                    }
                                ]}
                                headerTitle={t('Related Posts')}
                                headerRightContent={
                                    <Grid container direction={'row'} justifyContent="flex-end">
                                        <Button key={123} size="small" variant="contained" onClick={(data: any) => {
                                            var postIds: any = [];
                                            var issueIds: any = [];
                                            memberPosts.map((x: any) => {
                                                if (postIds.indexOf(x.publicationId) < 0) {
                                                    postIds.push(x.publicationId);
                                                }
                                                if (x.issueId !== '00000000-0000-0000-0000-000000000000')
                                                    issueIds.push(x.issueId);
                                            });
                                            setPostIds(postIds);
                                            setIssueIds(issueIds);
                                            setShowAddPostRelationForm(true);
                                        }}>
                                            <AddIcon className={classes.addIcon} />
                                            {t('Add Related Post')}
                                        </Button>
                                    </Grid>
                                }
                                headerRightContent2={
                                    <Grid container direction={'row'} justifyContent="flex-end">
                                        <IconButton key={123} onClick={() => {
                                            setDeleteEditionConfirmation(true);
                                        }}>
                                            <DeleteIcon className={classes.addIcon} />
                                        </IconButton>
                                        {<IconButton key={1234} onClick={() => {
                                            setPostIds([]);
                                            setIssueIds([]);
                                            setShowAddPostRelationForm(true);
                                        }}>
                                            <Edit className={classes.addIcon} />
                                        </IconButton>}
                                    </Grid>
                                }
                                onSelected={(selected) => {
                                    setSelectedMemberPostIds(selected);
                                }}
                            ></EnhancedTable>
                        </Grid>
                    </Grid>}
                </Grid>
            </Grid>

            {showAddPostRelationForm &&
                <AddPostRelation
                    issueIds={issueIds}
                    postIds={postIds} selectedMemberId={selectedMember.id}
                    getSelectedMemberPost={() => {
                        if (postIds && postIds.length > 0) return undefined;

                        return memberPosts.find((x: any) => x.id === selectedMemberPostIds[0]);
                    }}
                    onClose={() => {
                        setShowAddPostRelationForm(false);
                        setMemberPosts(undefined);
                    }}>
                </AddPostRelation>}

            {deleteEditionConfirmation &&
                <DeleteConfirmation onDeleteClicked={() => {
                    setDeleteEditionConfirmation(false);
                    selectedMemberPostIds.map((id: any) => {
                        proxyExecute(`MemberPosts/${id}`, HttpMethod.Delete, {
                            setIsLoading: undefined,
                            callback: () => { setMemberPosts(undefined) }
                        })
                    });
                }}
                    onCancelClicked={() => {
                        setDeleteEditionConfirmation(false);
                    }}>
                </DeleteConfirmation>}
        </Dialog>
    );
};
