import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  createAccountContainer: {
    padding: 40,
  },
  paper: {
    width: 408,
    padding: "24px 35px",
    boxShadow: '0 1px 5px #00000052',
    borderRadius:8
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    marginBottom: 24,
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  passwordInfo: {
    fontSize: 12,
    marginTop: 13,
    color: theme.palette.baseLight500,
  },
  contractInfo: {
    fontSize: 14,
    margin: "10px 0",
    userSelect: "none",
    color: theme.palette.baseLight600,
  },
  createAccountButton: {
    marginBottom: 60,
  },
  signInInfo: {
    fontSize: 14,
    marginBottom: 16,
    textAlign: "center",
    color: theme.palette.baseLight600,
  },
  signInButton: {
    marginLeft: 10,
  },
  passwordInfoButton: {
    padding: 0,
  },
  adornment: {
    color: theme.palette.baseLight600,
    fontSize: 20,
  },
}));

export default useStyles;
