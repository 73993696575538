export interface Year {
  key: number;
  value: number;
}

export const getUserInfo = (): any => {
  if (typeof window !== "undefined") {
    var userInfoString = window.localStorage.getItem("userInfo");
    if (userInfoString)
      return JSON.parse(userInfoString);
  }
  return {};
}

export const getLanguage = (): any => {
  if (typeof window !== "undefined") {
    var languageString = window.localStorage.getItem("language");
    if (languageString)
      return JSON.parse(languageString);
  }
  return {};
}

export const groupBy = <T extends unknown>(list: T[], keyGetter: (item: T) => string): Map<string, T[]> => {
  const map = new Map();
  list.forEach((item: T) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

export const months = [
  { key: 12, value: "12" },
  { key: 1, value: "01" },
  { key: 2, value: "02" },
  { key: 3, value: "03" },
  { key: 4, value: "04" },
  { key: 5, value: "05" },
  { key: 6, value: "06" },
  { key: 7, value: "07" },
  { key: 8, value: "08" },
  { key: 9, value: "09" },
  { key: 10, value: "10" },
  { key: 11, value: "11" },
];

export const generatingYears = () => {
  const date = new Date();
  let year = date.getFullYear() + 2;
  const newYears: Year[] = [];
  for (let index = 0; index < 55; index++) {
    const newYear = year - index;
    newYears.push({ key: newYear, value: newYear });
  }
  return newYears;
};

export enum MemberTypeId {
  Member = 1,
  Writer = 2,
  Editor = 3,
  PublishingDirector = 4
}

export const generateUUID = () => { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16;//random number between 0 and 16
    if (d > 0) {//Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {//Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}