import React, { useState, useEffect } from "react";
import useStyles from "./useStyles";
// Material Components
import { Button, Container, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
// Material Icons
import { Edit, Delete as DeleteIcon, Add as AddIcon, ContentCopy } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from '../../../components/hooks';
// Screens
import { AddMember } from './addMember'
import { MemberDetail } from './memberDetail'
// Components
import DeleteConfirmation from "components/deleteConfirmation";
import EnhancedTable from '../../../components/dataGrid/dataGrid'
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";
import { LoadingButton } from "@mui/lab";

interface DeniedMembersProps {
  postName?: any;
  issueNumber?: any;
  tenantName?: any;
  publicationId?: any;
  editionId?: any;
  issueId?: any;
  onClose: () => void;
}

export const DeniedMembers: React.FC<DeniedMembersProps> = (props: DeniedMembersProps) => {
  const { t, proxyExecute } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [showAddMemberForm, setShowAddMemberForm] = useState<boolean>(false);
  const [showMemberDetailForm, setShowMemberDetailForm] = useState<boolean>(false);
  const [members, setMembers] = useState<any>();
  const [posts, setPosts] = useState<any>();
  const [selectedMembers, setSelectedMembers] = useState<any>([]);
  const [deleteEditionConfirmation, setDeleteEditionConfirmation] = useState(false);
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dataHandler = (field: any, value: any,) => {
    setFormData({ ...formData, [field]: value });
  };


  useEffect(() => {
    if (posts) return;

    proxyExecute(`Posts`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => { setPosts([{ id: '-', name: t('All') }, ...data]); }
    });

  }, [posts, proxyExecute, t]);

  useEffect(() => {
    if (members) return;
    var postId = `p-${props.publicationId}`;
    if (props.issueId) {
      postId = `e-${props.editionId}`;
    }

    proxyExecute(`MemberPosts/ByPostId/${postId}?unauthorizedMembers=true`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => {
        arrangeMembers(data);
      }
    });

  }, [members, proxyExecute]);

  const arrangeMembers = (data: any) => {
    if (!data) {
      setMembers([]);
    }
    else {
      setMembers(data.map((item: any) => {
        return {
          ...item,
          fullname: item.firstname + ' ' + item.lastname
        };
      }));
    }
  }

  useEffect(() => {
    setSelectedMembers(selectedMembers);
  }, [selectedMembers]);

  const headCells = [
    {
      id: 'fullname',
      numeric: false,
      disablePadding: true,
      label: t('Name')
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('Email')
    }
  ];

  return (
    <Grid container direction="column" className={classes.pageWrapper}>
      <Grid item >
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.container}
          classes={{
            maxWidthMd: classes.maxWidthMd,
          }}
        >
          <Grid container xs={12}>
            <Grid xs={12}>
              {members && <EnhancedTable
                rows={members}
                columns={headCells}
                headerTitle={t('Members with access')}
                headerRightContent={
                  <Grid container direction={'row'} justifyContent="flex-end">
                    <Button key={123} size="small" variant="outlined" onClick={(data: any) => {
                      setShowAddMemberForm(true);
                    }}>
                      {t('Add new member')}
                    </Button>
                  </Grid>
                }
                headerRightContent2={
                  <Grid container direction={'row'} justifyContent="flex-end">

                  </Grid>
                }
                onSelected={(selected) => {
                  setSelectedMembers(selected);
                }}
              ></EnhancedTable>}
            </Grid>
            <Grid item xs={12}>
              <LoadingButton fullWidth type="submit" variant="contained" loading={isLoading}
                onClick={() => {
                  selectedMembers.map((id:any) => {
                    var requestBody = {
                      issueId: props.issueId,
                      editionId: props.editionId,
                      publicationId: props.publicationId,
                      memberTypeId: '1',
                      memberId: id
                    };
  
                    proxyExecute(`MemberPosts`, HttpMethod.Post, {
                      body: requestBody,
                      setIsLoading,
                      callback: (data: any) => {
                        props.onClose && props.onClose();
                      }
                    })
                  });
                }}
                style={{
                  marginTop: 20,
                  marginBottom: 10,
                }}
              >
                {t('Ok')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Container>
      </Grid >

      {showAddMemberForm &&
        <AddMember onClose={() => {
          setShowAddMemberForm(false);
          setMembers(undefined);
        }}>
        </AddMember>}

      {
        showMemberDetailForm &&
        <MemberDetail selectedMember={members.find((x: any) => x.id === selectedMembers[0])} onClose={() => {
          setShowMemberDetailForm(false);
          setMembers(undefined);
        }}>
        </MemberDetail>
      }

      {
        deleteEditionConfirmation &&
        <DeleteConfirmation onDeleteClicked={() => {
          setDeleteEditionConfirmation(false);
          selectedMembers.map((id: any) => {
            proxyExecute(`Members/${id}`, HttpMethod.Delete, {
              setIsLoading: undefined,
              callback: () => { setMembers(undefined) }
            })
          })
        }}
          onCancelClicked={() => {
            setDeleteEditionConfirmation(false);
          }}>
        </DeleteConfirmation>
      }
    </Grid >
  );
};