import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  issueContainer: {
    marginBottom: 30,
  },
  editionTitleWrapper: {
    marginBottom: 12,
  },
  editionName: {
    fontSize: 24,
    color: "#2B2B2B",
    fontWeight:'700'
  },
  addIcon: {
    marginRight: 10,
  },

  issue: {
    cursor: "pointer",
  },
  imageContainer: {

    marginBottom: 12,
  },
  image: {
    width: "100%",
    display: "block",
    borderRadius: 12,
    boxShadow: "0 2px 6px #12121280",
  },
  date: {
    fontSize: 15,
    color: "#4F525B",
  },
  imageNotfound: {
    width: "100%",
    borderRadius: 12,
    display: "block",
    background: "grey",
  },
}));

export default useStyles;
