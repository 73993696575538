import React, { useRef, useState } from "react";
import useStyles from "./useStyles";
// Material Components
import { Button, ButtonGroup, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
// Material Icons
import { ArrowDropDown as ArrowDropDownIcon, Add as AddIcon } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from 'components/hooks';
// Screens
import ContentDefinition from "../contentDefinition";
import ChapterDefinition from "../chapterDefinition";

interface Props {
  showOnlyAddChapterButton?: boolean;
  reloadChapters: () => void;
  postType: any;
  id: any;
  chapterId: any;
}

const AddContentChapterButton: React.FC<Props> = (props: Props) => {
  const { t } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [openContentDefinitionAsPopper, setOpenContentDefinitionAsPopper] = useState<boolean>(false);
  const [openChapterDefinitionasDialog, setOpenChapterDefinitionasDialog] = useState<boolean>(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid style={{ marginLeft: 8 }}>
      <>
        {props.showOnlyAddChapterButton && (
          <Button variant="outlined" onClick={() => { setOpenChapterDefinitionasDialog(true); }} style={{ zIndex: 1 }}>
            {t('Add Chapter')}
          </Button>
        )}
        {!openContentDefinitionAsPopper && !props.showOnlyAddChapterButton && (
          <>
            <ButtonGroup ref={anchorRef} variant="contained" style={{borderRadius:8}}>
              <Button onClick={() => setOpenContentDefinitionAsPopper(true)} >
                <AddIcon className={classes.addIcon} />{t('Add Content')}
              </Button>
              <Button size="small" aria-haspopup="menu" onClick={handleToggle}>
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
            <Popper transition open={open} disablePortal role={undefined} anchorEl={anchorRef.current}>
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        <MenuItem onClick={() => { setOpenChapterDefinitionasDialog(true); }}>{t('Add Chapter')}</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        )}
        {openContentDefinitionAsPopper && <ContentDefinition chapterId={props.chapterId} onClose={() => { setOpenContentDefinitionAsPopper(false); props.reloadChapters(); }} />}
        {openChapterDefinitionasDialog && <ChapterDefinition postType={props.postType} id={props.id} onClose={() => { setOpenChapterDefinitionasDialog(false); props.reloadChapters(); }} ></ChapterDefinition>}
      </>
    </Grid>
  );
};

export default AddContentChapterButton;
