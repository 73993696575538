import React, { useState } from "react";
import useStyles from "./useStyles";

import Loading from "components/loading";
import { useTranslation } from "react-i18next";

import { Container, Grid, Typography } from "@mui/material";

import PublicationContainer from '../../../components/publicationContainer'
import EditionContainer from "components/editionContainer";
import { PostListType } from "utils/constants/enums";

interface Props {
  postListType?: PostListType;
  isApproved?: boolean;
  tenantId?: string;
  isPublished?: boolean;
  refreshData: boolean;
}

const Posted: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation("home");
  const classes = useStyles();

  // Variables
  const { isApproved, isPublished, tenantId } = props;
  const [publicationCount, setPublicationCount] = useState<number>();
  const [editionCount, setEditionCount] = useState<number>();

  return (
    <Grid container direction="column" className={classes.pageWrapper}>
      <Grid item >
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.container}
          classes={{
            maxWidthMd: classes.maxWidthMd,
          }}
        >
          {editionCount === undefined && publicationCount === undefined && <Loading></Loading>}
          {editionCount === 0 && publicationCount === 0 && <Grid container direction="column" alignItems="center" alignContent="center" className={classes.outerGrid}>
            <Grid item className={classes.innerGrid}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Typography style={{
                    fontSize: 15,
                    color: "#666666",
                  }}>{t('There is no post')}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>}
          <EditionContainer loadingCompleted={setEditionCount} isApproved={isApproved} isPublished={isPublished} refreshData={props.refreshData} tenantId={props.tenantId}></EditionContainer>
          <PublicationContainer loadingCompleted={setPublicationCount} isApproved={isApproved} isPublished={isPublished} refreshData={props.refreshData} tenantId={props.tenantId}></PublicationContainer>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Posted;