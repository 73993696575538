import React, { useState } from "react";
import useStyles from "./useStyles";
// Material Components
import { Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Link, Paper, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// Material Icons
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from '../../components/hooks';
// Screens

// Components
import { Icons } from "components/icons";
// Constans&Enums
import { HttpMethod } from "utils/constants/enums";


import { useLocalStorage } from "hooks";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "store";
import { loggedIn, setToken } from "store/authentication/reducer";


const Login: React.FC = () => {
  const { t, proxyExecuteForAuth, setCache } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [isLoading, setIsLoading] = useState<any>(false);
  const [errors, setErrors] = useState<any>({});
  const [formData, setFormData] = useState<any>({
    passwordInputType: 'password',
    password: window.location.href.indexOf('localhost') > -1 ? 'Asdf1234+' : '',
    key: '1862e6f9-6f5b-490a-a07f-e358e9225f96'
  });

  const history = useHistory();
  const dispatch = useAppDispatch();
  const [, setLocalStorageToken] = useLocalStorage("token", "");
  const [, setUserInfo] = useLocalStorage("userInfo", "");

  const dataHandler = (field: any, value: any,) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleGoForgotPassword = () => {
    history.replace({ pathname: "/sifremi-unuttum" });
  };

  const validate = () => {
    var errorMessages: any = {};
    if (!formData.email || formData.email === '') {
      errorMessages.email = t('This field is required');
    }
    if (!formData.password || formData.password === '') {
      errorMessages.password = t('This field is required');
    }
    setErrors(errorMessages);
    return Object.keys(errorMessages).length === 0;
  }

  return (
    <Grid container direction="column" alignItems="center" alignContent="center" className={classes.loginContainer}>
      <Grid item>
        <Paper className={classes.paper}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography className={classes.logo}>
                <Icons.Logo />
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.title}>{t("Log In")}</Typography>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                name="email"
                variant="standard"
                label={t('Email')}
                value={formData.email}
                onChange={(value) => { dataHandler('email', value.currentTarget.value) }}
                error={Boolean(errors.email)}
                helperText={errors.email}
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                name="password"
                variant="standard"
                label={t('Password')}
                type={formData.passwordInputType}
                value={formData.password}
                onChange={(value) => { dataHandler('password', value.currentTarget.value) }}
                error={Boolean(errors.password)}
                helperText={errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment className={classes.adornment} position="end">
                      <IconButton
                        color="inherit"
                        className={classes.passwordInfoButton}
                        onClick={() => {
                          dataHandler('passwordInputType', formData.passwordInputType === 'password' ? 'text' : 'password');
                        }}
                      >
                        {formData.passwordInputType === 'password' ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                labelPlacement="end"
                control={<Checkbox value={formData.isRememberMe} onChange={(event, value) => dataHandler('password', value)} />}
                label={<Typography className={classes.rememberMeInfo}>{t('Remember Me')}</Typography>}
              />
            </Grid>
            <Grid item>
              <LoadingButton fullWidth size="large" loading={isLoading} variant="contained" className={classes.loginButton}
                onClick={() => {
                  if (!validate()) return;

                  proxyExecuteForAuth(`Auth/Login`, HttpMethod.Post, {
                    body: formData,
                    setIsLoading,
                    callback: (data: any) => {
                      if (data) {
                        setUserInfo({
                          userId: data.userId,
                          email: formData.email
                        });
                        if (data.hasRoles === true) {
                          dispatch(setToken(data.token));
                          history.replace({ pathname: "/select-tenant" });
                        }
                        else {
                          setLocalStorageToken(data.token);
                          dispatch(loggedIn());
                          history.replace({ pathname: "/" });
                        }
                      }
                    }
                  })
                }}>
                {t('Next')}
              </LoadingButton>
            </Grid>
            <Grid item className={classes.forgotPasswordWrapper}>
              <Link onClick={handleGoForgotPassword} className={classes.forgotPassword}>
                {t('Forgot Password')}
              </Link>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Login;
