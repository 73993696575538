import React, { useState } from 'react';

const ImageComponent = ({ src, alt, style, className, handleClose }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
    handleClose && handleClose(isFullScreen);
  };

  const imageContainerStyle = {
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    ...(isFullScreen && {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      background: 'rgba(0, 0, 0, 0.9)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 99999
    }),
  };

  const imageStyle = {
    ...style,
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain', // Adjusts the image to fit inside the container without stretching
    ...(isFullScreen && {
      width: 'auto', // Reset width for fullscreen
      height: 'auto', // Reset height for fullscreen
      maxHeight: '100vh', // Limit height in fullscreen mode
      maxWidth: '100vw', // Limit width in fullscreen mode
      borderRadius: 0
    })
  };

  return (
    <div style={imageContainerStyle} onClick={toggleFullScreen}>
      <img src={src} alt={alt} className={className} style={imageStyle}  />
    </div>
  );
};

export default ImageComponent;
