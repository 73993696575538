export enum ChapterType {
    Header = "header-chapter",
    Footer = "footer-chapter",
    Body = "body-chapter"
}

export enum HttpMethod {
    Post = 'post',
    Put = 'put',
    Delete = 'delete',
    Get = 'get'
}

export enum AuthorizationTabs {
    Members = '0',
    MemberGroups = '1',
    ManagersAndOwners = '2'
}

export enum PostTabs {
    Draft = '0',
    Published = '1',
    Authorized = '2'
}

export enum PostListType {
    Authorized = 'Authorized',
    Published = 'Published',
    Draft = 'Draft'
}