import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} style={{position:'unset', paddingLeft:-2}} />
))(({ theme }) => ({
  background: "none",
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  }
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary style={{position:'unset'}}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: "#F43F5E" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "none",
  borderBottom: `0px solid ${theme.palette.divider}`,
  color: theme.palette.primary.main,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(-2),
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  marginBottom: 24
}));