import {
  CheckActivationResponse,
  CheckBody,
  CheckResetPasswordResponse,
  ForgotPasswordBody,
  ForgotPasswordResponse,
  LoginBody,
  LoginResponse,
  LogoutResponse,
  RegisterBody,
  RegisterResponse,
  ResetPasswordBody,
  ResetPasswordResponse,
  UserInfoResponse,
} from "store/authentication/types";
import fetchBaseQuery from "store/baseQuery";

import { createApi } from "@reduxjs/toolkit/query/react";

export const authenticationApi = createApi({
  tagTypes: [],
  baseQuery: fetchBaseQuery,
  reducerPath: "authenticationApi",
  endpoints: (builder) => ({
    getUserInfo: builder.query<UserInfoResponse, void>({
      query: () => "/Account/Profile",
      transformResponse: (response: { data: UserInfoResponse }) => response.data,
    }),
    postLogin: builder.mutation<LoginResponse, LoginBody>({
      query(body) {
        return {
          body,
          method: "POST",
          url: "/Auth/Login",
        };
      },
    }),
    getLogout: builder.query<LogoutResponse, void>({
      query: () => "/Account/Logout",
    }),
    postRegister: builder.mutation<RegisterResponse, RegisterBody>({
      query(body) {
        return {
          body,
          method: "POST",
          url: "/Auth/Register",
        };
      },
    }),
    postForgotPassword: builder.mutation<ForgotPasswordResponse, ForgotPasswordBody>({
      query(body) {
        return {
          body,
          method: "POST",
          url: "/Auth/ForgotPassword",
        };
      },
    }),
    postResetPassword: builder.mutation<ResetPasswordResponse, ResetPasswordBody>({
      query(body) {
        return {
          body,
          method: "POST",
          url: "/Auth/PasswordReset",
        };
      },
    }),
    getCheckActivation: builder.query<CheckActivationResponse, CheckBody>({
      query: (queryStringCode) => `/Auth/Activation?code=${queryStringCode}`,
    }),
    checkResetPassword: builder.query<CheckResetPasswordResponse, CheckBody>({
      query: (queryStringCode) => `/Auth/PasswordResetCheck?code=${queryStringCode}`,
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useLazyGetLogoutQuery,
  usePostLoginMutation,
  usePostRegisterMutation,
  usePostResetPasswordMutation,
  usePostForgotPasswordMutation,
  useLazyCheckResetPasswordQuery,
  useLazyGetCheckActivationQuery,
} = authenticationApi;
