import fetchBaseQuery from "store/baseQuery";
import { CreateEditionBody, CreateEditionResponse, EditionDetailResponse, EditionModelResponse, EditionsResponse } from "store/edition/types";

import { createApi } from "@reduxjs/toolkit/query/react";

export const editionApi = createApi({
  reducerPath: "editionsApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Editions"],
  endpoints: (builder) => ({
    getEditions: builder.query<EditionsResponse[], void>({
      query: () => "/Editions/List",
      transformResponse: (response: { data: EditionsResponse[] }) => response.data,
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: "Editions" as const, id })), { type: "Editions", id: "LIST" }]
          : [{ type: "Editions", id: "LIST" }],
    }),
    getEditionDetail: builder.query<EditionDetailResponse, string>({
      query: (editionId) => `/Editions/Detail?id=${editionId}`,
      transformResponse: (response: { data: EditionDetailResponse }) => response.data,
      providesTags: (_result, _error, issueId) => [{ type: "Editions", id: issueId }],
    }),
    getEditionModel: builder.query<EditionModelResponse, void>({
      query: () => "/Editions/Create",
      providesTags: () => [{ type: "Editions", id: "MODEL" }],
      transformResponse: (response: { data: EditionModelResponse }) => response.data,
    }),
    postEditionCreate: builder.mutation<CreateEditionResponse, any>({
      invalidatesTags: [{ type: "Editions", id: "LIST" }],
      query(body) {
        return { body, method: "POST", url: "/Editions/Create" };
      },
    }),
  }),
});

export const { useGetEditionsQuery, useGetEditionModelQuery, useGetEditionDetailQuery, usePostEditionCreateMutation } = editionApi;
