import React, { useEffect, useState } from "react";
import useStyles from "./useStyles";
// Material Components
import { Button, Chip, FormControl, FormControlLabel, Grid, IconButton, Input, InputLabel, MenuItem, Select, Switch, TextField, Checkbox } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// Material Icons
import { InfoOutlined as InfoOutlinedIcon, AddCircleOutline as AddCircleOutlineIcon } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from 'components/hooks';
// Screens
import { AddMember } from "pages/authorization/members/addMember";
// Components

// Constants&Enums

import { getContentTypes } from "utils/apiCalls/definitions";
import { HttpMethod } from "utils/constants/enums";

interface Props {
  chapterId?: string;
  contentId?: string;
  onClose: (result: any) => void;
}

const ContentDefinition: React.FC<Props> = (props: Props) => {
  const { t, proxyExecute } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [openAddWriterAsDialog, setOpenAddWriterAsDialog] = useState<boolean>(false);
  const [contentTypes, setContentTypes] = useState<any>();
  const [writers, setWriters] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isWritersDropDownOpen, setIsWritersDropDownOpen] = useState(false);

  const [formData, setFormData] = useState<any>({
    showWriter: false,
    title: '',
    selectedWriterIds: [],
    contentTypeId: '3e72b2fa-aee2-40e8-a0e8-b04301435512',
    contentChapterId: props.chapterId,
  });

  useEffect(() => {
    if (!props.contentId || formData.id) return;

    var id = props.contentId;
    proxyExecute(`Contents/${id}`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => {
        setFormData({
          ...formData,
          title: data.title,
          contentTypeId: data.contentTypeId,
          showWriter: data.showWriter,
          id: data.id,
          contentChapterId: data.contentChapter.id,
          selectedWriterIds: data.writers.map((x: any) => { return x.id })
        });
      }
    });
  }, []);

  useEffect(() => {
    if (writers) return;

    proxyExecute(`Members`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => {
        if (!data)
          setWriters([]);
        else
          setWriters(data);
      }
    });

  }, [writers]);

  useEffect(() => {
    if (contentTypes) return;
    // getContentTypes((data: any) => {
    //   setContentTypes(data);
    // });
    proxyExecute(`ContentTypes`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => {
        if (!data)
          setContentTypes([]);
        else
          setContentTypes(data);
      }
    });

  }, []);

  const dataHandler = (field: any, value: any,) => {
    setFormData({ ...formData, [field]: value });
  };

  const selectContentTypes = () => {
    if (!contentTypes || formData.id) return;

    return <Grid item xs={12}>
      <FormControl fullWidth variant="standard">
        <InputLabel id="contentType">{t('Content Type')}</InputLabel>
        <Select
          name="contentType"
          labelId="contentType"
          onChange={(value) => { dataHandler('contentTypeId', value.target.value) }}
          value={formData.contentTypeId}
        >
          {contentTypes.map((contentType: any) => {
            return (
              <MenuItem key={contentType.contentType.id} value={contentType.contentType.id}>
                {contentType.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>;
  }

  const selectWriter = () => {
    if (!writers) return;

    return <Grid item xs={12}>
      <Grid container>
        <Grid item style={{ flexGrow: 1 }}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="writer">{t('Writer (Optional)')}</InputLabel>
            <Select
              multiple
              labelId="writer"
              input={<Input />}
              value={formData.selectedWriterIds}
              open={isWritersDropDownOpen}
              onOpen={() => { setIsWritersDropDownOpen(true) }}
              onClose={() => { setIsWritersDropDownOpen(false) }}
              onChange={(value) => {
                dataHandler('selectedWriterIds', value?.target?.value);
              }}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as string[]).map((value) => {
                    const selectedWriter = writers.find((item: any) => item.id === value);
                    return <Chip key={value} className={classes.chip} label={selectedWriter!.firstname + ' ' + selectedWriter!.lastname} />;
                  })}
                </div>
              )}
            >
              {writers.map((writer: any) => {
                return (
                  <MenuItem key={writer.id} value={writer.id}>
                    <div>
                      <Checkbox checked={formData.selectedWriterIds?.find((id: any) => id === writer.id) !== undefined} />
                      {writer.firstname} {writer.lastname}
                    </div>
                  </MenuItem>
                );
              })}
              <div style={{ position: 'sticky', bottom: 0, background: 'white', display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                <Button onClick={() => { setIsWritersDropDownOpen(false) }}>{t('Ok')}</Button>
              </div>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <IconButton>
            <InfoOutlinedIcon className={classes.infoIcon} />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton onClick={() => setOpenAddWriterAsDialog(true)}>
            <AddCircleOutlineIcon className={classes.plusIcon} />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  }

  const addWriter = () => {
    if (!openAddWriterAsDialog) return;
    return <AddMember onClose={(data: any) => {
      setOpenAddWriterAsDialog(false);
      if (data && data.id) {
        setWriters(undefined);
        dataHandler('selectedWriterIds', [data?.id]);
      }
    }}></AddMember>;
  }

  const validate = () => {
    var errorMessages: any = {};
    if (!formData.title) {
      errorMessages.title = 'This field is required';
    }
    setErrors(errorMessages);
    return Object.keys(errorMessages).length === 0;
  }

  return (
    <Grid container style={{ padding: "0 46px 0 18px" }}>
      <Grid item xs={12}>
        <Grid container spacing={2} direction="column" className={classes.addNewTitleContainer}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="standard"
              name="contentTitle"
              label={t('Content Title')}
              onChange={(value) => { dataHandler('title', value.currentTarget.value) }}
              value={formData.title}
              error={Boolean(errors.title)}
              helperText={errors.title}
            />
          </Grid>
          {selectContentTypes()}
          {selectWriter()}
          <Grid item xs={12}>
            <FormControlLabel
              label={t('Show Writer Name')}
              control={
                <Switch name="showWriter"
                  checked={formData.showWriter}
                  onChange={(value) => { dataHandler('showWriter', value.target.checked) }} />}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button
                  onClick={() => {
                    props.onClose && props.onClose(false);
                  }}
                >
                  {t('Cancel')}
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton loading={isLoading} variant="contained" onClick={() => {
                  if (!validate()) return;

                  if (formData.id)
                    proxyExecute(`Contents/${formData.id}`, HttpMethod.Put, {
                      body: formData,
                      setIsLoading,
                      callback: () => { props.onClose && props.onClose(true) }
                    });
                  else
                    proxyExecute(`Contents`, HttpMethod.Post, {
                      body: formData,
                      setIsLoading,
                      callback: () => { props.onClose && props.onClose(true) }
                    })
                }}>
                  {t('Ok')}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {addWriter()}
    </Grid>
  );
};

export default ContentDefinition;
