import React, { useEffect, useState } from "react";
import useStyles from "./useStyles";
// Material Components
import { Avatar, Button, Divider, Grid, Paper, Typography } from "@mui/material";
// Material Icons
import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';
// Custom Hooks
import { useCustomHooks } from '../../../components/hooks';
// Screens

// Components
import { Icons } from "components/icons";
import Loading from "components/loading";
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";

import { useLocalStorage } from "hooks";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "store";
import { loggedIn } from "store/authentication/reducer";
import { getUserInfo } from "../../../utils";

const SelectTenant: React.FC = () => {
  const { t, proxyExecuteForAuth } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [userTenantRoles, setUserTenantRoles] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const [, setLocalStorageToken] = useLocalStorage('token', '');
  const state = useSelector((state: any) => state);

  useEffect(() => {
    if (userTenantRoles) return;

    var userId = getUserInfo().userId;
    if (userId) {
      proxyExecuteForAuth(`Auth/UserTenantRoles/${userId}`, HttpMethod.Get, {
        setIsLoading,
        token: state.authentication.token,
        callback: (data: any) => {
          setUserTenantRoles(data);
        }
      });
    }
  }, [proxyExecuteForAuth, state.authentication.token, userTenantRoles]);

  const onTenantClick = (tenantId: any) => {
    proxyExecuteForAuth(`Account/ChangeTenant/${tenantId}`, HttpMethod.Put, {
      setIsLoading,
      token: state.authentication.token,
      callback: (data: any) => {
        setLocalStorageToken(data);
        dispatch(loggedIn());
        history.replace({ pathname: "/" });
      }
    });
  }

  return (
    <Grid container direction="column" alignItems="center" alignContent="center" className={classes.loginContainer}>
      <Grid item>
        <Paper className={classes.paper}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography className={classes.logo}>
                <Icons.Logo />
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.title}>{t('Select a profile')}</Typography>
            </Grid>
            {isLoading && <Loading></Loading>}
            {!isLoading && userTenantRoles && userTenantRoles.map((userTenantRole: any, index: any) => (
              <Grid style={{ cursor: 'pointer' }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = '#f5f5f5';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = 'transparent';
                }}>
                <Grid
                  key={index}
                  style={{
                    margin: '16px',
                    padding: '16px',
                    minWidth: '200px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    transition: 'background-color 0.3s ease'
                  }}
                  onClick={() => onTenantClick(userTenantRole.tenantId)}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar style={{ width: '50px', height: '50px', fontSize: '24px', marginRight: '16px' }}>
                      {userTenantRole.tenantName.charAt(0).toUpperCase()}
                    </Avatar>
                    <div>
                      <Typography variant="h6">
                        {userTenantRole.tenantName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {userTenantRole.roleName}
                      </Typography>
                    </div>
                  </div>
                  <KeyboardArrowRightIcon />
                </Grid>
                <Divider></Divider>
              </Grid>
            ))}
            <Button onClick={() => {
              history.replace({ pathname: "/giris-yap" });
            }}>{t('Login with different user')}</Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default SelectTenant;
