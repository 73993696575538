import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    marginBottom: 16
  },
  container: {
    flex: 1,
  },
  titleContentWrapper: {
    borderRadius: 12,
    padding: "5px 8px 5px 24px",
    border: "1px solid rgba(0, 0, 0, 0.12)"
  },
  contentTitle: {
    fontSize: 18,
    color: "#24272A",
    lineHeight: "23px",
    fontWeight: 600
  },
  writer: {
    fontSize: 14,
    color: "#24272A",
  },
  titleContentLeftContainer: {
    flex: 1,
    flexGrow: 1,
    paddingRight: 10,
  },
  titleContentRightContainer: {
    alignSelf: "flex-start",
  },
}));

export default useStyles;
