import { Icons } from "components/icons";
import { useSnackbar } from "components/snackbar";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { usePostResetPasswordMutation } from "store/authentication/api";
import * as Yup from "yup";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";

import useStyles from "./useStyles";

interface LocationState {
  email: string;
  passwordResetToken: string;
}

const ResetPassword: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(["error"]);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation<LocationState>();
  const [resetPassword] = usePostResetPasswordMutation();
  const [passwordInputType, setPasswordInputType] = useState<"text" | "password">("password");
  const [rePasswordInputType, setRePasswordInputType] = useState<"text" | "password">("password");

  const formik = useFormik({
    initialValues: {
      password: "",
      rePassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(t("error:required"))
        .matches(/^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[aA-zZ]){1}).*$/, t("error:passwordMatches")),
      rePassword: Yup.string()
        .required(t("error:required"))
        .oneOf([Yup.ref("password"), null], t("error:passwordMustMatch")),
    }),
    onSubmit: (values) => {
      resetPassword({
        password: values.password,
        email: location.state.email,
        confirmPassword: values.rePassword,
        passwordResetToken: location.state.passwordResetToken,
      })
        .unwrap()
        .then(({ success }) => {
          if (success) {
            enqueueSnackbar("Şifreniz değiştirildi.", {
              variant: "success",
            });
            history.replace({ pathname: "giris-yap" });
          }
        });
    },
  });

  return (
    <Grid container direction="column" alignItems="center" alignContent="center" className={classes.resetPasswordContainer}>
      <Grid item>
        <form onSubmit={formik.handleSubmit}>
          <Paper className={classes.paper}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.logo}>
                  <Icons.Logo />
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.title}>Parola sıfırla</Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label="Parola"
                  name="password"
                  variant="standard"
                  type={passwordInputType}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  helperText={formik.touched.password && formik.errors.password}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className={classes.adornment} position="end">
                        <IconButton
                          color="inherit"
                          className={classes.passwordInfoButton}
                          onClick={() => {
                            setPasswordInputType(passwordInputType === "password" ? "text" : "password");
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  name="rePassword"
                  variant="standard"
                  label="Parola tekrar"
                  type={rePasswordInputType}
                  onChange={formik.handleChange}
                  value={formik.values.rePassword}
                  helperText={formik.touched.rePassword && formik.errors.rePassword}
                  error={formik.touched.rePassword && Boolean(formik.errors.rePassword)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className={classes.adornment} position="end">
                        <IconButton
                          color="inherit"
                          className={classes.passwordInfoButton}
                          onClick={() => {
                            setRePasswordInputType(rePasswordInputType === "password" ? "text" : "password");
                          }}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Typography className={classes.passwordInfo}>
                  Yeni parolanızı doğrulamak için parola tekrar alanına da giriniz.
                </Typography>
              </Grid>
              <Grid item>
                <Button fullWidth size="large" type="submit" variant="contained" className={classes.sendButton}>
                  İleri
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
