import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  chapterTitleWrapper: {
    paddingBottom: 32,
  },
  chapterTitle: {
    color: "#4F525B",
    fontWeight: 700,
    letterSpacing: 2,
    fontSize:14
  },
  addContentButton: {
    float: "right",
  },
  chapterTitleTopContainer: {
    flex: 1,
  },
  chapterTitleTopWrapper: {
    paddingRight: 10,
  },
  paddingRight: {
    paddingRight: "36px !important",
  },
  paddingRightMd: {
    paddingRight: "0px !important",
  },
  header: {
    marginBottom: 20,
  },
  footer: {
    marginTop: 20,
  },
  chapterContainer: {
    marginBottom: 20,
  },
}));

export default useStyles;
