import React from "react";

import { Button, Grid, Switch, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
// Custom Hooks
import { useCustomHooks } from 'components/hooks';
import Dialog from "components/dialog";

interface Props {
  onDeleteClicked: () => void;
  onCancelClicked: () => void;
}

const DeleteConfirmation: React.FC<Props> = (props: Props) => {
  const { t } = useCustomHooks();
  const [hardDelete, setHardDelete] = React.useState(true);

  return (
    <Dialog
      open
      onClose={() => { props.onCancelClicked && props.onCancelClicked() }}
      maxWidth={'xs'}
    >
      <DialogTitle>
        {t('Record will be deleted')}
      </DialogTitle>
      <DialogContent>
        <Grid style={{ paddingLeft: '20px', paddingRight: '24px' }}
          container
          flexDirection={'row'}>
          <Grid item flexDirection={'row'}>
            <Switch value={hardDelete} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setHardDelete(!event.target.checked);
            }}></Switch>
          </Grid>
          <Grid item flexDirection={'row'}>
            <DialogContentText>
              <Grid display={'flex'} justifyContent={"flex-start"}>
                {t('Delete all content')}
              </Grid>
            </DialogContentText>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ padding: '12px' }}>
        <Button onClick={() => { props.onCancelClicked && props.onCancelClicked() }}>{t('Cancel')}</Button>
        <Button disabled={hardDelete} onClick={() => {
          props.onDeleteClicked && props.onDeleteClicked();
        }} autoFocus>
          {t('Delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmation;
