import React, { useEffect, useState } from "react";
import useStyles from "./useStyles";
// Custom Hooks
import { useCustomHooks } from '../../components/hooks';

import Edition from './edition'
import { getEditions } from "utils/apiCalls/editions";
import { HttpMethod } from "utils/constants/enums";

interface Props {
  tenantId?: any;
  isPublished?: boolean;
  isApproved?: boolean;
  refreshData: boolean;
  loadingCompleted?: (itemCount: number) => void;
}

const EditionContainer: React.FC<Props> = ({ refreshData, tenantId, isPublished, isApproved, loadingCompleted }) => {
  const { proxyExecute } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [editionIdList, setEditionIdList] = useState<any>();

  useEffect(() => {
    if (refreshData)
      setEditionIdList(undefined);
  }, [refreshData]);

  useEffect(() => {
    if (editionIdList) return;
    proxyExecute(`Editions/List?IsApproved=${isApproved ?? false}&IsPublished=${isPublished ?? false}`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => {
        setEditionIdList(data);
        loadingCompleted && loadingCompleted(data.length);
      },
      authorizedTenantId: tenantId
    });
  }, [editionIdList]);

  if (!editionIdList || (editionIdList && editionIdList.length === 0))
    return <div></div>

  return (<>
    {editionIdList.map((editionId: any) => {
      return (
        <Edition edition={editionId} isPublished={isPublished} tenantId={tenantId} editionId={editionId.id} onClick={(edition) => { }} onDeleteClick={(id: any) => {
          setEditionIdList(undefined);
        }} />
      );
    })}
  </>
  );
};

export default EditionContainer;
