import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100%",
  },
  pageWrapper: {
    flex: 1
  },

  maxWidthMd: {
    maxWidth: 1320,
    userSelect: "none",
    padding: "70px 24px",
  },
  issueContainer: {
    marginBottom: 30,
  },
  editionTitleWrapper: {
    marginBottom: 12,
  },
  editionName: {
    fontSize: 24,
    color: "#2B2B2B",
  },
  addIcon: {
    marginRight: 10,
  },
  outerGrid: {
    flex: 1,
  },
  innerGrid: {
    flex: 1,
    display: "flex",
    alignSelf: "center",
    alignItems: "center",
  },
}));

export default useStyles;
