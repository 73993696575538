import { rtkQueryErrorLogger } from "middleware/rtkQueryErrorLogger";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { authenticationApi } from "store/authentication/api";
import authenticationSlice from "store/authentication/reducer";
import { contentsApi } from "store/contents/api";
import { editionApi } from "store/edition/api";
import { issueApi } from "store/issue/api";
import { writerApi } from "store/writer/api";

import { combineReducers, configureStore } from "@reduxjs/toolkit";

import type { PreloadedState } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  [issueApi.reducerPath]: issueApi.reducer,
  [writerApi.reducerPath]: writerApi.reducer,
  [editionApi.reducerPath]: editionApi.reducer,
  [contentsApi.reducerPath]: contentsApi.reducer,
  [authenticationSlice.name]: authenticationSlice.reducer,
  [authenticationApi.reducerPath]: authenticationApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    preloadedState,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        issueApi.middleware,
        writerApi.middleware,
        editionApi.middleware,
        contentsApi.middleware,
        authenticationApi.middleware
      ),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
