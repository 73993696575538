import React from "react";

const Settings: React.FC = () => {
  return (
    <div
      style={{
        flex: 1,
        fontSize: 20,
        display: "flex",
        fontWeight: "bold",
        alignSelf: "center",
        alignItems: "center",
      }}
    >
      Ayarlar
    </div>
  );
};

export default Settings;
