import fetchBaseQuery from "store/baseQuery";
import { CreateWritersResponse, WritersCreateBody, WritersResponse } from "store/writer/types";

import { createApi } from "@reduxjs/toolkit/query/react";

export const writerApi = createApi({
  reducerPath: "writerApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Writers"],
  endpoints: (builder) => ({
    getWriters: builder.query<WritersResponse[], void>({
      query: () => "/Writers",
      providesTags: ["Writers"],
      transformResponse: (response: { data: WritersResponse[] }) => response.data,
    }),
    postWritersCreate: builder.mutation<CreateWritersResponse, WritersCreateBody>({
      invalidatesTags: ["Writers"],
      query(body) {
        return { body, method: "POST", url: "/Writers" };
      },
    }),
  }),
});

export const { useGetWritersQuery, usePostWritersCreateMutation } = writerApi;
