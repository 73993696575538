import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  snackbar: {
    fontSize: 16,
    margin: "2px 0",
    letterSpacing: 0,
    boxShadow: "none",
    lineHeight: "21px",
    padding: "8px 16px",
  },
  success: {
    backgroundColor: theme.palette.success + "!important",
  },
  error: {
    backgroundColor: theme.palette.baseBlack + "!important",
  },
  warning: {
    backgroundColor: theme.palette.warning + "!important",
  },
  info: {
    backgroundColor: theme.palette.info + "!important",
  },
}));

export default useStyles;
