import { AuthenticationState, UserInfoActionType } from "store/authentication/types";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "store";

const initialState: AuthenticationState = {
  email: "",
  lastname: "",
  firstname: "",
  isLogin: false,
  isUnauthorized: false,
  token:""
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    loggedIn: (state) => {
      state.isLogin = true;
    },
    setToken: (state, action: PayloadAction<any>) => {
      state.token = action.payload;
    },
    loggedOut: (state) => {
      state.isLogin = false;
      state.isUnauthorized = false;
    },
    unauthorized: (state, action: PayloadAction<boolean>) => {
      state.isUnauthorized = action.payload;
    },
    userInfo: (state, action: PayloadAction<UserInfoActionType>) => {
      state.email = action.payload.email;
      state.lastname = action.payload.lastname;
      state.firstname = action.payload.firstname;
    },
  },
});

export const { loggedIn, loggedOut, unauthorized, userInfo, setToken } = authenticationSlice.actions;

export const selectEmail = (state: RootState) => state.authentication.email;
export const selectLogin = (state: RootState) => state.authentication.isLogin;
export const selectLastname = (state: RootState) => state.authentication.lastname;
export const selectFirstname = (state: RootState) => state.authentication.firstname;
export const selectUnauthorized = (state: RootState) => state.authentication.isUnauthorized;

export default authenticationSlice;
