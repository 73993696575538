import { alpha, Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  tabPanelRoot: {
    padding: 0,
    overflow: "hidden",
  },
  activeTabPanelRoot: {
    flex: 1,
    display: "flex",
    position: "relative",
    flexDirection: "column",
  },
  verticalActiveTabPanelRoot: {
    flex: 1,
    display: "flex",
    position: "relative",
    flexDirection: "column",
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  tabListFixed: {
    top: 56,
    zIndex: 0,
    position: "sticky",
    background: "white",
    [theme.breakpoints.only("xs")]: {
      top: 56,
    },
  },
  rightBorder: {
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.only("xs")]: {
      borderRight: "none",
    },
  },
  hasDivider: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  rightButtonContent: {
    paddingRight: 48,
    background: alpha(theme.palette.baseLight100, 0.2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  textColorPrimary: {
    fontSize: 13,
    fontWeight: 500,
    textTransform: "none",
    color: theme.palette.baseLight600,
    "&.Mui-selected": {
      color: theme.palette.primary,
    },
    "&.Mui-disabled.Mui-selected": {
      color: theme.palette.baseLight600,
    },
  },
  verticalText: {
    fontSize: 16,
    fontWeight: 400,
    textTransform: "none",
    color: theme.palette.baseLight600,
    "&.Mui-selected": {
      borderRadius: 8,
      backgroundColor: "#F43F5E1A",
      color: theme.palette.primary,
    },
  },
  rightButtonWrapper: {
    top: 0,
    right: 0,
    zIndex: -3,
    position: "absolute",
  },
  verticalRightButton: {
    padding: "24px 0",
  },
  tabRoot: {
    [theme.breakpoints.down("md")]: {
      minWidth: "auto",
      padding: "0 20px",
    },
  },
  verticalTabRoot: {
    [theme.breakpoints.only("xs")]: {
      minWidth: 360,
      padding: "0 20px",
    },
  },
  horizontalTabs: {
    padding: "0 80px",
    "& .MuiTab-wrapper": {
      alignItems: "center",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: (props: any) => {
        if (props.disabled) {
          return theme.palette.baseLight600;
        } else {
          return theme.palette.primary;
        }
      },
    },
  },
  horizontalTabsMobile: {
    padding: "0 0px",
    "& .MuiTab-wrapper": {
      alignItems: "center",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: (props: any) => {
        if (props.disabled) {
          return theme.palette.baseLight600;
        } else {
          return theme.palette.primary;
        }
      },
    },
  },
  verticalTabs: {
    minWidth: 270,
    padding: "24px 8px",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTab-wrapper": {
      alignItems: "flex-start",
    },
    [theme.breakpoints.only("xs")]: {
      "& .Mui-selected": {
        backgroundColor: "transparent",
        color: theme.palette.baseLight600,
      },
    },
  },
}));

export default useStyles;
