import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  dialogContent: {
    width: 512,
    padding: 30,
  },
  dialogPaper: {
    marginBottom: 16,
    padding: "16px 24px 24px 24px",
  },
  chapterDialogTitle: {
    fontSize: 16,
    color: "#F43F5E",
  },
  addNewTitleContainer: {
    padding: 24,
    borderRadius: 12,
    border: "1px solid rgba(244, 150, 53, 0.15)",
  },
  infoIcon: {
    color: "#666666",
  },
  plusIcon: {
    color: "#F43F5E",
  },
  addIcon: {
    marginRight: 5,
  },
}));

export default useStyles;
