import React, { useEffect, useState } from "react";
import useStyles from "./useStyles";
// Material Components
import { Grid, Typography, IconButton, Avatar } from "@mui/material";
// Material Icons

// Custom Hooks
import { useCustomHooks } from '../../../../../components/hooks';
// Screens

// Components
import Loading from "components/loading";
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";

interface Props {
  tenantId: any;
  onClick: (tenantId: any) => void;
}

const Tenant: React.FC<Props> = (props: Props) => {
  const { proxyExecute } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const { tenantId } = props;
  const [tenant, setTenant] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (tenantId && !tenant) {
      var id = tenantId;
      proxyExecute(`Tenants/${id}`, HttpMethod.Get, {
        setIsLoading,
        callback: (data: any) => {
          setTenant(data);
        }
      });
    }
  }, [proxyExecute, tenant, tenantId]);

  return (
    <Grid container className={classes.issue} onClick={() => tenantId && props.onClick(tenant)}>
      <Grid item xs={12}>
        <Grid container alignContent="flex-end" className={classes.imageContainer}>
          <Grid item xs={12}>
            {
              tenant?.imagePath ? (
                <img alt={tenant.name} className={classes.image} src={tenant.imagePath} />
              ) : (
                <Grid container style={{
                  backgroundColor: '#F5F5F5', width: 192, height: 192, borderRadius: 8, justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  {isLoading ? <Loading /> :
                    <IconButton sx={{ p: 0 }}>
                      <Avatar alt={tenant?.name} >
                        {tenant?.name.slice(0, 1)}
                      </Avatar>
                    </IconButton>
                  }
                </Grid>
              )
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>

      </Grid>
      <Grid item xs={12}>
        <Typography style={{
          fontSize: 14,
          color: "#313131",
          fontWeight: '700',
          marginBottom: '4px'
        }}>{tenant?.name}</Typography>
      </Grid>
    </Grid>
  );
};

export default Tenant;
