import React from "react";

import { SvgIconProps } from "@mui/material";

import Logo from "./logo";

interface IconsType {
  Logo: React.FC<SvgIconProps>;
}

const icons: IconsType = {
  Logo,
};

export { icons as Icons };
