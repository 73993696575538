import React, { useEffect, useState } from "react";
import useStyles from "./useStyles";
// Material Components
import { Container, Grid, IconButton, Typography, Button, Box } from "@mui/material";
// Material Icons
import { ArrowBack as ArrowBackIcon, Close as CloseIcon } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from '../../components/hooks';
// Screens

// Components
import Dialog from "components/dialog";
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";

import groupBy from "lodash.groupby";
import { NavLink } from "react-router-dom";

interface Props {
  onClose: () => void;
}

const MenuComponent: React.FC<Props> = (props: Props) => {
  const { t, proxyExecute } = useCustomHooks();
  const classes = useStyles();

  const appBarMenu: any[] = [
    { title: t('Main Page'), routerLink: "" },
    { title: t('Post'), routerLink: "yayin" },
    { title: t('Team'), routerLink: "ekip" },
    // { title: t('Plan'), routerLink: "plan" },
    // { title: t('Contact Us'), routerLink: "bize-yazin" },
    // { title: t('Settings'), routerLink: "ayarlar" },
  ];

  const [menu] = useState<Array<any>>(appBarMenu);


  const closeIcon = () => {
    return (
      <IconButton color="inherit" onClick={props.onClose} className={classes.closeIcon}>
        <CloseIcon />
      </IconButton>
    );
  };

  const renderMenu = () => {
    return menu.map((item: any) => {
      return (
        <Grid item key={item.title}>
          <NavLink exact key={item.title} to={item.routerLink} className={classes.menu} activeClassName={classes.activeMenu} onClick={() => {
            props.onClose();
          }}>
            {item.title}
          </NavLink>
        </Grid>
      );
    });
  };

  const getTitle = () => {
    return <Typography className={classes.dialogTitle}>Menu</Typography>;
  };

  return (
    <Dialog
      open
      fullScreen
      closable={false}
      titleTextAlign="left"
      onClose={props.onClose}
      rightHeader={closeIcon()}
    >
      <Container
        disableGutters
        maxWidth="md"
        classes={{
          maxWidthMd: classes.maxWidthMd,
        }}
      >
        <Grid container style={{ marginTop: 24 }}>
          <Box className={classes.banner}>
            asd
          </Box>
        </Grid>
        <Grid container direction="column">
          {renderMenu()}
        </Grid>
      </Container>
    </Dialog>
  );
};

export default MenuComponent;