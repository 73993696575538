import React, { useState, useEffect } from "react";
import useStyles from "./useStyles";
// Material Components
import { Button, Container, Grid, IconButton } from "@mui/material";
// Material Icons
import { Delete as DeleteIcon, Add as AddIcon } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from '../../../components/hooks';
// Screens
import { AddManagerAndOwner } from './addManagerAndOwner'
// Components
import EnhancedTable from '../../../components/dataGrid/dataGrid'
import DeleteConfirmation from "components/deleteConfirmation";
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";

const ManagersAndOWners: React.FC = () => {
  const { t, proxyExecute } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [showAddManagerAndOwnerForm, setShowAddMemberForm] = useState<boolean>(false);
  const [members, setMembers] = useState<any>();
  const [selectedMembers, setSelectedMembers] = useState<any>([]);
  const [deleteEditionConfirmation, setDeleteEditionConfirmation] = useState(false);

  useEffect(() => {
    if (members) return;

    proxyExecute(`Members/ManagerAndOwner`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => {
        if (!data) {
          setMembers([]);
        }
        else {
          setMembers(data.map((item: any) => {
            return {
              ...item,
              fullname: item.firstname + ' ' + item.lastname
            };
          }));
        }
      }
    });
  }, [members, proxyExecute]);

  useEffect(() => {
    setSelectedMembers(selectedMembers);
  }, [selectedMembers]);

  const headCells = [
    {
      id: 'fullname',
      numeric: false,
      disablePadding: true,
      label: t('Name'),
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('Email'),
    },
    {
      id: 'memberTypeName',
      numeric: false,
      disablePadding: false,
      label: t('Role'),
    }
  ];

  return (
    <Grid container direction="column" className={classes.pageWrapper}>
      <Grid item >
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.container}
          classes={{
            maxWidthMd: classes.maxWidthMd,
          }}
        >
          <Grid container xs={12}>
            <Grid xs={12}>
              {members && <EnhancedTable
                rows={members}
                columns={headCells}
                headerTitle={t('{{count}} records listed', { count: members.length })}
                headerRightContent={
                  <Grid container direction={'row'} justifyContent="flex-end">
                    <Button key={123} size="small" variant="contained" onClick={(data: any) => {
                      setShowAddMemberForm(true);
                    }}>
                      <AddIcon className={classes.addIcon} />
                      {t('Add manager/admin')}
                    </Button>
                  </Grid>
                }
                headerRightContent2={
                  <Grid container direction={'row'} justifyContent="flex-end">
                    <IconButton key={123} onClick={() => {
                      setDeleteEditionConfirmation(true);
                    }}>
                      <DeleteIcon className={classes.addIcon} />
                    </IconButton>
                  </Grid>
                }
                onSelected={(selected) => {
                  setSelectedMembers(selected);
                }}
              ></EnhancedTable>}
            </Grid>
          </Grid>
        </Container>
      </Grid>

      {showAddManagerAndOwnerForm &&
        <AddManagerAndOwner onClose={(data: any) => {
          setShowAddMemberForm(false);
          setMembers(undefined);
        }}>
        </AddManagerAndOwner>}

      {deleteEditionConfirmation &&
        <DeleteConfirmation onDeleteClicked={() => {
          setDeleteEditionConfirmation(false);
          selectedMembers.map((id: any) => {
            proxyExecute(`Members/${id}`, HttpMethod.Delete, {
              setIsLoading: undefined,
              callback: () => { setMembers(undefined) }
            })
          })
        }}
          onCancelClicked={() => {
            setDeleteEditionConfirmation(false);
          }}></DeleteConfirmation>}
    </Grid>
  );
};

export default ManagersAndOWners;
