/* Common Theme Color Pallette */
const LightTheme = {
  divider: "#DCDCDC",
  primary: {
    main: "#F43F5E",
  },
  secondary: {
    main: "#0032AA",
  },
  background: {
    default: "#FFFFFF",
  },
  success: {
    main: "#27AE60",
  },
  warning: {
    main: "#FFCC00",
  },
  error: {
    main: "#FF3B30",
  },
  info: {
    main: "#5AC8FA",
  },
  //Custom
  primaryVariant: "#00C8C8",
  secondaryVariant: "#FFB900",
  paper: "#FFFFFF",
  surface: "#ECF2F4",
  //White
  baseWhite: "#FFFFFF",
  baseLight100: "#EFF0F5",
  baseLight200: "#E5E5EA",
  baseLight300: "#D1D1D6",
  baseLight400: "#C7C7CC",
  baseLight500: "#95A5A5",
  baseLight600: "#6E787B",
  baseLight700: "#384245",
  //Black
  baseBlack: "#000000",
  baseDark100: "#000000",
  baseDark200: "#1C1C1D",
  baseDark300: "#3A3A3C",
  baseDark400: "#48484A",
  baseDark500: "#636366",
  baseDark600: "#8E8E93",
  baseDark700: "#ADBBBF",
  //Other
  red: "#D80000",
  blue: "#8BAAFF",
  green: "#60D7AF",
  orange: "#FF9373",
  yellow: "#FFCF8B",
};

export default LightTheme;
