import Constants from "utils/constants";

import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query";

import { loggedIn, unauthorized } from "./authentication/reducer";

const baseQuery = fetchBaseQuery({
  baseUrl: Constants.API_URL,
  prepareHeaders: (headers, { getState }) => {
    let token;
    if (typeof window !== "undefined") {
      token = window.localStorage.getItem("token");
    }
    if (token) {
      headers.set("authorization", `Bearer ${JSON.parse(token)}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    //const refreshResult = await baseQuery('/refreshToken', api, extraOptions);
    if (false) {
      // store the new token
      api.dispatch(loggedIn());
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(unauthorized(true));
    }
  }
  return result;
};

export default baseQueryWithReauth;
