import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({

  issue: {
    cursor: "pointer",
  },
  imageContainer: {
  },
  image: {
    width: "100%",
    display: "block",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  date: {
    fontSize: 15,
    color: "#4F525B",
  },
  imageNotfound: {

    width: "100%",
    borderRadius: 12,
    display: "block",
    background: "grey",
  },
}));

export default useStyles;
