import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    top: 0,
    zIndex: 0,
    position: "fixed",
    backgroundColor: theme.palette.baseWhite,
    borderBottom: `1px solid ${theme.palette.divider}`,
    justifyContent:"center"
  },
  logoWrapper: {
    marginRight: 50,
    color: theme.palette.baseBlack,
  },
  logo: {
    textDecoration: "none",
  },
  betaText: {
    top: -7,
    fontSize: 10,
    color: "grey",
    position: "relative",
    display: "inline-block",
  },
  menu: {
    height: 36,
    fontSize: 14,
    marginRight: 12,
    padding: "0 24px",
    borderRadius: 150,
    lineHeight: "36px",
    fontWeight: "bold",
    textDecoration: "none",
    display: "inline-block",
    color: theme.palette.primary.main,
  },
  activeMenu: {
    color: theme.palette.baseWhite,
    backgroundColor: theme.palette.primary.main,
  },
  user: {
    marginLeft: "auto",
  },
  userIcon: {
    fontSize: 38,
    borderRadius: 150,
    background: theme.palette.baseLight400,
    border: `1px solid ${theme.palette.divider}`,
  },
  signInButton: {
    marginLeft: 10,
  },
  loginButton: {
    marginLeft: 10,
  },
}));

export default useStyles;
