import React, { useState } from "react";
import useStyles from "./useStyles";
// Material Components
import { Grid, Paper, TextField, Typography, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// Material Icons

// Custom Hooks
import { useCustomHooks } from '../../../components/hooks';

// Screens

// Components
import Dialog from "components/dialog";
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";

interface AddManagerAndOwnerProps {
    onClose: (data: any) => void;
}

export const AddManagerAndOwner: React.FC<AddManagerAndOwnerProps> = (props: AddManagerAndOwnerProps) => {
    const { t, proxyExecute } = useCustomHooks();
    const classes = useStyles();

    // Variables
    const [formData, setFormData] = useState<any>({
        memberTypeId: "5"
    });
    const [errors, setErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const dataHandler = (field: any, value: any,) => {
        setFormData({ ...formData, [field]: value });
    };

    const validate = () => {
        var errorMessages: any = {};
        if (!formData.firstName || formData.firstName === '') {
            errorMessages.firstName = t('This field is required');
        }
        if (!formData.lastName || formData.lastName === '') {
            errorMessages.lastName = t('This field is required');
        }
        if (!formData.email || formData.email === '') {
            errorMessages.email = t('This field is required');
        }
        setErrors(errorMessages);
        return Object.keys(errorMessages).length === 0;
    }

    return (
        <Dialog
            open={true}
            closable={true}
            showAlwaysTopDivider={true}
            onClose={() => {
                props.onClose && props.onClose(formData);
            }}
            title={t('Add manager/admin')}
        >
            <Grid container style={{
                width: 512,
                padding: 30
            }}>
                <Grid item xs={12}>
                    <Paper className={classes.dialogPaper}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            name="firstName"
                                            label={t('Name')}
                                            variant="standard"
                                            onChange={(value) => { dataHandler('firstName', value.currentTarget.value) }}
                                            value={formData.firstName}
                                            error={Boolean(errors.firstName)}
                                            helperText={errors.firstName}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label={t('Surname')}
                                            name="lastName"
                                            variant="standard"
                                            onChange={(value) => { dataHandler('lastName', value.currentTarget.value) }}
                                            value={formData.lastName}
                                            error={Boolean(errors.lastName)}
                                            helperText={errors.lastName}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    name="email"
                                    label={t('Email')}
                                    variant="standard"
                                    onChange={(value) => { dataHandler('email', value.currentTarget.value) }}
                                    value={formData.email}
                                    error={Boolean(errors.email)}
                                    helperText={errors.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ToggleButtonGroup
                                    value={formData.memberTypeId}
                                    exclusive
                                    onChange={(event: React.MouseEvent<HTMLElement>, value: string | null,) => { dataHandler('memberTypeId', value); }}
                                >
                                    <ToggleButton value="5">
                                        <Typography>{t('Manager')}</Typography>
                                    </ToggleButton>
                                    <ToggleButton value="6">
                                        <Typography>{t('Owner')}</Typography>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={12}>
                                {formData.memberTypeId == "5" && <Typography align="center" style={{ fontSize: 11, color: "#D1D4D8" }}>{t('Can do all operations except edit account settings')}</Typography>}
                                {formData.memberTypeId == "6" && <Typography align="center" style={{ fontSize: 11, color: "#D1D4D8" }}>{t('Can do all operations except edit account settings')}</Typography>}
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton fullWidth type="submit" variant="contained" loading={isLoading}
                        onClick={() => {
                            if (!validate()) return;

                            proxyExecute(`Members`, HttpMethod.Post, {
                                body: formData,
                                setIsLoading,
                                callback: (data: any) => { props.onClose && props.onClose(data) }
                            });
                        }}
                        style={{
                            marginTop: 20,
                            marginBottom: 10,
                          }}
                    >
                        {t('Next')}
                    </LoadingButton>
                </Grid>
            </Grid>
        </Dialog>
    );
};
