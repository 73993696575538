import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    position: "relative",
    color: theme.palette.baseLight500,
  },
  dialogTitle: {
    fontSize: 18,
    fontWeight: 500,
    color: "#4F525B",
  },
  subDialogTitle: {
    color: "#6E717A",
    letterSpacing: 5.4,
    fontWeight:800

  },
  maxWidthMd: {
    maxWidth: 1080,
    userSelect: "none",
    padding: "50px 24px",
  },
  image: {
    width: "100%",
    display: "block",
  },
  info: {
    fontSize: 20,
    color: "#6E717A",
    marginBottom: 40,
  },
  copyright: {
    fontSize: 11,
    color: "#D1D4D8",
  },
  date: {
    fontSize: 15,
    color: "#4F525B",
  },
}));

export default useStyles;
