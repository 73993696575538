import React from "react";
import { useAppSelector } from "store";
import { selectEmail, selectFirstname, selectLastname } from "store/authentication/reducer";

import { Grid, Link, Typography } from "@mui/material";

import useStyles from "./useStyles";

const SendEmail: React.FC = () => {
  const classes = useStyles();
  const email = useAppSelector(selectEmail);
  const lastname = useAppSelector(selectLastname);
  const firstname = useAppSelector(selectFirstname);

  return (
    <Grid container direction="column" alignContent="center" className={classes.pageContainer}>
      <Grid item>
        <div className={classes.image}></div>
      </Grid>
      <Grid item>
        <Typography className={classes.nameText}>
          Neredeyse bitti, {firstname} {lastname}.
        </Typography>
        <Typography className={classes.info1}>Hesabınızı etkinleştirin…</Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.info2}>
          <Link>{email}</Link> adresine bir e-posta gönderdik.
          <br />
          Lütfen gelen kutunuzu kontrol edin ve hesabınızı etkinleştirmek için e-postadaki bağlantıya tıklayın.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SendEmail;
