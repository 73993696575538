import React, { useEffect, useState, useRef } from "react";
import useStyles from "./useStyles";
// Material Components
import { ButtonGroup, ClickAwayListener, Grid, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Typography } from "@mui/material";
//Material Icons
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from '../../../components/hooks';
// Screens
import IssueDefinition from "pages/post/posted/postDefinition/issueDefinition";
// Components
import Loading from "components/loading";
import DeleteConfirmation from "components/deleteConfirmation";
// Constants&Enums
import Constants from "utils/constants";

import { PeriodCode } from "store/types";
import { HttpMethod } from "utils/constants/enums";

interface Props {
  issueId: any;
  onClick?: (issueId: any) => void;
  onIssueDeleted?: () => void;
  tenantId: any;
  issue: any;
}

const Issue: React.FC<Props> = (props: Props) => {
  const { t, i18n, proxyExecute, isMd } = useCustomHooks();
  const classes = useStyles();

  const [issue, setIssue] = useState<any>();
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const [openContextMenu, setOpenContextMenu] = useState(false);
  const [deleteIssueConfirmation, setDeleteIssueConfirmation] = useState(false);
  const [openIssueDefinitionAsDialog, setOpenIssueDefinitionAsDialog] = useState(false);
  const [isHover, setHover] = useState<boolean>(false);

  const getDate = () => {
    if (!issue) return;

    const { period } = issue.edition;
    const date = new Date();
    date.setFullYear(issue.year);

    let formattedDate = "";

    switch (period.code) {
      case PeriodCode.Daily:
      case PeriodCode.Weekly:
        formattedDate = new Intl.DateTimeFormat(i18n.language, {
          day: "numeric",
          month: "long",
          year: "numeric",
        }).format(new Date(issue.date));
        break;
      case PeriodCode.Monthly:
        date.setMonth(issue.periodItem.code); // TODO: ay bir fazla atıyor hep sonraki ayı yazıyor.
        formattedDate = new Intl.DateTimeFormat(i18n.language, {
          month: "long",
          year: "numeric",
        }).format(date);
        break;
      case PeriodCode.Quarterly:
      case PeriodCode.TwoMonthly:
      case PeriodCode.Seasonal:
      case PeriodCode.SemiAnnual:
        formattedDate = `${issue.periodItem?.name} ${new Intl.DateTimeFormat(i18n.language, {
          year: "numeric",
        }).format(date)}`;
        break;
      case PeriodCode.Annual:
        formattedDate = new Intl.DateTimeFormat(i18n.language, {
          year: "numeric",
        }).format(date);
        break;
      default:
        break;
    }

    return (
      <Typography className={classes.date}>
        {formattedDate}
      </Typography>
    );
  };

  useEffect(() => {
    if (issue) return;

    if(props.issue)
      setIssue(props.issue);

    // proxyExecute(`Issues/Detail?Id=${props.issueId}`, HttpMethod.Get, {
    //   setIsLoading: undefined,
    //   callback: (data: any) => { setIssue(data) },
    //   authorizedTenantId: props.tenantId
    // });
  });

  if (!issue) {
    return (
      <Grid container>
        <Loading />
      </Grid>
    );
  }

  const getLanguageFlag = (languageCode: any) => {
    switch (languageCode) {
      case 'TUR':
        return <div>&#x1F1F9;&#x1F1F7;</div>
      case 'ENG':
        return <div>&#x1F1EC;&#x1F1E7;</div>;
      default:
        return <div></div>;
    }
  }


  const handleToggle = (value: any) => {
    if (value === undefined)
      setOpenContextMenu((prevOpenContextMenu: any) => !prevOpenContextMenu);
    else
      setOpenContextMenu(value);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenContextMenu(false);
  };

  const getContectMenu = () => {
    return <Popper
      style={{ zIndex: 2 }}
      transition
      open={openContextMenu}
      disablePortal role={undefined} anchorEl={anchorRef.current}
      placement={'bottom-end'}>
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="split-button-menu">
                {(!props.tenantId || issue.hasAccessToEditIssue) && <MenuItem onClick={() => {
                  setOpenIssueDefinitionAsDialog(true);
                  setOpenContextMenu(false);
                }}>
                  <Typography>{t('Edit Issue Detail')}</Typography>
                </MenuItem>}
                {(!props.tenantId || issue.hasAccessToDeleteIssue) && <MenuItem onClick={() => {
                  setOpenContextMenu(false);
                  setDeleteIssueConfirmation(true);
                }}>
                  <Typography>{t('Delete Issue')}</Typography>
                </MenuItem>}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>;
  }

  return (
    <div onMouseEnter={() => setHover(true)} onMouseLeave={() => { setHover(false); setOpenContextMenu(false) }}>
      <Grid>
        <Grid container className={classes.issue} onClick={() => {
          props.onClick && props.onClick(props.issueId);
        }}>
          <Grid item xs={12}>
            <Grid container alignContent="flex-end" className={classes.imageContainer}>
              <Grid item xs={12}>
                {issue?.imagePath ? (
                  <img alt={issue.edition.name} className={classes.image} src={`${Constants.API_URL}${issue?.imagePath}`} />
                ) : (
                  <div className={classes.imageNotfound} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.issueTitle}>
              {issue.edition.name}
            </Typography>
            {getDate()}
            <Typography style={{ fontSize: '12px' }}>
              {t('Issue') + ' ' + issue?.issueNumber}
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={12} direction="row" justifyContent="space-between" alignItems="center" style={{ height: 45 }}>
          <Grid container flex={1} direction={"row"} alignItems="center">
            {getLanguageFlag(issue.language)}
            <Typography style={{ fontSize: '12px', marginLeft: 5 }}>
              {issue.language}
            </Typography>
          </Grid>
          {isHover && (!props.tenantId || issue.hasAccessToDeleteIssue || issue.hasAccessToEditIssue) && !isMd && <Grid>
            <ButtonGroup ref={anchorRef} >
              <IconButton onClick={handleToggle}><MoreVertIcon style={{ height: 25 }} /></IconButton>
            </ButtonGroup>
          </Grid>}
          {getContectMenu()}
        </Grid>
        {openIssueDefinitionAsDialog && <IssueDefinition editionId={issue.edition.id} issueId={issue.id}
          onClose={() => {
            setOpenIssueDefinitionAsDialog(false);
            props.onIssueDeleted && props.onIssueDeleted();
          }}>
        </IssueDefinition>}
        {deleteIssueConfirmation && <DeleteConfirmation onDeleteClicked={() => {
          setDeleteIssueConfirmation(false);

          var id = props.issueId;
          proxyExecute(`Issues/${id}`, HttpMethod.Delete, {
            setIsLoading: undefined,
            callback: () => { props.onIssueDeleted && props.onIssueDeleted() }
          })
        }}
          onCancelClicked={() => {
            setDeleteIssueConfirmation(false);
          }}></DeleteConfirmation>}
      </Grid>
    </div>
  );
};

export default Issue;
