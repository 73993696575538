import React, { useEffect, useState } from "react";
// Material Components
import { Grid } from "@mui/material";
// Material Icons

// Custom Hooks
import { useCustomHooks } from 'components/hooks';
// Screens

//Components
import Chapter from "./chapter";

import Loading from "components/loading";
import { useSnackbar } from "components/snackbar";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { usePutContentChaptersListMutation } from "store/contents/api";
import { ChaptersResponse } from "store/contents/types";
import { getDragAndDropListByPostType, updateSequenceNumberByDragDrop } from "utils/apiCalls/contentChapters";
import { ChapterType, HttpMethod } from "utils/constants/enums";

import { getItemStyle, getQuestionListStyle } from "./utils";

interface Props {
  refreshPage: () => void;
  postType: any;
  id: any;
  tenantId: any;
  authorizationContext: any;
}

const ContentChapterList: React.FC<Props> = (props: Props) => {
  const { t, proxyExecute } = useCustomHooks();

  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const [chapters, setChapters] = useState<any>();
  const [putContentChaptersList] = usePutContentChaptersListMutation();

  useEffect(() => {
    if (chapters) return;

    var postType = props.postType;
    var id = props.id;
    proxyExecute(`ContentChapters/GetDragAndDropListByPostType?postType=${postType}&id=${id}`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => {
        setChapters(data);
      }
    });
  });

  const handleDragEnd = (result: any) => {
    const { source, destination } = result;

    if (!destination) return;

    if (result.type === "chapter") {
      var sourceChapter = chapters[source.index];
      var destinationChapter = chapters[destination.index];

      proxyExecute(`ContentChapters/DragAndDrop`, HttpMethod.Put, {
        body: {
          postType: props.postType,
          postId: props.id,
          sourceSequenceNumber: sourceChapter.sequenceNumber,
          targetSequenceNumber: destinationChapter.sequenceNumber
        },
        setIsLoading: undefined,
        callback: (data: any) => {
          setChapters(undefined);
        }
      });
    } else if (result.type === "content") {
      const sourceChapterId = source.droppableId.split("$")[1];
      const targetChapterId = destination.droppableId.split("$")[1];

      const sourceChapter = chapters.find((x: any) => x.id === sourceChapterId);
      const content = sourceChapter.contents[source.index];

      var targetSequenceNumber = 0;
      if (sourceChapterId === targetChapterId) {
        const targetContent = sourceChapter.contents[destination.index];
        targetSequenceNumber = targetContent.sequenceNumber;
      }
      else {
        const targetChapter = chapters.find((x: any) => x.id === targetChapterId);
        if (targetChapter.contents && targetChapter.contents.length > 0) {
          const targetContent = targetChapter.contents[destination.index];
          targetSequenceNumber = targetContent ? targetContent.sequenceNumber : targetChapter.contents.length + 1;
        }
      }

      proxyExecute(`ContentChapters/DragAndDrop`, HttpMethod.Put, {
        body: {
          postType: props.postType,
          postId: props.id,
          sourceContentChapterId: sourceChapterId,
          targetContentChapterId: targetChapterId,
          contentId: content.id,
          sourceSequenceNumber: content.sequenceNumber,
          targetSequenceNumber: targetSequenceNumber
        },
        setIsLoading: undefined,
        callback: (data: any) => {
          setChapters(undefined);
        }
      });
    }
  };


  if (!chapters) {
    return (
      <Grid container>
        <Loading />
      </Grid>
    );
  }

  const hasBodyChapter = () => {
    if (chapters.length === 2)
      return false;
    return true;
  }

  const getChapterType = (index: any) => {
    if (index === 0)
      return ChapterType.Header
    if (index === chapters.length - 1)
      return ChapterType.Footer
    return ChapterType.Body
  }

  const isLastBodyChapter = (index: any) => {
    if (index !== 0 && index === chapters.length - 2)
      return true;
    return false;
  }

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable type="chapter" ignoreContainerClipping={true} droppableId="droppableStepMain">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} style={getQuestionListStyle(snapshot.isDraggingOver)}>
              {chapters.map((chapter: any, index: number) => {
                return (
                  <Draggable index={index} key={chapter.id} draggableId={chapter.id.toString()}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <div {...provided.dragHandleProps} />
                        <Chapter chapter={chapter.contentChapterModel} authorizationContext={props.authorizationContext} tenantId={props.tenantId} chapterIndex={index} chapterId={chapter.id}
                          dragProps={provided.dragHandleProps} hasBody={hasBodyChapter()}
                          chapterType={getChapterType(index)}
                          isLastBodyChapter={isLastBodyChapter(index)}
                          postType={props.postType}
                          postId={props.id}
                          reloadChapters={() => {
                            setChapters(undefined);
                          }} />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default ContentChapterList;
