import React from "react";

const Plan: React.FC = () => {
  return (
    <div
      style={{
        flex: 1,
        fontSize: 20,
        display: "flex",
        fontWeight: "bold",
        alignSelf: "center",
        alignItems: "center",
      }}
    >
      Plan
    </div>
  );
};

export default Plan;
