import React from "react";
// Custom Hooks
import { useCustomHooks } from '../../../components/hooks';

const Welcome = () => {
  const { t } = useCustomHooks();
  return (
    <div
      style={{
        flex: 1,
        fontSize: 20,
        display: "flex",
        fontWeight: "bold",
        alignSelf: "center",
        alignItems: "center",
      }}
    >
      {t('Welcome')}
    </div>
  );
};

export default Welcome;
