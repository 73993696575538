import React from "react";

const Instagram = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_9343" data-name="Rectangle 9343" width="16" height="16" fill="none" />
        </clipPath>
      </defs>
      <g id="Group_24490" dataName="Group 24490" transform="translate(-152 -2259)">
        <rect id="Rectangle_9334" dataName="Rectangle 9334" width="24" height="24" rx="12" transform="translate(152 2259)" opacity="0" />
        <g id="Group_24525" dataName="Group 24525" transform="translate(156 2263)">
          <g id="Group_24525-2" dataName="Group 24525" clip-path="url(#clip-path)">
            <path id="Path_17908" dataName="Path 17908" d="M8,1.442c2.136,0,2.389.008,3.233.047a4.436,4.436,0,0,1,1.486.276,2.472,2.472,0,0,1,.919.6,2.491,2.491,0,0,1,.6.919,4.431,4.431,0,0,1,.276,1.485c.039.844.047,1.1.047,3.233s-.008,2.389-.047,3.233a4.43,4.43,0,0,1-.276,1.485,2.66,2.66,0,0,1-1.518,1.518,4.433,4.433,0,0,1-1.486.276c-.844.039-1.1.047-3.233.047s-2.389-.008-3.233-.047a4.432,4.432,0,0,1-1.485-.276A2.659,2.659,0,0,1,1.762,12.72a4.432,4.432,0,0,1-.276-1.485c-.039-.844-.047-1.1-.047-3.233s.008-2.389.047-3.233a4.433,4.433,0,0,1,.276-1.485,2.472,2.472,0,0,1,.6-.919,2.493,2.493,0,0,1,.919-.6,4.435,4.435,0,0,1,1.485-.276c.844-.039,1.1-.047,3.233-.047M8,0C5.826,0,5.554.009,4.7.049A5.882,5.882,0,0,0,2.758.42a3.921,3.921,0,0,0-1.416.922A3.914,3.914,0,0,0,.419,2.759,5.845,5.845,0,0,0,.048,4.7C.008,5.555,0,5.827,0,8s.009,2.445.049,3.3a5.878,5.878,0,0,0,.372,1.942,3.92,3.92,0,0,0,.922,1.416,3.913,3.913,0,0,0,1.416.922,5.882,5.882,0,0,0,1.942.372C5.554,15.991,5.826,16,8,16s2.446-.009,3.3-.049a5.883,5.883,0,0,0,1.942-.372,4.088,4.088,0,0,0,2.339-2.339A5.878,5.878,0,0,0,15.95,11.3C15.99,10.445,16,10.173,16,8s-.009-2.446-.049-3.3a5.878,5.878,0,0,0-.372-1.942,3.921,3.921,0,0,0-.922-1.416A3.914,3.914,0,0,0,13.24.42,5.85,5.85,0,0,0,11.3.049C10.445.009,10.172,0,8,0" transform="translate(0.001)" fill="#666" />
            <path id="Path_17909" dataName="Path 17909" d="M56.726,52.618a4.108,4.108,0,1,0,4.108,4.108,4.109,4.109,0,0,0-4.108-4.108m0,6.775a2.667,2.667,0,1,1,2.667-2.667,2.667,2.667,0,0,1-2.667,2.667Z" transform="translate(-48.726 -48.726)" fill="#666" />
            <path id="Path_17910" dataName="Path 17910" d="M153.872,37.454a.96.96,0,1,1-.96.96.96.96,0,0,1,.96-.96" transform="translate(-141.602 -34.684)" fill="#666" />
          </g>
        </g>
      </g>
    </svg>

  );
};

export default Instagram;
