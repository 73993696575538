export interface BaseErrorResponse {
  success: boolean;
  message: string;
}

export enum PeriodCode {
  Daily = "Daily",
  Weekly = "Weekly",
  Annual = "Annual",
  Monthly = "Monthly",
  Seasonal = "Seasonal",
  Quarterly = "Quarterly",
  SemiAnnual = "SemiAnnual",
  TwoMonthly = "TwoMonthly",
}

export interface ContentId {
  id: string;
}

export interface IdNameCode {
  id: string;
  code: string;
  name: string;
}

export interface Code {
  id: string;
  code: string;
  name: string;
}

export enum PeriodsType {
  Quarterly = "Quarterly",
  Annual = "Annual",
  Weekly = "Weekly",
  Seasonal = "Seasonal",
  Daily = "Daily",
  SemiAnnual = "SemiAnnual",
  Monthly = "Monthly",
  TwoMonthly = "TwoMonthly"
}

export interface Periods {
  id: string;
  name: string;
  code: PeriodsType;
}

export interface Categories {
  id: string;
  name: string;
}

export interface Categories {
  id: string;
  name: string;
}

export interface Quarterlies {
  id: string;
  name: string;
}

export interface TwoMonthlies {
  id: string;
  name: string;
}

export interface Semesters {
  id: string;
  name: string;
}

export interface Seasons {
  id: string;
  name: string;
}

export interface Period {
  id: string;
  name: string;
  code: PeriodCode;
}

export interface Quarterly {
  id: string;
  name: string;
  code: PeriodCode;
}

export interface TwoMonthly {
  id: string;
  name: string;
  code: PeriodCode;
}

export interface Season {
  id: string;
  name: string;
  code: PeriodCode;
}

export interface Semester {
  id: string;
  name: string;
  code: PeriodCode;
}

export interface Periods {
  id: string;
  name: string;
  code: PeriodsType;
}
