export type TabBarProps = {
  value: string;
  centered?: boolean;
  disabled?: boolean;
  isVertical?: boolean;
  hasDivider?: boolean;
  onClick?: () => void;
  tabListFixed?: boolean;
  items: Array<TabBarItem>;
  variant?: "standard" | "scrollable" | "fullWidth";
  rightButton?: JSX.Element[] | JSX.Element | Element;
  onChange?: (event: React.ChangeEvent<any>, value: any) => void;
} & typeof TabBarDefaultProps;

export const TabBarDefaultProps = {
  value: "0",
  centered: true,
  hasDivider: false,
  variant: "standard",
};

export interface TabBarItem {
  value: string;
  disabled?: boolean;
  label: JSX.Element[] | JSX.Element | Element | string;
  content: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}
