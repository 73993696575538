import Constants from "../constants";
import axios from 'axios'

export const updateContent = (contentId: any, body: any, callback: any) => {
    var request = {
        method: 'put',
        url: Constants.API_URL + `Contents/${contentId}`,
        headers: {
            authorization: ''
        },
        data: body
    };
    let token;
    if (typeof window !== "undefined") {
        token = window.localStorage.getItem("token");
    }

    if (token) {
        request.headers.authorization = `Bearer ${JSON.parse(token)}`;
    }

    axios(request).then(({ data }: any) => {
        if (data && data.success) {
            callback(data.data.id);
        }
        else {
            alert('İstek başarısız oldu.');
        }
    });
}

export const updateContentDetail = (contentId: any, body: any, callback: any) => {
    var request = {
        method: 'put',
        url: Constants.API_URL + `Contents/Detail/${contentId}`,
        headers: {
            authorization: ''
        },
        data: body
    };
    let token;
    if (typeof window !== "undefined") {
        token = window.localStorage.getItem("token");
    }

    if (token) {
        request.headers.authorization = `Bearer ${JSON.parse(token)}`;
    }

    axios(request).then(({ data }: any) => {
        if (data && data.success) {
            callback(true);
        }
        else {
            callback(false);
        }
    });
}

export const saveContent = (body: any, callback: any) => {
    var request = {
        method: 'post',
        url: Constants.API_URL + 'Contents',
        headers: {
            authorization: ''
        },
        data: body
    };
    let token;
    if (typeof window !== "undefined") {
        token = window.localStorage.getItem("token");
    }

    if (token) {
        request.headers.authorization = `Bearer ${JSON.parse(token)}`;
    }

    axios(request).then(({ data }: any) => {
        if (data && data.success) {
            callback();
        }
        else {
            alert('İstek başarısız oldu.');
        }
    });
}

export const getContentById = (parameters: any, callback: any) => {
    var request = {
        method: 'get',
        url: Constants.API_URL + `Contents/${parameters.contentId}`,
        headers: {
            authorization: '',
            'N-AuthorizedTenantId': parameters.tenantId
        }
    };


    let token;
    if (typeof window !== "undefined") {
        token = window.localStorage.getItem("token");
    }

    if (token) {
        request.headers.authorization = `Bearer ${JSON.parse(token)}`;
    }

    axios(request).then(({ data }: any) => {

        if (data && data.success) {
            callback(data.data);
        }
        else {
            alert('hata aldı.');
        }
    });
}

export const getContentDetailById = (contentId: any, callback: any) => {
    var request = {
        method: 'get',
        url: Constants.API_URL + `Contents/WithDetail/${contentId}`,
        headers: {
            authorization: ''
        }
    };
    let token;
    if (typeof window !== "undefined") {
        token = window.localStorage.getItem("token");
    }

    if (token) {
        request.headers.authorization = `Bearer ${JSON.parse(token)}`;
    }

    axios(request).then(({ data }: any) => {

        if (data && data.success) {
            callback(data.data);
        }
        else {
            alert('hata aldı.');
        }
    });
}

export const addImageToContent = async (contentId: any, blobUrl: any, imageName: any, base64string: any) => {
    var request = {
        method: 'put',
        url: Constants.API_URL + `Contents/${contentId}/AddImage`,
        headers: {
            authorization: ''
        },
        data: {
            blobUrl: blobUrl,
            imageName: imageName,
            base64Data: base64string
        }
    };
    let token;
    if (typeof window !== "undefined") {
        token = window.localStorage.getItem("token");
    }

    if (token) {
        request.headers.authorization = `Bearer ${JSON.parse(token)}`;
    }

    var data = await axios(request);
    if (data.data.success) {
        return data.data.data.imageUrl;
    }
    return;
}

export const addImageToTextboxContent = async (contentId: any, file: any, callback: any) => {
    var request = {
        method: 'put',
        url: Constants.API_URL + `ContentImages`,
        headers: {
            authorization: ''
        },
        data: {
            contentId: contentId,
            file: file
        }
    };
    let token;
    if (typeof window !== "undefined") {
        token = window.localStorage.getItem("token");
    }

    if (token) {
        request.headers.authorization = `Bearer ${JSON.parse(token)}`;
    }

    var data = await axios(request);
    if (data.data.success) {
        callback(data.data.message);
        return data.data.data;
    }
    return;
}

export const deleteContent = (contentId: any, callback: any) => {
    var request = {
        method: 'delete',
        url: Constants.API_URL + `Contents/${contentId}`,
        headers: {
            authorization: ''
        }
    };
    let token;
    if (typeof window !== "undefined") {
        token = window.localStorage.getItem("token");
    }

    if (token) {
        request.headers.authorization = `Bearer ${JSON.parse(token)}`;
    }

    axios(request).then(({ data }: any) => {
        if (data && data.success) {
            callback();
        }
        else {
            alert('İstek başarısız oldu.');
        }
    });
}