import fetchBaseQuery from "store/baseQuery";
import {
  ChapterDetailResponse,
  ChaptersResponse,
  ContentChaptersCreateBody,
  ContentChaptersCreateResponse,
  ContentChaptersListResponse,
  ContentCreateBody,
  ContentDetailResponse,
  ContentTypesResponse,
  TitlesResponse,
} from "store/contents/types";

import { createApi } from "@reduxjs/toolkit/query/react";

export const contentsApi = createApi({
  reducerPath: "contentsApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Cahpters", "Titles"],
  endpoints: (builder) => ({
    getContentTypes: builder.query<ContentTypesResponse[], void>({
      query: () => "/ContentTypes",
      transformResponse: (response: { data: ContentTypesResponse[] }) => response.data,
    }),
    getChapters: builder.query<ChaptersResponse[], string>({
      providesTags: ["Cahpters"],
      query: (issueId) => `/ContentChapters/GetDragAndDropModelByIssueId?id=${issueId}`,
      transformResponse: (response: { data: ChaptersResponse[] }) => response.data,
    }),
    getContentDetail: builder.query<ContentDetailResponse, string>({
      query: (contentId) => `/Contents/${contentId}`,
      transformResponse: (response: { data: ContentDetailResponse }) => response.data,
    }),
    getChapterDetail: builder.query<ChapterDetailResponse, string>({
      query: (chapterId) => `/ContentChapters/${chapterId}`,
      transformResponse: (response: { data: ChapterDetailResponse }) => response.data,
    }),
    getTitles: builder.query<TitlesResponse[], string>({
      providesTags: ["Titles"],
      query: (chapterId) => `/Contents/GetByChapterId?id=${chapterId ?? '00000000-0000-0000-0000-000000000000'}`,
      transformResponse: (response: { data: TitlesResponse[] }) => response.data,
    }),
    putContentChaptersList: builder.mutation<ContentChaptersListResponse, ChaptersResponse[]>({
      invalidatesTags: ["Cahpters"],
      query(body) {
        return { body, method: "PUT", url: "/ContentChapters/DragAndDrop" };
      },
    }),
    postContentChaptersCreate: builder.mutation<any, any>({
      invalidatesTags: ["Cahpters"],
      query(body) {
        return { body, method: "POST", url: "/ContentChapters" };
      },
    }),
    postContentCreate: builder.mutation<ContentChaptersCreateResponse, ContentCreateBody>({
      invalidatesTags: ["Cahpters", "Titles"],
      query(body) {
        return { body, method: "POST", url: "/Contents" };
      },
    }),
  }),
});

export const {
  useGetTitlesQuery,
  useGetChaptersQuery,
  useGetContentTypesQuery,
  useGetChapterDetailQuery,
  useGetContentDetailQuery,
  usePostContentCreateMutation,
  usePutContentChaptersListMutation,
  usePostContentChaptersCreateMutation,
} = contentsApi;
