import fetchBaseQuery from "store/baseQuery";
import { CreateIssueBody, CreateIssueResponse, IssueDetailResponse, IssueModelResponse } from "store/issue/types";

import { createApi } from "@reduxjs/toolkit/query/react";

export const issueApi = createApi({
  reducerPath: "issueApi",
  baseQuery: fetchBaseQuery,
  tagTypes: ["Issues"],
  endpoints: (builder) => ({
    getIssues: builder.query<IssueDetailResponse[], string>({
      query: (editionId) => `/Issues/List?editionId=${editionId}`,
      transformResponse: (response: { data: IssueDetailResponse[] }) => response.data,
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: "Issues" as const, id })), { type: "Issues", id: "LIST" }] : [{ type: "Issues", id: "LIST" }],
    }),
    getIssueModel: builder.query<IssueModelResponse, string>({
      query: (editionId) => `/Issues/Create?editionId=${editionId}`,
      transformResponse: (response: { data: IssueModelResponse }) => response.data,
      providesTags: (_result, _error, editionId) => [{ type: "Issues", id: editionId }],
    }),
    getIssueDetail: builder.query<IssueDetailResponse, string>({
      query: (issueId) => `/Issues/Detail?id=${issueId}`,
      transformResponse: (response: { data: IssueDetailResponse }) => response.data,
      providesTags: (_result, _error, issueId) => [{ type: "Issues", id: issueId }],
    }),
    postIssueCreate: builder.mutation<CreateIssueResponse, CreateIssueBody>({
      invalidatesTags: [{ type: "Issues", id: "LIST" }],
      query(body) {
        return { body, method: "POST", url: "/Issues/Create" };
      },
    }),
  }),
});

export const { useGetIssuesQuery, useGetIssueModelQuery, useGetIssueDetailQuery, usePostIssueCreateMutation } = issueApi;
