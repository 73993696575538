import React, { useState, useEffect } from "react";
import useStyles from "./useStyles";
// Material Components
import { Grid, Typography, IconButton } from "@mui/material";
// Material Icons
import { Close as CloseIcon } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from '../../../../components/hooks';
// Screens
import Posted from "pages/post/posted";
// Components
import Dialog from "components/dialog";
import TabBar from "components/tabBar";
import { TabBarItem } from "components/tabBar/types";
import Tenant from './tenant';
// Constants&Enums
import { HttpMethod, PostTabs } from "utils/constants/enums";

import { getUserInfo } from "../../../../utils/index";

interface Props {
}

const TenantContainer: React.FC<Props> = (props: Props) => {
  const { t, proxyExecute } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [tenantList, setTenantList] = useState<any>();
  const [selectedTenant, setSelectedTenant] = useState<any>();
  const [selectedTab, setSelectedTab] = useState<PostTabs>(PostTabs.Published);

  const handleTabChange = (event: any, value: PostTabs) => {
    setSelectedTab(value);
  };

  const getSearchType = (): Array<TabBarItem> => {
    return [
      {
        value: PostTabs.Draft,
        content: <Posted isApproved={true} isPublished={false} refreshData={false} tenantId={selectedTenant.id} />,
        label: t('Draft')
      },
      {
        value: PostTabs.Published,
        content: <Posted isApproved={true} isPublished={true} refreshData={false} tenantId={selectedTenant.id} />,
        label: t('Published')
      }
    ];
  };

  useEffect(() => {
    if (!tenantList) {
      var userEmail = getUserInfo()?.email;
      proxyExecute(`Tenants/List/${userEmail}`, HttpMethod.Get, {
        setIsLoading: undefined,
        callback: (data: any) => { setTenantList(data) }
      });
    }
  }, [proxyExecute, tenantList]);

  const closeIcon = () => {
    return (
      <IconButton color="inherit" onClick={() => {
        setSelectedTenant(undefined);
      }} className={classes.closeIcon}>
        <CloseIcon />
      </IconButton>
    );
  };

  if (!tenantList || tenantList.length === 0) {
    return <Grid container direction="column" alignItems="center" alignContent="center" style={{
      flex: 1
    }}>
      <Grid item style={{
        flex: 1,
        display: "flex",
        alignSelf: "center",
        alignItems: "center",
      }}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Typography style={{
              fontSize: 15,
              color: "#666666",
            }}>{t('There is no authorized post')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: 30 }}>
        <Grid container>
          <Grid container spacing={3} style={{ alignItems: 'baseline' }}>
            {tenantList && tenantList.map((tenant: any) => {
              return (
                <Grid key={1} item xs={2}>
                  <Tenant tenantId={tenant.id} onClick={(tenant) => { setSelectedTenant(tenant); }} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      {selectedTenant &&
        <Dialog
          open
          fullScreen
          closable={false}
          title={<Typography style={{
            fontSize: 18,
            fontWeight: 500,
            color: "#4F525B",
          }}>{selectedTenant.name}
          </Typography>}
          titleTextAlign="left"
          leftHeader={closeIcon()}
          showAlwaysTopDivider={true}
        >
          <TabBar hasDivider centered={false} value={selectedTab} items={getSearchType()} onChange={handleTabChange} />
        </Dialog>}
    </Grid>
  );
};

export default TenantContainer;