import en from "date-fns/locale/en-US";
import tr from "date-fns/locale/tr";
import { Language } from "i18n/types";
import React from "react";
import { useTranslation } from "react-i18next";

import { Event } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const DatePickers: React.FC<any> = (props: any) => {
  const { i18n } = useTranslation();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language === Language.TR ? tr : en}>
      <DatePicker
        {...props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <Event />
              </IconButton>
            </InputAdornment>
          ),
        }}
        renderInput={(params) => {
          return <TextField {...params} fullWidth variant="standard" />;
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePickers;
