import { Icons } from "components/icons";
import { useSnackbar } from "components/snackbar";
import { useFormik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { usePostForgotPasswordMutation } from "store/authentication/api";
import * as Yup from "yup";

import { LoadingButton } from "@mui/lab";
import { Grid, Paper, TextField, Typography } from "@mui/material";

import useStyles from "./useStyles";

const ForgotPassword: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["forgotPassword", "error"]);
  const [forgotPasswordApi, { isLoading }] = usePostForgotPasswordMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t("error:email")).required(t("error:required")),
    }),
    onSubmit: (values) => {
      forgotPasswordApi({
        email: values.email,
      })
        .unwrap()
        .then(({ success }) => {
          if (success !== false) {
            enqueueSnackbar(t("sendForgotPasswordEmailMessage"), {
              variant: "success",
            });
            history.replace({ pathname: "giris-yap" });
          }
        });
    },
  });

  return (
    <Grid container direction="column" alignItems="center" alignContent="center" className={classes.forgotPasswordContainer}>
      <Grid item>
        <form onSubmit={formik.handleSubmit}>
          <Paper className={classes.paper}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.logo}>
                  <Icons.Logo />
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.title}>{t("forgotPassword")}</Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  name="email"
                  variant="standard"
                  label={t("email")}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  helperText={formik.touched.email && formik.errors.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                />
                <Typography className={classes.info}>{t("sendForgotPasswordInfo")}</Typography>
              </Grid>
              <Grid item>
                <LoadingButton fullWidth size="large" type="submit" loading={isLoading} variant="contained" className={classes.sendButton}>
                  {t("next")}
                </LoadingButton>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
