import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    position: "relative",
    color: theme.palette.baseLight500,
  },
  dialogTitle: {
    fontSize: 18,
    fontWeight: 500,
    color: "#4F525B",
  },
  maxWidthMd: {
    maxWidth: 1320,
    userSelect: "none",
    padding: "50px 24px",
  },
  groupTitle: {
    fontSize: 24,
    color: "#2B2B2B",
    marginRight: 20,
    fontWeight: '700'
  },
}));

export default useStyles;
