import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, DialogProps, Typography, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import useStyles from "./useStyles";

interface Props {
  open: boolean;
  closable?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  fullScreen?: boolean;
  onClose?: () => void;
  hideCloseIcon?: boolean;
  title?: React.ReactNode;
  height?: number | string;
  children: React.ReactNode;
  hideBottomDivider?: boolean;
  leftHeader?: React.ReactNode;
  leftFooter?: React.ReactNode;
  rightFooter?: React.ReactNode;
  rightHeader?: React.ReactNode;
  showAlwaysTopDivider?: boolean;
  bottomHeader?: React.ReactNode;
  centerFooter?: React.ReactNode;
  topIconPosition?: any;
  titleTextAlign?: "left" | "right" | "center";
  maxWidth?: string;
}

enum ScrollPosition {
  Top,
  Center,
  Bottom,
}

const Dialog: React.FC<Props> = (props: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const refEl = useRef<HTMLElement>(null);
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const [scrollPosition, setScrollPosition] = useState<ScrollPosition>(ScrollPosition.Top);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md');

  useEffect(() => {
    if (props.maxWidth == 'xs') {
      setMaxWidth('xs');
    }
  }, [props.maxWidth]);

  const hideDivider = () => {
    if (refEl.current) {
      const scrollMaxPosition = refEl.current.scrollHeight - refEl.current.offsetHeight;
      if (refEl.current.scrollTop === 0) {
        setScrollPosition(ScrollPosition.Top);
      } else if (refEl.current.scrollTop === scrollMaxPosition) {
        setScrollPosition(ScrollPosition.Bottom);
      } else {
        setScrollPosition(ScrollPosition.Center);
      }
    }
  };

  const closeIcon = () => {
    if (props.hideCloseIcon) return;
    if (props.closable) {
      return (
        <IconButton color="inherit" onClick={props.onClose} className={classes.closeIcon}>
          <CloseIcon />
        </IconButton>
      );
    }
  };

  const getLeftContent = () => {
    if (isXs && props.closable && !props.hideCloseIcon) {
      return closeIcon();
    } else {
      return props.leftHeader;
    }
  };

  const getRightContent = () => {
    if (isXs && props.closable && !props.hideCloseIcon) {
      return (
        <>
          {props.leftHeader}
          {props.rightHeader}
        </>
      );
    } else {
      return (
        <>
          {props.rightHeader}
          {closeIcon()}
        </>
      );
    }
  };

  const handleClose = () => {
    if (props.closable !== false) {
      props.onClose && props.onClose();
    }
  };

  return (
    <MuiDialog
      maxWidth={maxWidth}
      scroll="paper"
      open={props.open}
      onClose={handleClose}
      fullScreen={props.fullScreen}
      PaperProps={{
        className: clsx({
          [classes.fullWidth]: props.fullWidth,
          [classes.fullHeight]: props.fullHeight,
        }),
      }}
      disableEscapeKeyDown={props.closable === false}
      style={{ zIndex: 0 }}
    >
      {(props.title || props.closable || props.leftHeader || props.rightHeader) && (
        <DialogTitle className={classes.dialogTitleWrapper}>

            <Grid container alignContent="center" justifyContent="space-between" alignItems={props.topIconPosition}>
              <Grid item>
                <DialogActions className={classes.leftActions}>{getLeftContent()}</DialogActions>
              </Grid>

              <Grid
                item
                className={clsx(classes.dialogTitle, {
                  [classes.titleTextAlignLeft]: props.titleTextAlign === "left",
                })}
              >
                {props.title}
              </Grid>
              <Grid item style={{alignContent:"center"}}>
                <DialogActions className={classes.rightActions}>{getRightContent()}</DialogActions>
              </Grid>

            </Grid>
          {/* <Grid container style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <Grid item xs={'auto'}>
              <DialogActions className={classes.leftActions}>{getLeftContent()}</DialogActions>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h6" style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}>
                {props.title}
              </Typography>
            </Grid>
            <Grid item xs={3} container justifyContent="flex-end">
              <DialogActions className={classes.rightActions}>{getRightContent()}</DialogActions>
            </Grid>
          </Grid> */}
        </DialogTitle>
      )}
      {props.bottomHeader && (
        <Grid container alignItems="center" alignContent="center">
          {props.bottomHeader}
        </Grid>
      )}
      {(scrollPosition !== ScrollPosition.Top || props.showAlwaysTopDivider) && <Divider />}
      <DialogContent ref={refEl} onScroll={hideDivider}>
        <Grid container direction="column" style={{ height: props.height || "auto" }}>
          {props.children}
        </Grid>
      </DialogContent>
      {!props.hideBottomDivider && scrollPosition !== ScrollPosition.Bottom && <Divider />}
      {(props.rightFooter || props.leftFooter || props.centerFooter) && (
        <DialogActions>
          <Grid container alignItems="center" alignContent="center" justifyContent="space-between">
            <Grid item>
              <DialogActions>{props.leftFooter}</DialogActions>
            </Grid>
            <Grid item>
              <DialogActions>{props.centerFooter}</DialogActions>
            </Grid>
            <Grid item>
              <DialogActions>{props.rightFooter}</DialogActions>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default Dialog;