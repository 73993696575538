import React, { useEffect, useState } from "react";
import useStyles from "./useStyles";
// Material Components
import { Chip, FormControl, FormControlLabel, Grid, Input, InputLabel, MenuItem, Paper, Select, TextField, RadioGroup, Radio, IconButton, FormHelperText, Typography, Checkbox, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// Material Icons
import { AddCircleOutline as AddCircleOutlineIcon } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from '../../../../components/hooks';
// Screens
import { AddMember } from "pages/authorization/members/addMember";
// Components
import Dialog from "components/dialog";
// Constants&Enums
import { HttpMethod } from "utils/constants/enums";

interface Props {
  onClose?: (dialogResponse: any) => void;
  onSuccess?: (newEditionId: string, newPublicationId: string) => void;
  post?: any;
  postType?: any;
}

const PostDefinition: React.FC<Props> = (props: Props) => {
  const { t, proxyExecute, isXs } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [openAddWriterAsDialog, setOpenAddWriterAsDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCategoriesDropDownOpen, setIsCategoriesDropDownOpen] = useState(false);

  const [formData, setFormData] = useState<any>({
    id: props.post ? props.post.id : undefined,
    name: props.post ? props.post.name : undefined,
    categoryIds: props.post ? props.post.categories.map((x: any) => { return x.id }) : [],
    postType: props.postType ?? 'publication',
    font: props.post ? props.post.font : "3",
    language: "ENG",
    periodId: (props.postType === 'edition') ? props.post.period.id : undefined,
    selectedWriterIds: (props.postType === 'publication' && props.post.writers) ? props.post.writers?.map((x: any) => { return x.id }) : [],
    internationalNumber: (props.postType === 'publication') ? props.post.isbn : ((props.postType === 'edition') ? props.post.issn : undefined)
  });
  const [periods, setPeriods] = useState<any>();
  const [categories, setCategories] = useState<any>();
  const [writers, setWriters] = useState<any>();
  const [errors, setErrors] = useState<any>({});

  const dataHandler = (field: any, value: any,) => {
    setFormData({ ...formData, [field]: value });
  };

  useEffect(() => {
    if (categories && periods) return;
    proxyExecute(`Editions/Create`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => {
        setPeriods(data.periods);
        setCategories(data.categories);
      }
    });
  }, [periods, categories, proxyExecute]);

  useEffect(() => {
    if (writers) return;

    proxyExecute(`Members`, HttpMethod.Get, {
      setIsLoading: undefined,
      callback: (data: any) => { setWriters(data); }
    });
  }, [writers, proxyExecute]);


  const handleClose = () => {
    setIsOpen(false);
  };

  const handleButtonClick = () => {
    handleClose();
  };

  const selectCategories = () => {
    if (!categories) return;
    return <Grid className={classes.row} item>
      <FormControl fullWidth  variant="standard">
        <InputLabel id="categoryIds">{t('Category')}</InputLabel>
        <Select
          multiple
          input={<Input />}
          name="categoryIds"
          labelId="categoryIds"
          open={isCategoriesDropDownOpen}
          onOpen={() => { setIsCategoriesDropDownOpen(true) }}
          onClose={() => { setIsCategoriesDropDownOpen(false) }}
          onChange={(value) => {
            dataHandler('categoryIds', value?.target?.value);
          }}
          value={formData.categoryIds}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => {
                const selectedCategory = categories.find((item: any) => item.id === value);
                if (selectedCategory)
                  return <Chip key={value} className={classes.chip} label={selectedCategory!.name} />;
              })}
            </div>
          )}
          error={Boolean(errors.categoryIds)}
        >
          {categories.map((category: any) => {
            return (
              <MenuItem key={category.id} value={category.id}>
                <div>
                  <Checkbox checked={formData.categoryIds?.find((id: any) => id === category.id) !== undefined} />
                  {category.name}
                </div>
              </MenuItem>
            );
          })}
          <div style={{ position: 'sticky', bottom: 0, background: 'white', display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
            <Button onClick={() => { setIsCategoriesDropDownOpen(false) }}>{t('Ok')}</Button>
          </div>
        </Select>
        {errors.categoryIds && <FormHelperText error>{errors.categoryIds}</FormHelperText>}
      </FormControl>
    </Grid>;
  }

  const selectFont = () => {
    return <Grid className={classes.row} item>
      <FormControl fullWidth variant="standard">
        <InputLabel id="font">{t('Font')}</InputLabel>
        <Select
          name="font"
          labelId="font"
          value={formData.font}
          onChange={(value) => {
            dataHandler('font', value?.target?.value);
          }}>
          <MenuItem value="3">Varsayılan</MenuItem>
        </Select>
      </FormControl>
    </Grid>;
  }

  const selectWriter = () => {
    if (formData.postType !== 'publication') return;
    if (!writers) return;

    return <Grid item xs={12}>
      <Grid container alignItems="flex-start">
        <Grid item md={11} style={{ flexGrow: 1 }}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="writer">{t('Writer (Optional)')}</InputLabel>
            <Select
              multiple
              labelId="writer"
              open={isOpen}
              onClose={handleClose}
              onOpen={() => setIsOpen(true)}
              input={<Input />}
              value={formData.selectedWriterIds}
              onChange={(value) => {
                dataHandler('selectedWriterIds', value?.target?.value);
              }}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {(selected as string[]).map((value) => {
                    const selectedWriter = writers.find((item: any) => item.id === value);
                    return <Chip key={value} className={classes.chip} label={`${selectedWriter.firstname} ${selectedWriter!.lastname}`} />;
                  })}
                </div>
              )}
            >
              {writers.map((writer: any) => {
                return (
                  <MenuItem key={writer.id} value={writer.id}>
                    <div>
                      <Checkbox checked={formData.selectedWriterIds?.find((id: any) => id === writer.id) !== undefined} />
                      {writer.firstname} {writer.lastname}
                    </div>
                  </MenuItem>
                );
              })}
              <div style={{ position: 'sticky', bottom: 0, background: 'white', display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                <Button onClick={handleButtonClick}>{t('Ok')}</Button>
              </div>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={1}>
          <IconButton onClick={() => setOpenAddWriterAsDialog(true)}>
            <AddCircleOutlineIcon className={classes.plusIcon} />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  }

  const selectPeriod = () => {
    if (formData.postType !== "edition" || props.postType) return;

    return <Grid className={classes.row} item>
      <FormControl fullWidth variant="standard">
        <InputLabel id="periodId">{t('Post Period')}</InputLabel>
        <Select
          name="periodId"
          labelId="periodId"
          value={formData.periodId}
          onChange={(value) => {
            dataHandler('periodId', value?.target?.value);
          }}
          error={Boolean(errors.periodId)}
        >
          {periods?.map((period: any) => {
            return (
              <MenuItem key={period.id} value={period.id}>
                {period.name}
              </MenuItem>
            );
          })}
        </Select>
        {errors.periodId && <FormHelperText error>{errors.periodId}</FormHelperText>}
      </FormControl>
    </Grid>;
  }

  const validate = () => {
    var errorMessages: any = {};
    if (!formData.name || formData.name === '') {
      errorMessages.name = t('This field is required');
    }
    if (!formData.categoryIds || formData.categoryIds.length === 0) {
      errorMessages.categoryIds = t('This field is required');
    }
    if (formData.postType === "edition") {
      if (!formData.periodId || formData.periodId.length === 0) {
        errorMessages.periodId = t('This field is required');
      }
    }
    setErrors(errorMessages);
    return Object.keys(errorMessages).length === 0;
  }

  return (
    <Dialog open closable={true} fullScreen={isXs} title={t('Add Post')} onClose={() => {
      props.onClose && props.onClose({ result: false });
    }} showAlwaysTopDivider={true}>
      <Grid container direction="column" className={classes.dialogContent}>
        <Paper className={classes.dialogPaper}>
          <Grid container spacing={1} direction="column">
            {!props.postType && <Grid className={classes.row} item >
              <Grid container alignItems="center"  >
                <Grid item spacing={1} xs={12}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    onChange={(event) => {
                      var value = (event.target as HTMLInputElement).value;
                      dataHandler('postType', value);
                    }}
                    value={formData.postType}
                  >
                    <Grid item container >
                      <Grid item xs={6}>
                        <FormControlLabel value="publication" control={<Radio />} label={t('Publication')} />
                        <Typography style={{ fontSize: 12, marginLeft: 33, marginTop: -5 }}>{t('Book, catalog, guide, report (Published once)')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel value="edition" control={<Radio />} label={t('Edition')} />
                        <Typography style={{ fontSize: 12, marginLeft: 33, marginTop: -5 }}>{t('Bulletin, magazine, report, daily (New issues published at regular intervals)')}</Typography>
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
              </Grid>
            </Grid>}
            <Grid className={classes.row} item>
              <TextField
                fullWidth
                name="name"
                variant="standard"
                label={t("Post Name")}
                value={formData.name}
                onChange={(value) => { dataHandler('name', value.currentTarget.value) }}
                error={Boolean(errors.name)}
                helperText={errors.name}
              />
            </Grid>

            {selectCategories()}
            {selectWriter()}
            {selectPeriod()}

            <Grid className={classes.row} item>
              <TextField
                fullWidth
                name="internationalNumber"
                variant="standard"
                value={formData.internationalNumber}
                onChange={(value) => { dataHandler('internationalNumber', value.currentTarget.value) }}
                label={formData.postType === 'edition' ? t("Issn") : t("Isbn")}
              />
            </Grid>

            {selectFont()}

          </Grid>
        </Paper>
        <Grid item>
          <LoadingButton loading={isLoading} onClick={() => {
            if (!validate()) return;
            setIsLoading(true);
            var body: any = {
              color: "#6E717A",
              font: formData.font,
              name: formData.name,
              selectedCategoryIds: formData.categoryIds,
              language: formData.language
            }
            if (formData.postType === 'publication') {
              body.isbn = formData.internationalNumber;
              body.selectedWriterIds = formData.selectedWriterIds;
              if (formData.id) {
                body.id = formData.id;
                proxyExecute(`Publications`, HttpMethod.Put, {
                  body,
                  setIsLoading,
                  callback: (data: any) => {
                    props.onClose && props.onClose({ result: true, postType: formData.postType, id: data.id });
                  }
                });
              }
              else {
                proxyExecute(`Publications/Create`, HttpMethod.Post, {
                  body,
                  setIsLoading,
                  callback: (data: any) => {
                    props.onClose && props.onClose({ result: true, postType: formData.postType, id: data.id });
                  }
                });
              }
            }
            else {
              body.issn = formData.internationalNumber;
              body.SelectedPeriod = {};
              body.SelectedPeriod.id = formData.periodId;
              if (formData.id) {
                body.id = formData.id;
                proxyExecute(`Editions`, HttpMethod.Put, {
                  body,
                  setIsLoading,
                  callback: (data: any) => {
                    props.onClose && props.onClose({ result: true, postType: formData.postType, id: data.id });
                  }
                });
              }
              else {
                proxyExecute(`Editions/Create`, HttpMethod.Post, {
                  body,
                  setIsLoading,
                  callback: (data: any) => {
                    props.onClose && props.onClose({ result: true, postType: formData.postType, id: data.id });
                  }
                });
              }
            }
          }} fullWidth size="large" variant="contained" className={classes.nextButton}>
            {t('Next')}
          </LoadingButton>
        </Grid>

        {openAddWriterAsDialog && <AddMember onClose={() => { setOpenAddWriterAsDialog(false); }}></AddMember>}
      </Grid>
    </Dialog>
  );
};

export default PostDefinition;
