import { alpha, Palette } from "@mui/material";

const overrides = (palette: Palette) => ({
  MuiDialog: {
    styleOverrides: {
      scrollPaper: {
        color: "red",
        alignItems: "flex-start",
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none" as const,
        borderRadius:8
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: 0,
        "&:first-child": {
          paddingTop: 0,
        },
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: 0,
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        marginBottom: 4,
      },
      positionStart: {
        marginRight: 4,
      },
      positionEnd: {
        marginLeft: 4,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        marginBottom: 4,
      },
      input: {
        height: 21,
        fontSize: 16,
        color: "#212121",
        padding: "2px 0 4px 0",
        "&.Mui-disabled": {
          color: palette.baseDark600,
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: 16,
        color: palette.baseDark600,
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        width: 44,
        height: 44,
        fontSize: 14,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        "@media (min-width: 767px)": {
          minWidth: "auto",
        },
      },
    },
  },
});

export default overrides;
