import React, { useEffect, useState } from "react";
import useStyles from "./useStyles";
// Material Components
import { Button, Container, Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// Material Icons
import { Close as CloseIcon, ArrowBack as ArrowBackIcon, MoreVert as MoreVertIcon, Publish, Edit, Done } from "@mui/icons-material";
// Custom Hooks
import { useCustomHooks } from '../../../../components/hooks';
// Screens
import IssueDefinition from "../postDefinition/issueDefinition";
import PublicationDefinition from "../postDefinition/publicationDefinition";
import ImprintDefinition from "./imprintDefinition";
import AuthorizedMembers from '../../../authorization/members/authorizedMembers'
// Components
import Dialog from "components/dialog";
import Loading from "components/loading";
import ContentChapterList from "./contentChapterList";
import DeleteConfirmation from "components/deleteConfirmation";
import ImageComponent from 'components/imageComponent'
// Constants&Enums
import { PeriodCode } from "store/types";
import Constants from "utils/constants";
import { HttpMethod } from "utils/constants/enums";


interface Props {
  onClose?: () => void;
  refreshPage?: () => void;
  postType: string;
  id: string;
  editionId?: string;
  tenantId: any;
}

const ContentManagement: React.FC<Props> = (props: Props) => {
  const { t, i18n, isXs, isSm, isMd, proxyExecute } = useCustomHooks();
  const classes = useStyles();

  // Variables
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const isOpenMoreMenu = Boolean(anchorEl);
  const [formData, setFormData] = useState<any>();
  const [deleteIssueConfirmation, setDeleteIssueConfirmation] = useState(false);
  const [openIssueDefinitionAsDialog, setOpenIssueDefinitionAsDialog] = useState(false);
  const [openPublicationDefinitionAsDialog, setOpenPublicationDefinitionAsDialog] = useState<boolean>(false);
  const [openImprintDefinitionAsDialog, setOpenImprintDefinitionAsDialog] = useState<boolean>(false);
  const [openPublishAsDialog, setOpenPublishAsDialogDialog] = useState<boolean>(false);
  const [deletePublicationConfirmation, setDeletePublicationConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isImageFullScreen, setIsImageFullScreen] = useState(true);

  useEffect(() => {
    if (formData) return;

    if (props.postType === 'publication') {
      proxyExecute(`Publications/Detail?Id=${props.id}`, HttpMethod.Get, {
        setIsLoading: undefined,
        callback: (data: any) => {
          setFormData({
            ...formData,
            publicationId: props.id,
            postType: "publication",
            name: data.name,
            year: data.year,
            imagePath: data.imagePath,
            tenantName: data.tenantName,
            isPublished: data.isPublished,
            internationalNumber: data.isbn,
            hasAccessToEditPostDetail: data.hasAccessToEditPostDetail,
            hasAccessToDeletePost: data.hasAccessToDeletePost,
            hasAccessToPublish: data.hasAccessToPublish,
            authorizationContext: {
              hasAccessToAddContent: data.hasAccessToAddContent,
              hasAccessToEditContent: data.hasAccessToEditContent,
              hasAccessToEditContentDetail: data.hasAccessToEditContentDetail,
              hasAccessToReorderContent: data.hasAccessToReorderContent,
              hasAccessToAddChapter: data.hasAccessToAddChapter,
              hasAccessToEditChapter: data.hasAccessToEditChapter,
              hasAccessToDeleteChapter: data.hasAccessToDeleteChapter,
              hasAccessToDeleteContent: data.hasAccessToDeleteContent
            },
            hasImprint: data.hasImprint
          });
        },
        authorizedTenantId: props.tenantId
      });
    }
    else if (props.postType === 'edition') {
      proxyExecute(`Issues/Detail?Id=${props.id}`, HttpMethod.Get, {
        setIsLoading: undefined,
        callback: (data: any) => {
          setFormData({
            ...formData,
            postType: 'edition',
            name: data.edition.name,
            editionId: data.edition.id,
            issueId: props.id,
            period: data.edition.period,
            periodItem: data.periodItem,
            year: data.year,
            imagePath: data.imagePath,
            tenantName: data.edition.tenant.name,
            date: data.date,
            issueNumber: data.issueNumber,
            isPublished: data.isPublished,
            internationalNumber: data.edition.issn,
            hasAccessToEditIssue: data.hasAccessToEditIssue,
            hasAccessToDeleteIssue: data.hasAccessToDeleteIssue,
            hasAccessToPublish: data.hasAccessToPublish,
            authorizationContext: {
              hasAccessToAddContent: data.hasAccessToAddContent,
              hasAccessToEditContent: data.hasAccessToEditContent,
              hasAccessToEditContentDetail: data.hasAccessToEditContentDetail,
              hasAccessToReorderContent: data.hasAccessToReorderContent,
              hasAccessToAddChapter: data.hasAccessToAddChapter,
              hasAccessToEditChapter: data.hasAccessToEditChapter,
              hasAccessToDeleteChapter: data.hasAccessToDeleteChapter,
              hasAccessToDeleteContent: data.hasAccessToDeleteContent
            },
            hasImprint: data.hasImprint
          });
        },
        authorizedTenantId: props.tenantId
      })
    }
  });

  const handleMoreClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreMenuClose = () => {
    setAnchorEl(null);
  };

  const closeIcon = () => {
    if (props.tenantId)
      return (
        <IconButton color="inherit" onClick={props.onClose} className={classes.closeIcon}>
          <ArrowBackIcon />
        </IconButton>
      );
    return (
      <IconButton color="inherit" onClick={props.onClose} className={classes.closeIcon}>
        <CloseIcon />
      </IconButton>
    );
  };

  const getTitle = () => {
    if (formData.postType === 'publication') {
      // return (
      //   <Typography className={classes.dialogTitle} style={{
      //     overflow: "hidden",
      //     textOverflow: "ellipsis",
      //   }}>
      //     {formData.name}
      //   </Typography>
      // );
      return formData.name;
    }
    else {
      // return (
      //   <Typography className={classes.dialogTitle}>
      //     {formData.name}, {formData.issueNumber}
      //   </Typography>
      // );
      return `${formData.name}, ${formData.issueNumber}`
    }
  };

  const getImprintButton = () => {
    return <Grid item>
      {(!props.tenantId || formData.authorizationContext.hasAccessToAddContent || formData.hasImprint) && (!isMd || formData.hasImprint) && <Button size="medium" onClick={() => { setOpenImprintDefinitionAsDialog(true) }}>
        {t('Imprint')}
      </Button>}
    </Grid>;
  }

  const getRightHeader = () => {
    if (isMd)
      return <Grid container justifyContent="center" spacing={1}>
        {getImprintButton()}
      </Grid>

    if (isInEditMode) {
      return <Grid container justifyContent="center" spacing={1}>
        <Grid item>
          <Button size="medium" variant="contained"
            color="success"
            sx={{
              color: '#fff',
            }}
            startIcon={<Done />}
            onClick={() => {
              setIsInEditMode(false);
            }}>
            {t('Ok')}
          </Button>
        </Grid>
      </Grid>
    }

    return (
      <Grid container justifyContent="center" spacing={1}>
        {getImprintButton()}
        {(!props.tenantId || formData.hasAccessToPublish) && formData.isPublished === false && <Grid item>
          <LoadingButton loading={isLoading} size="medium" variant="outlined"
            startIcon={<Publish />}
            onClick={() => {
              setOpenPublishAsDialogDialog(true);
            }}>
            {t('Publish')}
          </LoadingButton>
        </Grid>}
        <Grid item>
          {(!props.tenantId || formData.hasAccessToEditIssue || formData.hasAccessToEditPostDetail) &&
            <Button size="medium" variant="contained"
              startIcon={<Edit />}
              onClick={() => {
                setIsInEditMode(true);
              }}>
              {t('Edit')}
            </Button>}
        </Grid>
        {(!props.tenantId || formData.hasAccessToDeleteIssue || formData.hasAccessToDeletePost || (formData.hasAccessToPublish && formData.isPublished)) && <Grid item>
          <IconButton style={{ height: 36, width: 36 }} onClick={handleMoreClick}>
            <MoreVertIcon />
          </IconButton>
        </Grid>}
      </Grid>
    );
  };

  const getDate = () => {
    if (!formData) return;

    const { period } = formData;
    const date = new Date();
    date.setFullYear(formData.year);

    let formattedDate = "";

    switch (period.code) {
      case PeriodCode.Daily:
      case PeriodCode.Weekly:
        formattedDate = new Intl.DateTimeFormat(i18n.language, {
          day: "numeric",
          month: "long",
          year: "numeric",
        }).format(new Date(formData.date));
        break;
      case PeriodCode.Monthly:
        date.setMonth(formData.periodItem.code); // TODO: ay bir fazla atıyor hep sonraki ayı yazıyor.
        formattedDate = new Intl.DateTimeFormat(i18n.language, {
          month: "long",
          year: "numeric",
        }).format(date);
        break;
      case PeriodCode.Quarterly:
      case PeriodCode.TwoMonthly:
      case PeriodCode.Seasonal:
      case PeriodCode.SemiAnnual:
        formattedDate = `${formData.periodItem?.name} ${new Intl.DateTimeFormat(i18n.language, {
          year: "numeric",
        }).format(date)}`;
        break;
      case PeriodCode.Annual:
        formattedDate = new Intl.DateTimeFormat(i18n.language, {
          year: "numeric",
        }).format(date);
        break;
      default:
        break;
    }

    return (
      <Typography className={classes.date}>
        {formattedDate}
      </Typography>
    );
  };

  const getLeftContent = () => {
    return <Grid item md={4} xs={12} position={isMd ? 'sticky' : 'fixed'} style={{ width: 336 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {formData.imagePath &&
            <ImageComponent style={{
              borderRadius: 12,
              boxShadow: "0 2px 6px #12121280"
            }} alt={formData.issueNumber} className={classes.image} src={`${Constants.API_URL}${formData.imagePath}`}
              handleClose={(isFullScreen: any) => {
                setIsImageFullScreen(isFullScreen);
              }} />}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {formData.tenantName && <Typography align="center" style={{
          fontSize: 12,
          marginTop: 12,
          color: "#9E9E9E",
        }}>{formData.tenantName}</Typography>}
      </Grid>
    </Grid>;
  }

  const getMoreMenu = () => {
    return <Menu
      keepMounted
      anchorEl={anchorEl}
      open={isOpenMoreMenu}
      onClose={handleMoreMenuClose}
      PaperProps={{
        style: {
          width: "20ch",
          maxHeight: 48 * 4.5,
        },
      }}
    >
      {(!props.tenantId || formData.hasAccessToDeleteIssue || formData.hasAccessToDeletePost) &&
        <MenuItem key={1} onClick={() => {
          handleMoreMenuClose();
          if (props.postType === 'edition') {
            setDeleteIssueConfirmation(true);
          }
          else {
            setDeletePublicationConfirmation(true);
          }
        }}>
          {t('Delete')}
        </MenuItem>}
      {(!props.tenantId || formData.hasAccessToPublish) && formData.isPublished === true &&
        <MenuItem key={1} onClick={() => {
          handleMoreMenuClose();
          var id = 0;
          if (props.postType === 'publication') {
            id = formData.publicationId;
            proxyExecute(`Publications/Unpublish/${id}`, HttpMethod.Put, {
              setIsLoading: undefined,
              callback: () => { setFormData({ ...formData, isPublished: false }) }
            });
          }
          else {
            id = formData.issueId;
            proxyExecute(`Issues/Unpublish/${id}`, HttpMethod.Put, {
              setIsLoading: undefined,
              callback: () => { setFormData({ ...formData, isPublished: false }) }
            });
          }
        }}>
          {t('Unpublish')}
        </MenuItem>}
      {(!props.tenantId || formData.hasAccessToDeleteIssue || formData.hasAccessToDeletePost) &&
        <MenuItem key={1} onClick={() => {
          handleMoreMenuClose();
          if (props.postType === 'edition') {
            setOpenIssueDefinitionAsDialog(true);
          }
          else {
            setOpenPublicationDefinitionAsDialog(true);
          }
        }}>
          {t('Edit post detail')}
        </MenuItem>}
    </Menu>
  }

  if (!formData) {
    return (
      <Grid container>
        <Loading />
      </Grid>
    );
  }

  return (
    <Dialog
      open
      fullScreen
      title={getTitle()}
      titleTextAlign="left"
      onClose={() => { props.onClose && props.onClose(); }}
      leftHeader={closeIcon()}
      showAlwaysTopDivider={true}
      rightHeader={getRightHeader()}
    >
      <Container
        maxWidth="md"
        disableGutters
        classes={{
          maxWidthMd: classes.maxWidthMd,
        }}
      >
        <Grid container spacing={3}>
          {getLeftContent()}
          {isImageFullScreen && <Grid item md={8} xs={12} style={{
            marginLeft: (isMd ? 0 : 336)
          }}>
            <Grid item xs={12} style={{ textAlign: isMd ? 'center' : 'start' }}>
              {props.postType === 'edition' && <Typography className={classes.info}>
                <b>{formData.name}</b>
                <br />
                {t('Issue')} {formData.issueNumber}
                <br />
                {getDate()}
                <br />
                {formData.internationalNumber && <div>{t('Issn')}: {formData.internationalNumber}</div>}
              </Typography>}
              {props.postType === 'publication' && <Typography className={classes.info}>
                <b>{formData.name}</b>
                <br />
                <br />
                {formData.internationalNumber && <div>{t('Isbn')}: {formData.internationalNumber}</div>}
              </Typography>}
            </Grid>
            <Grid container>
              <Grid item xs={12} style={{
                marginBottom: 20,
                marginTop: 20,
                textAlign: isMd ? 'center' : 'start'
              }}>
                <Typography variant="overline" className={classes.subDialogTitle}>{t('Contents')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <ContentChapterList authorizationContext={formData.authorizationContext} tenantId={props.tenantId} refreshPage={() => { }} postType={props.postType} id={props.id} />
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
        {getMoreMenu()}
      </Container>

      {openIssueDefinitionAsDialog &&
        <IssueDefinition editionId={formData.editionId} issueId={formData.issueId}
          onClose={() => {
            setOpenIssueDefinitionAsDialog(false);
            setFormData(undefined);
          }}></IssueDefinition>}


      <Dialog open={openPublishAsDialog}
        onClose={() => { setOpenPublishAsDialogDialog(false) }}
        closable={true}
        showAlwaysTopDivider={true}
        title={t('Publish')}>
        <AuthorizedMembers postName={formData.name}
          issueNumber={formData.issueNumber}
          publicationId={formData.publicationId}
          issueId={formData.issueId}
          tenantName={formData.tenantName}
          editionId={formData.editionId}
          onClose={() => {
            setFormData({ ...formData, isPublished: true });
            setOpenPublishAsDialogDialog(false);
          }}
        ></AuthorizedMembers>
      </Dialog>

      {deleteIssueConfirmation &&
        <DeleteConfirmation onDeleteClicked={() => {
          setDeleteIssueConfirmation(false);
          var id = formData.issueId;
          proxyExecute(`Issues/${id}`, HttpMethod.Delete, {
            setIsLoading: undefined,
            callback: () => { props.onClose && props.onClose() }
          });
        }}
          onCancelClicked={() => {
            setDeleteIssueConfirmation(false);
          }}></DeleteConfirmation>}

      {openPublicationDefinitionAsDialog &&
        <PublicationDefinition publicationId={formData.publicationId}
          onClose={(dialogResponse: any) => {
            setOpenPublicationDefinitionAsDialog(false);
            setFormData(undefined);
          }}></PublicationDefinition>}

      {openImprintDefinitionAsDialog &&
        <ImprintDefinition postType={props.postType} id={props.id}
          onClose={() => { setOpenImprintDefinitionAsDialog(false) }}
          isEditable={!props.tenantId || formData.authorizationContext.hasAccessToAddContent}></ImprintDefinition>}

      {deletePublicationConfirmation && <DeleteConfirmation onDeleteClicked={() => {
        setDeletePublicationConfirmation(false);
        var id = formData.publicationId;
        proxyExecute(`Publications/${id}`, HttpMethod.Delete, {
          setIsLoading: undefined,
          callback: () => { props.onClose && props.onClose() }
        });
      }}
        onCancelClicked={() => {
          setDeletePublicationConfirmation(false);
        }}></DeleteConfirmation>}
    </Dialog>
  );
};

export default ContentManagement;
